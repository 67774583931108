import React from 'react'
import DataTable from 'react-data-table-component'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { tableSearch } from '../../helper/dataTable'
import { 
    Loader, Button, Icon,
    Modal, Form, Card, Input, TransitionablePortal
} from 'semantic-ui-react'
import { getLocalStorage } from '../../helper/local_storage'
import NoDataImage from '../../admin/components/nodata'
const superagent = require('superagent')

export default class FAQ extends React.Component{
    constructor(props){
        super(props)
        let userInfo = getLocalStorage('user');
        this.state = {
            userInfo: userInfo.user,
            faqList: [],
            oldFaqList: [],
            isLoading: true,
            open: false,
            saveLoading: false,
            isUpdate: false,
            faqForm: {
                id: '',
                title:'',
                category:'',
                value:'',
            },
            search: ''
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleRefreshData = this.handleRefreshData.bind(this)
        this.handleTableAction = this.handleTableAction.bind(this)
        this.handleSave = this.handleSave.bind(this)
    }

    componentWillMount(){
        this.handleRefreshData()
    }

    render(){
        let columns = [
            {
                name: 'Title',
                selector: 'title',
                sortable: true,
                ignoreRowClick: true,
                allowOverflow: false,
                wrap: true
            },
            {
                name: 'Category',
                selector: 'category',
                sortable: true,
                ignoreRowClick: true,
                allowOverflow: false,
                wrap: true
            },
            {
                name: 'Actions',
                cell: row => {
                    let options = [
                        { key: 0, color: 'blue', text: 'Edit', value: 'edit' },
                        { key: 1, color: 'red', text: 'Remove', value: 'delete' },
                    ];
                    return (
                        <div>
                            {
                                options.map((val, k) =><Button key={k} size='small' color={val.color} faq_id={row.id} action={val.key} onClick={this.handleTableAction}>{val.text}</Button>)
                            }
                        </div>
                    )
                },
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
                wrap: true
            },
        ]
        let actions = [
            <Button key='btn_faq_create' onClick={this.openModal} 
            floated='right' color='green' 
            content='Add New FAQ' icon='plus' 
            labelPosition='right' />
        ]

        let ExpandedSection = ({ data }) => <div style={{padding: 50}} className='ui container' dangerouslySetInnerHTML={{__html: data.value}}></div>
        return(
            <div>
                <Card fluid color='blue'>
                    { this.state.isLoading ? <div>
                        <Loader active/>
                    </div> : ''}
                    <DataTable
                        title="FAQ"
                        columns={columns}
                        data={this.state.faqList}
                        highlightOnHover
                        defaultSortField="title"
                        defaultSortAsc={false}
                        actions={actions}
                        sortIcon={<Icon name='sort amount down'/>}
                        keyfield='id'
                        pagination
                        subHeader
                        expandableRows
                        expandableRowsComponent={<ExpandedSection />}
                        subHeaderComponent={
                            <Input onChange={this.tableSearch} size='small'
                            icon='search' placeholder='Search...' />
                        }
                        noDataComponent={<NoDataImage/>}
                    />
                </Card>
                <TransitionablePortal open={this.state.open}>
                    <Modal size='small' open closeOnEscape={true} closeOnDimmerClick={false}>
                        <Modal.Header>Manage FAQ</Modal.Header>
                        <Modal.Content>
                            <Form>
                                <Form.Input onChange={this.handleChange}
                                name='title' label='Title' value={this.state.faqForm.title}
                                placeholder='Type title...' />
                                <Form.Input onChange={this.handleChange}
                                name='category' label='Category' value={this.state.faqForm.category}
                                placeholder='Type category...' />
                                <div className="field">
                                    <label>Value</label>
                                    <ReactQuill value={this.state.faqForm.value} onChange={this.handleChange} />
                                </div>
                            </Form>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button content='Cancel' onClick={this.close} />
                            <Button primary loading={this.state.saveLoading} disabled={this.state.saveLoading}
                                content='Save'
                                onClick={this.handleSave}
                            />
                        </Modal.Actions>
                    </Modal>
                </TransitionablePortal>
            </div>
        )
    }

    tableSearch = (event, e) =>{
        let txtSearch = ''
        let stateSearch = this.state.search
        if(e !== undefined){
            if(stateSearch !== e.value){
                txtSearch = e.value
            }else{
                txtSearch = stateSearch
            }
        }else{
            txtSearch = stateSearch
        }
        if(txtSearch === ''){
            this.setState({faqList:this.state.oldFaqList, search: ''});
        }else{
            this.setState({faqList:tableSearch(this.state.oldFaqList, txtSearch), search: txtSearch});
        }
    }

    handleChange(e, data){
        let faqForm = this.state.faqForm
        let name = data.name !== undefined ? data.name : null
        if(name !== null){
            if(name === 'title'){
                faqForm.title = data.value
            }else{
                faqForm.category = data.value
            }
        }else{
            faqForm.value = e
        }
        this.setState({faqForm: faqForm})
    }

    handleRefreshData(){
        this.setState({isLoading:true});
        superagent.get(process.env.REACT_APP_API_URL+'api/staticinfo?type=faq')
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            this.setState({isLoading:false});
            if(res !== undefined){
                if(res.body.status !== undefined){
                    localStorage.clear();
                    alert('something wrong! You will be logout in 3 seconds')
                    setTimeout(()=>{
                        window.location.href='/'
                    }, 3000)
                }else{
                    let data = JSON.parse(res.body.value)
                    let tempFaqList = []
                    let idx = 1
                    data.forEach(function(val){
                        tempFaqList.push({
                            id: idx,
                            title: val.title,
                            value: val.value,
                            category: val.category
                        })
                        idx++
                    })
                    this.setState({faqList:tempFaqList, oldFaqList:tempFaqList})
                    if(this.state.search !== ''){
                        this.tableSearch()
                    }
                }
            }else{
                localStorage.clear();
                alert('something wrong! You will be logout in 3 seconds')
                setTimeout(()=>{
                    window.location.href='/'
                }, 3000)
            }
        })
    }

    handleTableAction(event, e){
        let faqData = this.state.faqList
        switch (e.action) {
            case 0:
                let selectedFaq = faqData.filter(x=>x.id === e.faq_id)
                if(selectedFaq.length > 0){
                    selectedFaq = selectedFaq[0]
                }
                this.setState({faqForm: selectedFaq, open: true, isUpdate: true})
                break;
            default:
                if(window.confirm('are you sure want to delete?')){
                    let faqData = this.state.oldFaqList
                    let dataIndex = faqData.findIndex(x=>x.id === e.faq_id)
                    let dataReq = []
                    if(dataIndex >= 0){
                        faqData.splice(dataIndex, 1)
                        faqData.forEach(function(val){
                            dataReq.push({
                                title: val.title,
                                category: val.category,
                                value: val.value
                            })
                        })
                        this.setState({isLoading: true})
                        superagent
                        .post(process.env.REACT_APP_API_URL+'api/staticinfo')
                        .send({
                            type:'faq',
                            value: JSON.stringify(dataReq)
                        })
                        .set('X-API-KEY', this.state.userInfo.api_key)
                        .end((err, res) => {
                            if(res.body.status === 200){
                                this.setState({isLoading:false});
                                alert('faq deleted!');
                                this.handleRefreshData();
                            }else if(res.body.status >= 400 && res.body.status !== 500){
                                localStorage.clear();
                                alert('something wrong! You will be logout in 3 seconds')
                                setTimeout(()=>{
                                    window.location.href='/'
                                }, 3000)
                            }else{
                                alert('save failed!');
                            }
                        })
                    }
                }
                break;
        }
    }

    handleSave(){
        let faqForm = this.state.faqForm
        let faqList = this.state.oldFaqList
        let dataReq = []
        if(faqForm.title === '' || faqForm.category === '' || faqForm.value === ''){
            alert('please complete the form!')
            return false
        }

        if(this.state.isUpdate){
            let currData = faqList.filter(x=>x.id === faqForm.id)
            let idxData = faqList.findIndex(x=>x.id === faqForm.id)
            if(currData.length > 0){
                currData = currData[0]
                currData.title = faqForm.title
                currData.category = faqForm.category
                currData.value = faqForm.value
            }

            if(idxData >= 0){
                faqList[idxData] = currData
            }
        }else{
            faqList.push(faqForm)
        }

        faqList.forEach(function(val){
            dataReq.push({
                title: val.title,
                category: val.category,
                value: val.value
            })
        })

        this.setState({saveLoading: true})
        superagent
        .post(process.env.REACT_APP_API_URL+'api/staticinfo')
        .send({
            type:'faq',
            value: JSON.stringify(dataReq)
        })
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            if(res.body.status === 200){
                this.setState({saveLoading:false, open: false});
                alert('faq saved!');
                this.handleRefreshData();
            }else if(res.body.status >= 400 && res.body.status !== 500){
                localStorage.clear();
                alert('something wrong! You will be logout in 3 seconds')
                setTimeout(()=>{
                    window.location.href='/'
                }, 3000)
            }else{
                alert('save failed!');
            }
        })
    }

    openModal = () => {
        this.setState({open: true})
        let faqForm = this.state.faqForm;
        faqForm.title = ''
        faqForm.category = ''
        faqForm.value = ''
        this.setState({ open: true, faqForm: faqForm, isUpdate: false }) 
    } 
    
    close = () => {
        this.setState({ open: false })
    }
}