import React from 'react'
import { Header, List, Image} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import NoDataImage from '../../../components/nodata'

export default class UserSection extends React.Component{
    render(){
        let currData = this.props.data
        return(
            <div>
                <Header as='h3' dividing>
                    Last added user
                </Header>
                {currData.contributors.last_added.length > 0 ? <List relaxed>
                    {currData.contributors.last_added.map((v,k)=><List.Item key={k}>
                        <Image avatar src={require('../../../../img/avatar/'+v.user.avatar+'.png')} />
                        <List.Content>
                            <List.Header><Link to={'/admin/user/'+v.user.username}>{v.user.full_name === null ? v.user.username : v.user.full_name}</Link></List.Header>
                            <List.Description>
                            on {v.created_at}
                            </List.Description>
                        </List.Content>
                    </List.Item>)}
                </List> : <NoDataImage size='small'/>}
                
                <Header as='h3' dividing>
                    Recent online user
                </Header>
                {currData.contributors.active.length > 0 ? <List relaxed>
                    {currData.contributors.active.map((v,k)=><List.Item key={k}>
                        <Image avatar src={require('../../../../img/avatar/'+v.avatar+'.png')} />
                        <List.Content>
                            <List.Header><Link to={'/admin/user/'+v.username}>{v.full_name === null ? v.username : v.full_name}</Link></List.Header>
                        </List.Content>
                    </List.Item>)}
                </List> : <NoDataImage size='small'/>}
            </div>
        )
    }
}