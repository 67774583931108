import React from 'react'
import { Button, Modal,
    TransitionablePortal, Form, Dropdown } from 'semantic-ui-react'
import { getLocalStorage, setLocalStorage } from '../../../helper/local_storage'
const superagent = require('superagent')

export default class LanguageSetting extends React.Component{
    constructor(props){
        super(props)
        let userInfo = getLocalStorage('user')
        this.state = {
            userInfo: userInfo.user,
            languages: [],
            selectedLanguage: '',
            isSaveDetail: false
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSaveProfile = this.handleSaveProfile.bind(this)
    }

    componentWillMount(){
        superagent
        .get(process.env.REACT_APP_API_URL+'api/languages')
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            let langs = res.body
            let tempLangs = []
            Array.from(langs).forEach(v=>{
                tempLangs.push({
                    key: v.language_code,
                    value: v.language_code,
                    text: v.language_name
                })
            })
            this.setState({languages: tempLangs})
        });
    }

    render(){

        return(
            <TransitionablePortal open={this.state.isSettingOpen} trigger={
                <Dropdown.Item icon='language' text='Language Preference' onClick={this.openSetting}/>
            }>
                <Modal size='mini' open={this.state.isSettingOpen}>
                    <Modal.Header>Prefered Language</Modal.Header>
                    <Modal.Content>
                        <Form>
                            <Form.Field name='preferred_language' control={Dropdown}
                            options={this.state.languages} fluid clearable
                            search onChange={this.handleChange}
                            multiple
                            selection
                            placeholder='Select Language' />
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.closeSetting}>
                            Cancel
                        </Button>
                        <Button primary loading={this.state.isSaveDetail}
                            disabled={this.state.isSaveDetail} onClick={this.handleSaveProfile}>
                            Save
                        </Button>
                    </Modal.Actions>
                </Modal>
            </TransitionablePortal>
        )
    }

    test = () =>{
        alert('test')
    }

    handleChange(e, data){
        this.setState({selectedLanguage: data.value.join(',')})
    }

    openSetting = () =>{
        this.setState({isSettingOpen:true})
    }

    closeSetting = () =>{this.setState({isSettingOpen:false})}

    handleSaveProfile(){
        this.setState({isSaveDetail: true})
        superagent
        .post(process.env.REACT_APP_API_URL+'api/update/user/detail')
        .send({preferred_language: this.state.selectedLanguage})
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            this.setState({isSaveDetail: false})
            if(res.body.status === 200){
                alert('profile updated')
                let user = res.body.data
                setLocalStorage('user', {
                    isLoggedIn: true,
                    user: user
                })
                let lg = user.preferred_language.split(',')
                lg.sort()
                window.location.href="/contributor/"+lg[0]
            }else{
                alert('save failed!')
            }
        })
    }
}
