import React from 'react'
import { Menu } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

export default function SecondNav(props){
    let activePath = props.match.pathname.split('/');
    activePath = activePath[activePath.length-1];
    return(
        <Menu secondary>
            {
                props.data.map((val, i) =>{
                    return (
                        <Link to={val.target} key={i} className={'item'+ (activePath === val.path ? ' active' : '')}>{val.text}</Link>
                    )
                })
            }
        </Menu>
    )
}