import React from 'react'
import { Image, Header, Grid, Modal, Button } from 'semantic-ui-react'
import NoDataImage from '../../../admin/components/nodata'

export default class ProjectSection extends React.Component{
    render(){
        let userDetail = this.props.user
        let limit = userDetail.user_project.length > 9 ? userDetail.user_project.slice(0, 9) : userDetail.user_project
        return(
            <div>
                <Grid columns={3}>
                {limit.length > 0 ? limit.map((v,k)=><Grid.Column key={k} className="center aligned">
                    <Image src={require('../../../img/projects/'+v.project_thumbnail+'.png')} />
                    <Header as='h4' style={{marginTop:0}}>{v.project_id}</Header>
                    </Grid.Column>) : <NoDataImage/>}
                </Grid>
                {userDetail.user_project.length > 9 ? <Modal closeOnDimmerClick trigger={<Button size='mini'>View More</Button>}>
                    <Modal.Header>List of projects</Modal.Header>
                    <Modal.Content scrolling>
                        <Grid columns={10}>
                            {limit.map((v,k)=><Grid.Column key={k} className="center aligned">
                            <Image src={require('../../../img/projects/'+v.project_thumbnail+'.png')} />
                            <Header as='h4' style={{marginTop:0}}>{v.project_id}</Header>
                            </Grid.Column>)}
                        </Grid>
                    </Modal.Content>
                </Modal> : ''}
            </div>
        )
    }
}