import React from 'react'
import { Statistic, Grid, Header, Image, Button, Modal } from 'semantic-ui-react'

export default class ProjectSection extends React.Component{
    render(){
        let currData = this.props.data
        currData.recent = currData.recent === null ? [] : currData.recent
        let limitList = currData.list.length > 5 ? currData.list.slice(0, 5) : currData.list
        return(
            <div>
                <Grid columns='two' divided='vertically'>
                    <Grid.Row>
                        <Grid.Column width={6}>
                            <Statistic className='total-statistic'>
                                <Statistic.Value>{currData.total}</Statistic.Value>
                                <Statistic.Label>Total Project</Statistic.Label>
                            </Statistic>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Header as='h4'>Recent added projects</Header>
                            <Grid columns={3}>
                                {currData.recent.map((v,k)=><Grid.Column key={k} className="center aligned">
                                <Image src={require('../../../img/projects/'+v.project_thumbnail+'.png')} />
                                <Header as='h4' style={{marginTop:0}}>{v.project_id}</Header>
                                </Grid.Column>) }
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Grid columns={5}>
                                {limitList.map((v,k)=><Grid.Column key={k} className="center aligned">
                                <Image src={require('../../../img/projects/'+v.project_thumbnail+'.png')} />
                                <Header as='h4' style={{marginTop:0}}>{v.project_id}</Header>
                                </Grid.Column>) }
                            </Grid>
                            {currData.list.length > 5 ? 
                            <Modal closeOnDimmerClick trigger={<Button floated='right' size='mini'>View More</Button>}>
                                <Modal.Header>List of projects</Modal.Header>
                                <Modal.Content scrolling>
                                    <Grid columns={10}>
                                        {currData.list.map((v,k)=><Grid.Column key={k} className="center aligned">
                                        <Image src={require('../../../img/projects/'+v.project_thumbnail+'.png')} />
                                        <Header as='h4' style={{marginTop:0}}>{v.project_id}</Header>
                                        </Grid.Column>) }
                                    </Grid>
                                </Modal.Content>
                            </Modal> : ''}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}