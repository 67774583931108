import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

export default class TranslationSection extends React.Component{
    render(){
        let userDetail = this.props.user
        let langs = []
        let totChar = []
        let totLine = []
        let totWord = []
        let totAcc = []
        let totRej = []
        let totAssign = []
        userDetail.stats.forEach(function(val){
            langs.push(val.lang.language_name)
            totChar.push(val.total_character)
            totLine.push(val.total_line)
            totWord.push(val.total_word)
            totAcc.push(val.total_translation_accepted)
            totRej.push(val.total_translation_rejected)
            totAssign.push(val.total_translation_assignment)
        })
        const options = {
            title: {
              text: 'Translation Activity Chart'
            },
            xAxis: {
                categories: langs,
            },
            credits: {
                enabled: false
            },
            series: [
                { name:'Character', data: totChar, type: 'column' },
                { name:'Lines', data: totLine, type: 'column' },
                { name:'Words', data: totWord, type: 'column' },
                { name:'Accepted', data: totAcc, type: 'column' },
                { name:'Rejected', data: totRej, type: 'column' },
                { name:'Assigned', data: totAssign, type: 'column' }
            ]
        }
        return(
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        )
    }
}