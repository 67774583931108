import React from 'react'
import DataTable from 'react-data-table-component'
import { tableSearch } from '../../helper/dataTable'
import { 
    Loader, Button, Icon,
    Modal, Form, Card, Input, TransitionablePortal, Flag
} from 'semantic-ui-react'
import { getLocalStorage } from '../../helper/local_storage'
import { getAllFlag } from '../../helper/dummy_data'
import NoDataImage from '../../admin/components/nodata'
const superagent = require('superagent')

export default class LanguageOwner extends React.Component{
    constructor(props){
        super(props)
        let userInfo = getLocalStorage('user');
        this.state = {
            userInfo: userInfo.user,
            langList: [],
            oldLangList: [],
            isLoading: true,
            langForm: {
                language_code: '',
                language_name: '',
                language_flag: ''
            },
            open: false,
            saveLoading: false,
            isUpdate: false,
            listFlag: [],
            search: ''
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleRefreshData = this.handleRefreshData.bind(this)
        this.handleTableAction = this.handleTableAction.bind(this)
        this.handleSave = this.handleSave.bind(this)
    }

    componentWillMount(){
        this.handleRefreshData();
    }

    componentDidMount(){
        this.setState({listFlag: getAllFlag()})
    }

    render(){
        let columns = [
            {
                name: 'Code',
                selector: 'language_code',
                sortable: true,
                ignoreRowClick: true,
                allowOverflow: true,
                wrap: true
            },
            {
                name: 'Name',
                selector: 'language_name',
                sortable: true,
                ignoreRowClick: true,
                allowOverflow: true,
                wrap: true
            },
            {
                name: 'Flag',
                selector: 'language_flag',
                sortable: true,
                ignoreRowClick: true,
                allowOverflow: true,
                cell: row => {
                    return <Flag name={row.language_flag} />
                },
                wrap: true
            },
            {
                name: 'Last Modified',
                selector: 'updated_at',
                sortable: true,
                ignoreRowClick: true,
                allowOverflow: true,
                wrap: true
            },
            {
                name: 'Actions',
                cell: row => {
                    let options = [
                        { key: 0, color: 'blue', text: 'Edit', value: 'edit' },
                        { key: 1, color: 'red', text: 'Remove', value: 'delete' },
                    ];
                    return (
                        <div>
                            {
                                options.map((val, k) =><Button key={k} size='small' color={val.color} l_code={row.language_code} action={val.key} onClick={this.handleTableAction}>{val.text}</Button>)
                            }
                        </div>
                    )
                },
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
                wrap: true
            },
        ]
        let actions = [
            <Button key='btn_lang_create' onClick={this.openModal} 
            floated='right' color='green' 
            content='Add New Language' icon='plus' 
            labelPosition='right' />
        ]
        return(
            <div>
                <Card fluid color='blue'>
                    { this.state.isLoading ? <div>
                        <Loader active/>
                    </div> : ''}
                    <DataTable
                        title="Languages"
                        columns={columns}
                        data={this.state.langList}
                        highlightOnHover
                        defaultSortField="language_code"
                        defaultSortAsc={false}
                        actions={actions}
                        sortIcon={<Icon name='sort amount down'/>}
                        keyfield='language_code'
                        pagination
                        subHeader
                        subHeaderComponent={
                            <Input onChange={this.tableSearch} size='small'
                            icon='search' placeholder='Search...' />
                        }
                        noDataComponent={<NoDataImage/>}
                    />
                </Card>
                <TransitionablePortal open={this.state.open}>
                    <Modal size='mini' open closeOnEscape={true} closeOnDimmerClick={false}>
                        <Modal.Header>Manage Language</Modal.Header>
                        <Modal.Content>
                            <Form>
                                <Form.Input onChange={this.handleChange} readOnly={this.state.isUpdate}
                                name='code' label='Language Code' value={this.state.langForm.language_code} 
                                placeholder='Type language code (es, id, in)...' />
                                <Form.Input onChange={this.handleChange}
                                name='lang_name' label='Language Name' value={this.state.langForm.language_name} 
                                placeholder='Type language name (Spain, Bahasa, Hindi)...' />
                                <Form.Dropdown onChange={this.handleChange} label='Language Flag' placeholder='Select Country'
                                    fluid
                                    search
                                    selection
                                    value={this.state.langForm.language_flag}
                                    options={this.state.listFlag}/>
                            </Form>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button content='Cancel' onClick={this.close} />
                            <Button primary loading={this.state.saveLoading} disabled={this.state.saveLoading}
                                content='Save'
                                onClick={this.handleSave}
                            />
                        </Modal.Actions>
                    </Modal>
                </TransitionablePortal>
            </div>
        )
    }

    handleChange(e, data){
        let lForm = this.state.langForm
        let name = data.name
        switch (name) {
            case 'code':
                lForm.language_code = data.value
                break;
            case 'lang_name':
                lForm.language_name = data.value
                break;
            default:
                lForm.language_flag = data.value
                break;
        }
        this.setState({langForm: lForm})
    }

    handleRefreshData(){
        this.setState({isLoading:true});
        superagent.get(process.env.REACT_APP_API_URL+'api/languages')
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            this.setState({isLoading:false});
            if(res !== undefined){
                if(res.body.status !== undefined){
                    localStorage.clear();
                    alert('something wrong! You will be logout in 3 seconds')
                    setTimeout(()=>{
                        window.location.href='/'
                    }, 3000)
                }else{
                    this.setState({langList:res.body, oldLangList:res.body})
                    if(this.state.search !== ''){
                        this.tableSearch()
                    }
                }
            }else{
                localStorage.clear();
                alert('something wrong! You will be logout in 3 seconds')
                setTimeout(()=>{
                    window.location.href='/'
                }, 3000)
            }
        })
    }

    tableSearch = (event, e) =>{
        let txtSearch = ''
        let stateSearch = this.state.search
        if(e !== undefined){
            if(stateSearch !== e.value){
                txtSearch = e.value
            }else{
                txtSearch = stateSearch
            }
        }else{
            txtSearch = stateSearch
        }
        if(txtSearch === ''){
            this.setState({langList:this.state.oldLangList, search: ''});
        }else{
            this.setState({langList:tableSearch(this.state.oldLangList, txtSearch), search: txtSearch});
        }
    }

    handleTableAction(event, e){
        let lData = this.state.langList
        switch (e.action) {
            case 0:
                let selectedLang = lData.filter(x=>x.language_code === e.l_code)
                if(selectedLang.length > 0){
                    selectedLang = selectedLang[0]
                }
                this.setState({langForm: selectedLang, open: true, isUpdate: true})
                break;
            default:
                if(window.confirm('are you sure want to delete?')){
                    this.setState({isLoading: true})
                    superagent
                    .delete(process.env.REACT_APP_API_URL+'api/language/remove/'+e.l_code)
                    .set('X-API-KEY', this.state.userInfo.api_key)
                    .end((err, res) => {
                        if(res.body.status === 200){
                            this.setState({isLoading:false})
                            alert('language deleted!')
                            this.handleRefreshData()
                        }else if(res.body.status >= 400 && res.body.status !== 500){
                            localStorage.clear()
                            alert('something wrong! You will be logout in 3 seconds')
                            setTimeout(()=>{
                                window.location.href='/'
                            }, 3000)
                        }else{
                            alert('delete failed!')
                        }
                    })
                }
                break;
        }
    }

    openModal = () => {
        let lForm = this.state.langForm;
        lForm.language_code = '';
        lForm.language_name = '';
        lForm.language_flag = '';
        this.setState({ open: true, langForm: lForm, isUpdate: false }) 
    } 
    
    close = () => {
        this.setState({ open: false })
    }

    handleSave(){
        let lForm = this.state.langForm
        if(lForm.language_flag === '' || lForm.language_code === '' || lForm.language_name === ''){
            alert('please complete the form!')
            return false
        }
        let url = 'api/language/save'
        if(this.state.isUpdate){
            url = url + '/'+lForm.language_code
        }
        this.setState({saveLoading: true})
        superagent
        .post(process.env.REACT_APP_API_URL+url)
        .send(lForm)
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            if(res.body.status === 200){
                this.setState({saveLoading:false, open: false});
                alert('language saved!');
                this.handleRefreshData();
            }else if(res.body.status >= 400 && res.body.status !== 500){
                localStorage.clear();
                alert('something wrong! You will be logout in 3 seconds')
                setTimeout(()=>{
                    window.location.href='/'
                }, 3000)
            }else{
                alert('save failed!');
            }
        })
    }
}