import React from 'react'
import { Feed, Tab } from 'semantic-ui-react'
import NoDataImage from '../../components/nodata'

export default class ActivitySection extends React.Component{
    render(){
        let userDetail = this.props.user
        let panes = []
        if(userDetail.is_translator){
            panes.push({
                menuItem: 'Translator', render: () => 
                userDetail.last_activities.last_translation_activities.length > 0 ? 
                <Feed>
                    {userDetail.last_activities.last_translation_activities.map((val, i)=>
                        {
                            let msg = ''
                            let type = 'added'
                            if(val.before_value !== '' && val.before_value !== null){
                                type = 'modified'
                            }

                            msg = val.username +' '+ type +' '+ val.audit_type
                        +'\nbefore:'+val.before_value+' after:'+val.after_value
                            return(
                                <Feed.Event key={i}
                                icon='pencil'
                                date={val.updated_at}
                                summary={msg}
                                />
                            )
                        }
                    )}
                </Feed>
                : <NoDataImage size='small'/>
            })
        }
        if(userDetail.is_validator){
            panes.push({
                menuItem: 'Validator', render: () => 
                userDetail.last_activities.last_validation_activities.length > 0 ?
                <Feed>
                    {userDetail.last_activities.last_validation_activities.map((val, i)=>
                        {
                            let msg = ''
                            let type = 'added'
                            if(val.before_value !== '' && val.before_value !== null){
                                if(val.audit_type === 'translation' || val.audit_type === 'base'){
                                    type = 'modified'
                                }else{
                                    type = 'did'
                                }
                            }

                            msg = val.username +' '+ type +' '+ val.audit_type
                            return(
                                <Feed.Event key={i}
                                icon='pencil'
                                date={val.updated_at}
                                summary={msg}
                                />
                            )
                        }
                    )}
                </Feed>
                : <NoDataImage size='small'/>
            })
        }
        
        return(
            <Tab menu={{ secondary: true }} panes={panes} />
        )
    }
}