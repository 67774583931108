import React from 'react'
import { Placeholder } from 'semantic-ui-react'
export default function PlaceholderComponent(props){
    let component = {};
    if(props.type === 'profile'){
        component = <Placeholder>
            <Placeholder.Image square />
            <Placeholder.Header>
            <Placeholder.Line length='very short' />
            <Placeholder.Line length='medium' />
            </Placeholder.Header>
            <Placeholder.Paragraph>
            <Placeholder.Line length='short' />
            </Placeholder.Paragraph>
        </Placeholder>
    }

    if(props.type === 'line'){
        component = <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
        </Placeholder>
    }

    if(props.type === 'paragraph'){
        component = <Placeholder>
            <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            </Placeholder.Paragraph>
            <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            </Placeholder.Paragraph>
        </Placeholder>
    }
    return component;
}