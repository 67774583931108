import React from 'react'
import { Button, Form, Segment,
Grid, Divider, Message } from 'semantic-ui-react'
import ReCAPTCHA from "react-google-recaptcha";
const superagent = require('superagent')
const recaptchaRef = React.createRef();

export default class ForgotPassword extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            isSuccess: false,
            isError: false,
            errorMessage: '',
            forgotForm: {
                captcha: '',
                email: ''
            }
        }

        this.handleFormChange = this.handleFormChange.bind(this)
        this.handleSubmitForgot = this.handleSubmitForgot.bind(this)
    }

    render(){
        return(
            <div className='login-form'>
            <style>{`
            body > div,
            body > div > div,
            body > div > div > div.login-form {
                height: 100%;
            }
            `}
            
            </style>
            <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
            <Grid.Column style={{ maxWidth: 425 }}>
                <Form size='large'>
                <Segment>
                    <img alt='logo' width="250px" src={require('../img/logo.png')} />
                    <Divider className="hidden" />
                    {this.state.isSuccess ? <Message positive>
                        <Message.Header>Success!</Message.Header>
                        <p>
                            Your forgot password sent.
                        </p>
                    </Message> : ''}
                    {this.state.isError ? <Message negative>
                        <Message.Header>Failed!</Message.Header>
                        <p>
                            {this.state.errorMessage}
                        </p>
                    </Message> : ''}
                    <Form.Input fluid icon='envelope' onChange={this.handleFormChange} autoComplete='email'
                    name='email' iconPosition='left' placeholder='Type your email...' required/>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey='6LfveIMUAAAAAPzd1W07Jt8o8mhKAantkiC3R3e_'
                        onChange={this.handleFormChange}
                    />
                    <Divider className="hidden" />
                    <Button onClick={this.handleSubmitForgot} 
                    disabled={this.state.isLoading} loading={this.state.isLoading} 
                    primary fluid >
                        Submit
                    </Button>
                    <br/>
                    <a href='/'>back to login page</a>
                </Segment>
                </Form>
            </Grid.Column>
            </Grid>
        </div>
        )
    }

    handleFormChange(e, data){
        let fForm = this.state.forgotForm
        if(data !== undefined){
            fForm.email = data.value
        }else{
            fForm.captcha = e;
        }
    }

    handleSubmitForgot(){
        let fForm = this.state.forgotForm
        if(fForm.captcha === '' || fForm.email === ''){
            alert('please complete the form!')
            return false
        }
        this.setState({isLoading:true})
        superagent
        .post(process.env.REACT_APP_API_URL+'api/user/forgot')
        .send(fForm)
        .end((err, res) => {
            this.setState({isLoading:false})
            if(res.body.status === 200){
                fForm.captcha = ''
                fForm.email = ''
                this.setState({forgotForm:fForm, isSuccess:true, isError: false})
                setTimeout(function(){
                    window.location.href = res.body.url
                }, 2000)
            }else{
                this.setState({forgotForm:fForm, isError:true, errorMessage: res.body.message, isSuccess: false})
            }
            recaptchaRef.current.reset()
        })
    }
}