import React from 'react'
import { Route } from 'react-router-dom'

import Dashboard from '../projects/dashboard/dashboard'
import Translation from '../projects/translation/translation'
import ManageBase from '../projects/base/manage_base'
import History from '../projects/history/history'
import Versioning from '../projects/versioning/versioning'
import Settings from '../projects/settings/setting'

export default class ProjectRoute extends React.Component{
    render(){
        return(
            <div>
                <Route exact path='/admin/projects/:project_id/dashboard' component={Dashboard} />
                <Route exact path='/admin/projects/:project_id/translation' component={Translation} />
                <Route exact path='/admin/projects/:project_id/base' component={ManageBase} />
                <Route exact path='/admin/projects/:project_id/history' component={History} />
                <Route exact path='/admin/projects/:project_id/versioning' component={Versioning} />
                <Route exact path='/admin/projects/:project_id/settings' component={Settings} />
            </div>
        )
    }
}