import React from 'react'
import { getLocalStorage } from '../../../helper/local_storage'
import { Menu, Dropdown, Image } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import './main_nav.scss'
const superagent = require('superagent');

export default function MainNav(props){
    let userLS = getLocalStorage('user');
    const [userInfo] = React.useState(userLS.user);
    function logout(){
        superagent.get(process.env.REACT_APP_API_URL+'api/user/logout')
        .set('X-API-KEY', userInfo.api_key)
        .end((err, res) => {
            localStorage.clear();
            window.location.href='/'
        });
    }
    let activePath = window.location.pathname.split('/');
    activePath = activePath[2];
    let trigger = (
      <span>
        <Image avatar src={require('../../../img/avatar/'+userInfo.avatar+'.png')} /> {userInfo.email}
      </span>
    )
    let dropOpt = [
      { key: 'logout', text: 'Logout', onClick: logout }
    ]
    return (
        <Menu stackable fixed={'top'} className={'inverted'}>
            <Link to="/" className='header item'>
            <img alt="test" src={require('../../../img/logo-header.png')} />
            </Link>
            <Link to="/admin/projects" className={'item'+ (activePath === 'projects' ? ' active' : '')}>Projects</Link>
            <Link to='/admin/user' className={'item'+ (activePath === 'user' ? ' active' : '')}>User Management</Link>
            <Link to='/admin/faq' className={'item'+ (activePath === 'faq' ? ' active' : '')}>FAQ</Link>
            <Menu.Menu position='right'>
              <Dropdown compact item trigger={trigger} options={dropOpt} />
              {/* <Dropdown item text={userInfo.email}>
                <Dropdown.Menu>
                  <Dropdown.Item  onClick={logout}>
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
            </Menu.Menu>
        </Menu>
    )
}
