export function tableSearch(data, search){
    let rows = [];
    data.forEach(function(val, k){
        let idx = null;
        for(var key in val){
            if(val[key] !== undefined && val[key] !== null){
                search = search.toLowerCase()
                let txt = val[key]
                if(txt.toString().toLowerCase().indexOf(search) !== -1 && idx !== k){
                    rows.push(val);
                    idx = k;
                }
            }
        }
    });
    return rows;
}