import React from 'react'
import { Button, Icon, Form, List, Loader } from 'semantic-ui-react'
const superagent = require('superagent')

export default class SettingContent extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            current: '',
            isLoading: false,
            settingData: '',
            xmlSetting: [],
            settingForm:{
                key: '',
                value: '',
                font: null,
                flag: null
            },
            isSaveLoading: false,
            settingId: null
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSaveSetting = this.handleSaveSetting.bind(this)
        this.handleFile = this.handleFile.bind(this)
        this.handleXMLSetting = this.handleXMLSetting.bind(this)
    }

    componentWillMount(){
        this.setState({current: this.props.data})
        this.getSettingData()
    }

    componentDidUpdate(){
        if(this.state.current !== '' && this.state.current.lang !== this.props.data.lang){
            this.setState({current: this.props.data})
            this.getSettingData()
        }
    }

    render(){
        return(
            <div>
                {this.state.isLoading ? <Loader active/> : <Form>
                    <Form.Input label={
                        <div>
                            Font File {this.state.settingData !== '' ? 
                                <b>(<a href={this.state.settingData.font} rel="noopener noreferrer" target='_blank'>{this.state.settingData.font}</a>)</b>
                            : ''}
                        </div>
                    } type='file' onChange={this.handleChange}
                    name='font_file' />
                    <Form.Input label={
                        <div>
                            Flag File {this.state.settingData !== '' ? 
                                <b>(<a href={this.state.settingData.flag} rel="noopener noreferrer" target='_blank'>{this.state.settingData.flag}</a>)</b>
                            : ''}
                        </div>
                    } type='file' onChange={this.handleChange}
                    name='flag_file' />
                    <label>XML Setting</label>
                    {this.state.xmlSetting.length > 0 ? <List verticalAlign='middle' bulleted>
                        {this.state.xmlSetting.map((v,k)=><List.Item key={k}>
                            <List.Content floated='right'>
                                <Button id='remove_xml' xid={v.key} onClick={this.handleXMLSetting} 
                                size='tiny' color='red' icon><Icon name='remove' /></Button>
                            </List.Content>
                            <List.Content>{v.key} = {v.value}</List.Content>
                        </List.Item>)}
                    </List> : ''}
                    <Form.Group widths='equal'>
                        <Form.Input name='key' value={this.state.settingForm.key} onChange={this.handleChange} fluid label='Key' 
                        placeholder='Type key...' />
                        <Form.Input name='value' value={this.state.settingForm.value} onChange={this.handleChange} fluid label='Value' 
                        placeholder='Type value...' />
                    </Form.Group>
                    <Button primary floated='right' id='add_xml' onClick={this.handleXMLSetting}>Add XML Setting</Button>
                    <Button color='green' floated='right' loading={this.state.isSaveLoading} 
                    disabled={this.state.isSaveLoading} 
                    onClick={this.handleSaveSetting}>Update Setting</Button>
                </Form>}
            </div>
        )
    }

    getSettingData = () =>{
        this.setState({isLoading: true})
        let req = this.props.data
        superagent.get(process.env.REACT_APP_API_URL+'api/projectSettings/get/'+req.pid+'/'+req.lang)
        .set('X-API-KEY', req.key)
        .end((err, res) => {
            this.setState({isLoading: false})
            if(res.body.status === 200){
                let xmlSetting = []
                if(res.body.data.xml_setting !== ''){
                    xmlSetting = JSON.parse(res.body.data.xml_setting)
                }
                this.setState({settingData: res.body.data, xmlSetting: xmlSetting, settingId: res.body.data.setting_id})
            }else{
                this.setState({settingData: '', settingId: null, xmlSetting:[]})
            }
        })
    }

    handleChange(event, data){
        let cName = data.name
        let sForm = this.state.settingForm
        let value = data.value
        switch (cName) {
            case 'font_file':
                this.handleFile(event, cName)
                break
            case 'flag_file':
                this.handleFile(event, cName)
                break
            case 'key':
                sForm.key = value
                break
            default:
                sForm.value = value
                break
        }
        this.setState({
            settingForm: sForm
        })
    }   

    handleSaveSetting(){
        let url = 'api/projectSettings/save'
        if(this.state.settingId !== null){
            url = url+'/'+this.state.settingId
        }
        let sForm = this.state.settingForm
        let req = this.props.data
        
        let dataRequest = new FormData()
        dataRequest.append('font', sForm.font)
        dataRequest.append('flag', sForm.flag)
        dataRequest.append('project_id', req.pid)
        dataRequest.append('language', req.lang)
        dataRequest.append('xml_setting', JSON.stringify(this.state.xmlSetting))
        this.setState({isSaveLoading: true})
        superagent
        .post(process.env.REACT_APP_API_URL+url)
        .send(dataRequest)
        .set('X-API-KEY', req.key)
        .end((err, res) => {
            this.setState({isSaveLoading: false})
            if(res.body.status === 200){
                alert('setting updated')
                this.getSettingData()
            }else if(res.body.status >= 400 && res.body.status !== 500){
                localStorage.clear()
                alert('something wrong! You will be logout in 3 seconds')
                setTimeout(()=>{
                    window.location.href='/'
                }, 3000)
            }else{
                alert('update failed!')
            }
        })
    }

    handleFile(event, name){
        let sForm = this.state.settingForm
        let file = event.target.files[0]
        let fileType = file.name.split('.')
        fileType = fileType[fileType.length - 1]
        if(name === 'font_file'){
            if(fileType !== 'ttf'){
                alert('file format is not supported. extention must .ttf')
                event.target.value = null
                return false
            }
            sForm.font = event.target.files[0]
        }else{
            if(fileType !== 'jpg' && fileType !== 'png'){
                alert('file format is not supported. extention must .jpg or .png')
                event.target.value = null
                return false
            }
            sForm.flag = event.target.files[0]
        }
        this.setState({settingForm: sForm})
    }

    handleXMLSetting(e, data){
        let sForm = this.state.settingForm
        let xmlListData = this.state.xmlSetting
        
        if(data.xid !== undefined){
            let idx = xmlListData.findIndex(x=>x.key === data.xid)
            xmlListData.splice(idx, 1)
        }else{
            if(sForm.key === '' || sForm.value === ''){
                alert('xml setting key & value are required!')
                return false
            }
            let idx = xmlListData.findIndex(x=>x.key === sForm.key)
            if(idx < 0){
                xmlListData.push({
                    key: sForm.key,
                    value: sForm.value
                })
            }else{
                xmlListData[idx].value = sForm.value
            }
        }

        sForm.key = ''
        sForm.value = ''
        this.setState({sForm:sForm, xmlSetting: xmlListData})
    }
}