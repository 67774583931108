import React from 'react'
import { Button, Modal, TransitionablePortal, Transition
, Form, Dropdown, Image, Grid, Header, Label
, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import './user_manage.scss'
import PlaceholderComponent from '../components/placeholder'

import { getLocalStorage } from '../../helper/local_storage'
const superagent = require('superagent');


export default class MainUserManage extends React.Component{
    constructor(props){
        super(props)
        let userInfo = getLocalStorage('user');
        this.state = {
            modalOpen: false,
            userInfo: userInfo.user,
            projects: [],
            userList: [],
            isLoading: true,
            form: {
                email:'',
                is_translator:0,
                is_validator:0,
                owner: userInfo.user.username,
                role: userInfo.user.role === 0 ? 1 : userInfo.user.role === 1 ? 2 : 0,
                projects:'',
            },
            actionType:'',
            isUpdateUsername: ''
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSaveUser = this.handleSaveUser.bind(this);
        this.handleRefreshData = this.handleRefreshData.bind(this);
        this.handleActions = this.handleActions.bind(this);
    }

    componentWillMount(){
        let listProjects = [];
        superagent.get(process.env.REACT_APP_API_URL+'api/project/username')
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            if(res !== undefined){
                if(res.body.status !== undefined){
                    localStorage.clear();
                    alert('something wrong! You will be logout in 3 seconds')
                    setTimeout(()=>{
                        window.location.href='/'
                    }, 3000)
                }else{
                    if(res.body.length > 0){
                        res.body.forEach(function(val){
                            listProjects.push(
                                {
                                    key: val.project_id,
                                    value: val.project_id,
                                    text: val.project_id+' - '+val.project.project_name
                                }
                            )
                        });
                    }
                }
            }else{
                localStorage.clear();
                alert('something wrong! You will be logout in 3 seconds')
                setTimeout(()=>{
                    window.location.href='/'
                }, 3000)
            }
            this.setState({projects: listProjects});
        });

        this.handleRefreshData();
    }

    render(){
        return(
            <div>
                <Grid id={'userListGrid'} columns={4} stackable>
                    { this.state.isLoading ? <Grid.Column className='center align'>
                    <PlaceholderComponent type='profile' />
                    </Grid.Column> : 
                    this.state.userList.map((val, i) =>{
                        return (
                            <Transition key={i} transitionOnMount={true} animation='scale' duration={1000}>
                                <Grid.Column className="center aligned">
                                <Link to={'/admin/user/'+val.username}>
                                    <img alt='profile_image' className='ui small circular centered image' src={require('../../img/avatar/'+val.avatar+'.png')} />
                                    <Header as='h3'>{val.full_name === null ? val.username : val.full_name}</Header>
                                    <Label>
                                    <Icon name="mail" />
                                    {val.email}
                                    </Label>
                                </Link>
                                <button
                                    className="ui small primary button"
                                    onClick={() => this.handleActions('edit', val.username)}>
                                    <Icon name='pencil' />
                                    Edit
                                </button>
                                <button
                                    className="ui small negative icon button"
                                    onClick={() => this.handleActions('delete', val.username)}>
                                    <Icon name='trash' />
                                </button>
                                </Grid.Column>
                            </Transition>
                        )
                    })
                    }
                    
                    <Grid.Column className='center aligned'>
                        <Image id='addProject' src={require('../../img/add-project.png')} centered onClick={this.openForm} />
                        <Header as='h3'>Add New User</Header>
                    </Grid.Column>
                </Grid>
                <TransitionablePortal open={this.state.modalOpen}>
                    <Modal size='small' open={this.state.modalOpen}>
                        <Modal.Header>User</Modal.Header>
                        <Modal.Content>
                            <Form>
                                <Form.Group widths="equal">
                                    <Form.Input type='email' onChange={this.handleChange} name='email'
                                    label='Email' placeholder='Type Email...' readOnly={this.state.isUpdateUsername !== ''} value={this.state.form.email} />
                                    <Form.Field name='project' control={Dropdown} label='Projects'
                                    options={this.state.projects} fluid clearable
                                    search
                                    multiple
                                    selection
                                    onChange={this.handleChange}
                                    value={this.state.form.projects !== '' ? this.state.form.projects.split(','): []}
                                    placeholder='Select Projects' />
                                </Form.Group>
                                <Form.Group widths="equal">
                                    <Form.Checkbox checked={this.state.form.is_translator ? true : false} name='is_translator' label='Is Translator?' onChange={this.handleChange} />
                                    <Form.Checkbox checked={this.state.form.is_validator ? true : false} name='is_validator' label='Is Validator?' onChange={this.handleChange} />
                                </Form.Group>
                            </Form>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                content='Cancel'
                                onClick={this.close}
                            />
                            <Button primary loading={this.state.saveUserLoading} disabled={this.state.saveUserLoading}
                            content='Save'
                            onClick={this.handleSaveUser}
                            />
                        </Modal.Actions>
                    </Modal>
                </TransitionablePortal>
            </div>
        )
    }

    handleActions(type, uname){
        switch (type) {
            case 'delete':
                if(window.confirm('are you sure want to delete this user?')){
                    this.setState({isLoading:true});
                    superagent.delete(process.env.REACT_APP_API_URL+'api/user/remove/'+uname)
                    .set('X-API-KEY', this.state.userInfo.api_key)
                    .end((err, res) => {
                        this.setState({isLoading:false});
                        if(res !== undefined){
                            if(res.body.status === 200){
                                alert('user deleted');
                                this.handleRefreshData();
                            }else if(res.body.status >= 400 && res.body.status !== 500){
                                localStorage.clear();
                                alert('something wrong! You will be logout in 3 seconds')
                                setTimeout(()=>{
                                    window.location.href='/'
                                }, 3000)
                            }else{
                                alert('delete failed!');
                            }
                        }else{
                            localStorage.clear();
                            alert('something wrong! You will be logout in 3 seconds')
                            setTimeout(()=>{
                                window.location.href='/'
                            }, 3000)
                        }
                    });
                }
                break;
            default:
                this.setState({isLoading:true});
                superagent.get(process.env.REACT_APP_API_URL+'api/user/detail/'+uname)
                .set('X-API-KEY', this.state.userInfo.api_key)
                .end((err, res) => {
                    this.setState({isLoading:false});
                    if(res !== undefined){
                        if(res.body.status === 200){
                            let uData = res.body.data;
                            let proj = [];
                            uData.user_project.forEach(function(val){
                                proj.push(val.project_id);
                            });
                            let uForm = {
                                email: uData.email,
                                is_translator: uData.is_translator,
                                is_validator: uData.is_validator,
                                projects: proj.join()
                            }
                            this.setState({modalOpen:true, form:uForm, isUpdateUsername: uData.username})
                        }else if(res.body.status >= 400 && res.body.status !== 500){
                            localStorage.clear();
                            alert('something wrong! You will be logout in 3 seconds')
                            setTimeout(()=>{
                                window.location.href='/'
                            }, 3000)
                        }else{
                            alert('no data found!');
                        }
                    }else{
                        localStorage.clear();
                        alert('something wrong! You will be logout in 3 seconds')
                        setTimeout(()=>{
                            window.location.href='/'
                        }, 3000)
                    }
                });
                break;
        }
    }

    handleRefreshData(){
        superagent.get(process.env.REACT_APP_API_URL+'api/user/owner')
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            this.setState({isLoading:false});
            if(res !== undefined){
                if(res.body.status !== undefined){
                    localStorage.clear();
                    alert('something wrong! You will be logout in 3 seconds')
                    setTimeout(()=>{
                        window.location.href='/'
                    }, 3000)
                }else{
                    this.setState({userList: res.body});
                }
            }else{
                localStorage.clear();
                alert('something wrong! You will be logout in 3 seconds')
                setTimeout(()=>{
                    window.location.href='/'
                }, 3000)
            }
        });
    }

    handleChange(event, data){
        let cName = data.name
        let uForm = this.state.form;
        switch (cName) {
            case 'email':
                uForm.email = data.value;
                this.setState({
                    form: uForm
                });
                break;
            case 'project':
                uForm.projects = data.value.join();
                this.setState({
                    form: uForm
                });
                break;
            case 'is_translator':
                uForm.is_translator = data.checked ? 1 : 0;
                this.setState({
                    form: uForm
                });
                break;
            default:
                uForm.is_validator = data.checked ? 1 : 0;
                this.setState({
                    form: uForm
                });
                break;
        }
    }

    handleSaveUser(){
        let uForm = this.state.form;
        if(uForm.email === '' || uForm.projects === ''){
            alert('please complete the form');
            return false;
        }
        this.setState({saveUserLoading:true});
        let unameParam = this.state.isUpdateUsername !== '' ? '/'+this.state.isUpdateUsername : '';
        superagent
        .post(process.env.REACT_APP_API_URL+'api/user/save'+unameParam)
        .send(uForm)
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            this.setState({saveUserLoading:false});
            if(res !== undefined){
                if(res.body.status === 200){
                    alert('user saved!');
                    this.setState({modalOpen: false});
                    this.handleRefreshData();
                }else if(res.body.status >= 400 && res.body.status !== 500){
                    localStorage.clear();
                    alert('something wrong! You will be logout in 3 seconds')
                    setTimeout(()=>{
                        window.location.href='/'
                    }, 3000)
                }else{
                    alert('save failed!\n'+res.body.message);
                }
            }else{
                localStorage.clear();
                alert('something wrong! You will be logout in 3 seconds')
                setTimeout(()=>{
                    window.location.href='/'
                }, 3000)
            }
        });
    }

    close = () => this.setState({ modalOpen: false })
    openForm = () => {
        let uForm = {
            owner: this.state.userInfo.username,
            role: this.state.userInfo.role === 0 ? 1 : this.state.userInfo.role === 1 ? 2 : 0,
            projects: '',
            is_translator:0,
            is_validator:0
        }
        this.setState({ modalOpen: true, form: uForm, isUpdateUsername: '' })
    }
}
