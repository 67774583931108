import { Route } from 'react-router-dom'
import React from 'react'

import DashboardOwner from '../dashboard/dashboard'
import UserOwner from '../user_manage/user'
import MainStatic from '../static_manage/main'
import UserDetail from '../user_manage/user_detail'

export default function MainRoute(){
    return(
        <div>
            <Route path='/owner/dashboard' exact component={DashboardOwner} />
            <Route path='/owner/user' exact component={UserOwner} />
            <Route path='/owner/user/:username' exact component={UserDetail} />
            <Route path='/owner/static' component={MainStatic} />
        </div>
    )
}