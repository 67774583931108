import React from 'react'
import { Link } from 'react-router-dom'
import { Menu, Icon } from 'semantic-ui-react'
import { getLocalStorage } from '../../helper/local_storage'
const superagent = require('superagent')

export default class Language extends React.Component{
    constructor(props){
        super(props)
        let userInfo = getLocalStorage('user')
        this.state = {
            userInfo: userInfo.user,
            languages: []
        }
    }

    componentWillMount(){
        superagent
        .get(process.env.REACT_APP_API_URL+'api/languages')
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            let langs = res.body
            let tempLangs = []
            let preferredLanguage = this.state.userInfo.preferred_language.split(',')
            Array.from(langs).forEach(v=>{
                if(preferredLanguage.indexOf(v.language_code) !== -1){
                    tempLangs.push(v)
                }
            })
            
            this.setState({languages: tempLangs})
        });
    }

    render(){
        let pname = window.location.pathname.split('/')
        return(
            <Menu>
                {
                    this.state.languages.map((v, k)=>
                        <Link key={k} to={'/contributor/'+v.language_code} className={pname[2]===v.language_code?'item active':'item'}>
                            {v.language_name}
                        </Link>
                    )
                }

                <Menu.Menu position='right'>
                  <Menu.Item onClick={this.logout} className={'item'}>
                      <Icon name="sign out" />
                  </Menu.Item>
                </Menu.Menu>
            </Menu>
        )
    }

    logout = () =>{
        superagent.get(process.env.REACT_APP_API_URL+'api/user/logout')
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            localStorage.clear();
            window.location.href="/";
        });
    }
}
