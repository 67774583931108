import React from 'react'
import { Progress, Segment, Button, Header,
    Form, TextArea, Icon, Modal, Grid, Flag,
    Table, Loader, Container, Label, Message  } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { getLocalStorage } from '../../helper/local_storage'
import NoDataImage from '../../admin/components/nodata'
const superagent = require('superagent')

export default class Translation extends React.Component{
    constructor(props){
        super(props)
        let userInfo = getLocalStorage('user');
        this.state = {
            listTranslation: [],
            currentTranslation: null,
            currentProgress: 1,
            totalProgress: 10,
            newTranslate: '',
            userInfo: userInfo.user,
            translationIds: [],
            isLoading: true,
            isModalFinishOpen: false,
            isTableDisplay: false,
            allProgress: null,
            showTip: true
        }
        this.handleSubmitTranslation = this.handleSubmitTranslation.bind(this)
        this.handleFormChange = this.handleFormChange.bind(this)
        this.handleDisplayChange = this.handleDisplayChange.bind(this)
    }

    componentWillMount(){
        this.handleRefreshData()
    }

    handleDisplayChange(e, event){
        let isAct = false
        if(event.act === 'table'){
            isAct = true
        }
        if(this.state.isTableDisplay !== isAct){
            this.setState({isTableDisplay: isAct})
            this.handleRefreshData()
        }
    }

    handleFormChange(e, data){
        if(data.id !== undefined){
            let tData = this.state.listTranslation
            let filteredData = tData.filter(x=>x.translation_id === data.id);
            tData[tData.findIndex(i => i.translation_id === filteredData[0].translation_id)].translation_value = data.value
            this.setState({listTranslation:tData})
        }else{
            this.setState({newTranslate: data.value})
        }
    }

    handleSubmitTranslation(e, event){
        if(event.act === 'submit_table'){
            let tData = this.state.listTranslation
            let filteredData = tData.filter(x=>x.translation_id === event.id);
            if(filteredData.length > 0){
                filteredData = filteredData[0]
                superagent
                .post(process.env.REACT_APP_API_URL+'api/translation/manual/'+filteredData.translation_id)
                .send({translation: filteredData.translation_value})
                .set('X-API-KEY', this.state.userInfo.api_key)
                .end((err, res) => {
                    tData[tData.findIndex(i => i.translation_id === filteredData.translation_id)].is_verified = true
                    let idx = 1;
                    let isShow = false;
                    tData.forEach(function(val){
                        if(!val.is_verified){
                            return false;
                        }else{
                            if(idx === tData.length){
                                isShow = true
                            }
                        }
                        idx++
                    })
                    this.setState({listTranslation:isShow ? [] : tData, isModalFinishOpen: isShow})
                })
            }
        }else{
            let count = this.state.currentProgress;
        
            if(count <= this.state.totalProgress){
                let listTranslation = this.state.listTranslation
                count = count + 1;
                let ids = this.state.translationIds
                let datacur = listTranslation.filter(function(item){
                    return ids.indexOf(item.translation_id) === -1;
                });
                if(datacur.length > 0){
                    ids.push(datacur[0].translation_id)
                }else{
                    ids = []
                }
                if(event.act === 'skip'){
                    superagent
                    .get(process.env.REACT_APP_API_URL+'api/reset/translation/'+this.state.currentTranslation.translation_id)
                    .set('X-API-KEY', this.state.userInfo.api_key)
                    .end((err, res) => {
                        this.setState({currentProgress: count, currentTranslation: datacur.length > 0 ? datacur[0] : null, 
                        newTranslate:'', translationIds: ids, isModalFinishOpen: count-1 === this.state.totalProgress ? true : false,
                        listTranslation: count-1 === this.state.totalProgress ? [] : this.state.listTranslation})
                    })
                }else{
                    let dataRequest = {
                        translation: this.state.newTranslate
                    }
                    superagent
                    .post(process.env.REACT_APP_API_URL+'api/translation/manual/'+this.state.currentTranslation.translation_id)
                    .send(dataRequest)
                    .set('X-API-KEY', this.state.userInfo.api_key)
                    .end((err, res) => {
                        this.setState({currentProgress: count, currentTranslation: datacur.length > 0 ? datacur[0] : null, 
                        newTranslate:'', translationIds: ids, isModalFinishOpen: count-1 === this.state.totalProgress ? true : false,
                        listTranslation: count-1 === this.state.totalProgress ? [] : this.state.listTranslation})
                    })
                }
            }
        }
    }

    handleRefreshData = () =>{
        this.setState({isLoading: true})
        superagent
        .get(process.env.REACT_APP_API_URL+'api/translation-list/pick?lang='+this.props.match.params.lang+'&type=translate')
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            this.setState({isLoading:false, isModalFinishOpen: false})
            if(res.body.data.length > 0){
                let ids = this.state.translationIds
                ids.push(res.body.data[0].translation_id)
                this.setState({listTranslation: res.body.data, currentTranslation: res.body.data[0], currentProgress: 1,
                totalProgress: res.body.data.length, translationIds:ids, 
                allProgress: res.body.progress !== undefined ? res.body.progress : null})
            }
        })
    }

    render(){
        return(
            <Container style={{padding:20}}>
                {this.state.showTip ? 
                <Message size='mini'
                info
                onDismiss={()=>this.setState({showTip: false})}
                header='Additional Information!'
                content={
                    <p>
                        Some of translations might contain variables, please take a look on <b>"How to Handle Variable?"</b>
                    </p>
                }
                />
                : null}
                {this.state.isLoading ? <Loader active>Fetching Translation...</Loader>:
                    this.state.listTranslation.length > 0 && this.state.currentTranslation !== null ? 
                    <div>
                        {this.state.allProgress !== null ? 
                            <div>
                                <Header as='h4'>Translation Progress ({this.state.allProgress.finished}/{this.state.allProgress.total})</Header>
                                <Progress percent={this.state.allProgress.percentage} 
                                color='green' progress/>
                            </div>
                        : null}
                        <Button.Group>
                            <Button act='single' onClick={this.handleDisplayChange} icon active={!this.state.isTableDisplay}>
                                <Icon name='th' />
                            </Button>
                            <Button act='table' onClick={this.handleDisplayChange} icon active={this.state.isTableDisplay}>
                                <Icon name='th list' />
                            </Button>
                        </Button.Group>
                        <Header as='h4' attached='top'>
                            Translation
                            <Modal trigger={<Icon style={{float: 'right'}} link name='question circle outline' />}>
                                <Modal.Header>Context Information</Modal.Header>
                                <Modal.Content>
                                <Grid columns={2} divided>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Modal.Description>
                                                <Header>General Context</Header>
                                                <p>{this.state.currentTranslation.project_info.project_description}</p>
                                            </Modal.Description>
                                        </Grid.Column>
                                        {!this.state.isTableDisplay ? <Grid.Column>
                                            <Modal.Description>
                                                <Header>Translation Context</Header>
                                                <p>{this.state.currentTranslation.base_info.base_description}</p>
                                            </Modal.Description>
                                        </Grid.Column> : ''}
                                    </Grid.Row>
                                </Grid>
                                </Modal.Content>
                            </Modal>
                            <Modal closeIcon trigger={
                                <Label style={{float: 'right'}} as='a' color='teal' tag>
                                    How to Handle Variable?
                                </Label>
                            }>
                                <Modal.Header>Variable Translation Guide</Modal.Header>
                                <Modal.Content>
                                <p>
                                Please find below the information you need to know before you start translating in localizy.<br/><br/>
                                Every translation that contain <b style={{color: '#00b5ad'}}>@</b> is a variable and need to write as it is, for example:
                                </p>
                                <Header as='h2' color='teal'>
                                    @&&USER_NAME&&
                                </Header>
                                <p>You must copy it and write exactly the same for the whole word 
                                and continue with the rest of the words based on specific language 
                                that you are translating or proofreading.</p>
                                </Modal.Content>
                            </Modal>
                        </Header>
                        {
                            this.state.isTableDisplay ? 
                            <Segment attached clearing>
                                <Table className='fixed'>
                                    <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell width={6}><Flag name='gb' /> ENGLISH</Table.HeaderCell>
                                        <Table.HeaderCell width={4} style={{textTransform:'uppercase'}}>
                                            {this.state.currentTranslation.language_info.language_name}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={2}>Action</Table.HeaderCell>
                                    </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {this.state.listTranslation.map((val, k)=>
                                            <Table.Row key={k}>
                                            <Table.Cell>
                                                {val.base_info.is_variable ? 
                                                    <Label style={{marginRight: 5}} size='mini' color='teal'>@</Label>
                                                : null}
                                                {val.base_info.base_value}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {!val.is_verified ? <Form>
                                                    <TextArea id={val.translation_id} onChange={this.handleFormChange} placeholder='Tell us more' />
                                                </Form> : val.translation_value}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {
                                                    !val.is_verified ? 
                                                    <Button act='submit_table' size='mini' id={val.translation_id} 
                                                    disabled={val.translation_value !== null ? val.translation_value.trim() === '' ? true : false : true}
                                                    onClick={this.handleSubmitTranslation} primary>Translate</Button>
                                                    : 'Submitted'
                                                }
                                                {
                                                    this.state.isTableDisplay ? <Modal trigger={<Icon style={{float: 'right'}} link name='question circle outline' />}>
                                                    <Modal.Header>Context Information</Modal.Header>
                                                    <Modal.Content>
                                                    <Grid columns={2} divided>
                                                        <Grid.Row>
                                                            <Grid.Column>
                                                                <Modal.Description>
                                                                    <Header>Translation Context</Header>
                                                                    <p>{val.base_info.base_description}</p>
                                                                </Modal.Description>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>
                                                    </Modal.Content>
                                                </Modal> : ''
                                                }
                                            </Table.Cell>
                                        </Table.Row>)}
                                    </Table.Body>
                                </Table>
                            </Segment>
                            : 
                            <Segment attached clearing>
                                <Progress color='blue' value={this.state.currentProgress} 
                                total={this.state.totalProgress} progress='ratio' />
                                <h5><Flag name='gb' /> ENGLISH</h5>
                                <h4>
                                    {this.state.currentTranslation.base_info.is_variable ? 
                                        <Label style={{marginRight: 5}} size='mini' color='teal'>@</Label>
                                    : null}
                                    {this.state.currentTranslation.base_info.base_value}
                                </h4>
                                <h5 style={{textTransform:'uppercase'}}>{this.state.currentTranslation.language_info.language_name}</h5>
                                <Form>
                                    <TextArea onChange={this.handleFormChange} value={this.state.newTranslate} placeholder='Tell us more' />
                                </Form>
                                <br/>
                                <Link to={'/contributor/'+this.props.match.params.lang} className='ui button'>BACK</Link>
                                <Button floated='right' act='submit' disabled={this.state.newTranslate.trim() === '' ? true : false} 
                                primary onClick={this.handleSubmitTranslation}>SUBMIT</Button>
                                <Button floated='right' act='skip' onClick={this.handleSubmitTranslation}>SKIP</Button>
                            </Segment>
                        }
                    </div> : <NoDataImage/>
                }

                <Modal dimmer='blurring' open={this.state.isModalFinishOpen} size='small'>
                    <Header icon='browser' content='Finish Submission' />
                    <Modal.Content>
                        <Modal.Description>
                        <h3>You've complete the steps, our system will be assiged you another available task.</h3>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                    <Button color='green' onClick={this.handleRefreshData} inverted>
                        <Icon name='checkmark' /> Got it
                    </Button>
                    </Modal.Actions>
                </Modal>
            </Container>
        )
    }
}