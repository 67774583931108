import React from 'react'
import { Accordion, Divider, Container, 
    Grid, Segment, Header, Loader } from 'semantic-ui-react'
import { BreadcrumbRoot } from '../components/breadcrumb/breadcrumb'
import { getLocalStorage } from '../../helper/local_storage'
import NoDataImage from '../components/nodata'
const superagent = require('superagent')

export default class LFAQ extends React.Component {
    constructor(props){
        super(props)
        let userInfo = getLocalStorage('user');
        this.state = {
            userInfo: userInfo.user,
            faqList: [],
            isLoading: true
        }
    }

    componentWillMount(){
        superagent.get(process.env.REACT_APP_API_URL+'api/staticinfo?type=faq')
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            this.setState({isLoading: false})
            if(res !== undefined){
                if(res.body.status !== undefined){
                    localStorage.clear();
                    alert('something wrong! You will be logout in 3 seconds')
                    setTimeout(()=>{
                        window.location.href='/'
                    }, 3000)
                }else{
                    let data = JSON.parse(res.body.value)
                    let tempFaqList = []
                    let idx = 1
                    data.forEach(function(val){
                        let isCatExist = tempFaqList.findIndex(x=>x.category === val.category)
                        if(isCatExist < 0){
                            tempFaqList.push({
                                category: val.category,
                                faq: []
                            })
                        }
                        let catIndex = tempFaqList.findIndex(x=>x.category === val.category)
                        tempFaqList[catIndex].faq.push({
                            key: 'faq_'+idx,
                            title: val.title,
                            content: {
                                content: (
                                    <Container className='faq_container' dangerouslySetInnerHTML={{__html: val.value}}>
                                    </Container>
                                ),
                            }
                        })
                        idx++
                    })
                    this.setState({faqList:tempFaqList})
                }
            }else{
                localStorage.clear();
                alert('something wrong! You will be logout in 3 seconds')
                setTimeout(()=>{
                    window.location.href='/'
                }, 3000)
            }
        })
    }


    render(){
        return(
            <div>
                <BreadcrumbRoot data={'FAQ'}/><Divider /><Divider hidden />
                <Grid columns={2} stackable>
                    {this.state.isLoading ? <Loader active>Loading...</Loader> : 
                    this.state.faqList.length > 0 ? this.state.faqList.map((v,k)=>
                    <Grid.Column key={k}>
                        <Segment>
                            <Header as='h2' dividing>
                                {v.category}
                            </Header>
                            <Accordion panels={v.faq} />
                        </Segment>
                    </Grid.Column>) : <NoDataImage/>
                    }
                </Grid>
            </div>
        )
    }
}