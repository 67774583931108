import React from 'react'
import { Image,
    Header, Label,
    Icon } from 'semantic-ui-react'

export default class ProfileSection extends React.Component{
    render(){
        let userDetail = this.props.user
        return(
            <div>
                <Image src={require('../../../img/avatar/'+userDetail.avatar+'.png')} />
                <Header as='h2'>{userDetail.full_name}</Header>
                <Header as='h2'>{userDetail.username}</Header>
                <p>{userDetail.email}</p>
                <i>created on {userDetail.created_at}</i>
                <Label.Group>
                    {
                        userDetail.is_new ? <Label color='green' size='large'><Icon name='user' /> New User</Label> : ''
                    }
                    {
                        userDetail.is_validator ? <Label color='orange' size='large'><Icon name='ald' /> Validator</Label> : ''
                    }
                    {
                        userDetail.is_translator ? <Label color='blue' size='large'><Icon name='language' /> Translator</Label> : ''
                    }
                </Label.Group>
            </div>
        )
    }
}