import React from 'react'
import { Divider, Grid, Segment } from 'semantic-ui-react'
import { BreadcrumbRoot } from '../../admin/components/breadcrumb/breadcrumb'
import { getLocalStorage } from '../../helper/local_storage'
import ProjectSection from './sections/project'
import TranslationSection from './sections/translation'
import UserSection from './sections/user'
import PlaceholderComponent from '../../admin/components/placeholder'
const superagent = require('superagent')

export default class DashboardOwner extends React.Component{
    constructor(props){
        super(props)
        let userInfo = getLocalStorage('user')
        this.state = {
            userInfo: userInfo.user,
            data: {},
            isLoading: true
        }
    }

    componentWillMount(){
        superagent.get(process.env.REACT_APP_API_URL+'api/dashboard/getOwner')
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            if(res.body.status === 200){
                this.setState({data: res.body.data, isLoading: false})
            }
        })
    }

    render(){
        let currData = this.state.data
        return(
            <div>
                <BreadcrumbRoot data={'Dashboard'}/><Divider /><Divider hidden />
                <Grid columns='two'>
                    <Grid.Column>
                        <Segment>
                            {this.state.isLoading ? <PlaceholderComponent type='line'/> : <ProjectSection data={currData.projects}/>}
                        </Segment>
                        <Segment>
                            {this.state.isLoading ? <PlaceholderComponent type='line'/> : <UserSection data={currData.users}/>}
                        </Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment>
                            {this.state.isLoading ? <PlaceholderComponent type='line'/> : <TranslationSection data={currData}/>}
                        </Segment>
                    </Grid.Column>
                </Grid>
            </div>
        )
    }
}