import React from 'react'
import { Statistic } from 'semantic-ui-react'

export default class UserSection extends React.Component{
    render(){
        let currData = this.props.data
        return(
            <div>
                <Statistic className='total-statistic'>
                    <Statistic.Value>{currData.total}</Statistic.Value>
                    <Statistic.Label>Total User</Statistic.Label>
                </Statistic>
                <Statistic.Group horizontal size='small'>
                    <Statistic>
                        <Statistic.Value>
                            {currData.roles.admin}
                        </Statistic.Value>
                        <Statistic.Label>Admin</Statistic.Label>
                    </Statistic>
                    <Statistic>
                        <Statistic.Value>
                            {currData.roles.translator}
                        </Statistic.Value>
                        <Statistic.Label>Translator</Statistic.Label>
                    </Statistic>
                    <Statistic>
                        <Statistic.Value>
                            {currData.roles.validator}
                        </Statistic.Value>
                        <Statistic.Label>Validator</Statistic.Label>
                    </Statistic>
                </Statistic.Group>
            </div>
        )
    }
}