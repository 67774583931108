import React from 'react'
import BreadcrumbComponent from '../../admin/components/breadcrumb/breadcrumb'
import { Grid, Segment, Divider, Header } from 'semantic-ui-react'
import { getLocalStorage } from '../../helper/local_storage'
import PlaceholderComponent from '../../admin/components/placeholder'
import ProfileSection from './sections/profile'
import ProjectSection from './sections/project'
import UserSection from './sections/user'
const superagent = require('superagent')

export default class UserDetail extends React.Component{
    constructor(props){
        super(props)
        let userInfo = getLocalStorage('user');
        this.state = {
            isLoading: true,
            userInfo: userInfo.user,
            userDetail: {}
        }
    }

    componentWillMount(){
        superagent.get(process.env.REACT_APP_API_URL+'api/user/role?role=1&username='+this.props.match.params.username)
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            if(res !== undefined){
                if(res.body.status !== undefined){
                    localStorage.clear();
                    alert('something wrong! You will be logout in 3 seconds')
                    setTimeout(()=>{
                        window.location.href='/'
                    }, 3000)
                }else{
                    this.setState({userDetail: res.body[0], isLoading:false})
                }
            }else{
                localStorage.clear();
                alert('something wrong! You will be logout in 3 seconds')
                setTimeout(()=>{
                    window.location.href='/'
                }, 3000)
            }
        })
    }
    
    render(){
        let pathName = this.props.match.params.username
        let sections = [{ key: 'Users', content: 'Users', link: true, target: '/owner/user' },
        { key: pathName, content: pathName, active: true },]
        return(
            <div>
                <BreadcrumbComponent data={sections}/><Divider /><Divider hidden />
                <Grid columns='equal'>
                    <Grid.Column>
                        <Segment>
                        {this.state.isLoading ? <PlaceholderComponent type='profile'/> : 
                        <ProfileSection user={this.state.userDetail}/>}
                        </Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment>
                            <Header as='h2'>Projects</Header>
                            {this.state.isLoading ? <PlaceholderComponent type='profile'/> : 
                                <ProjectSection user={this.state.userDetail}/>
                            }
                        </Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment>
                            <Header as='h2'>Users</Header>
                            {this.state.isLoading ? <PlaceholderComponent type='profile'/> : 
                                <UserSection user={this.state.userDetail}/>
                            }
                        </Segment>
                    </Grid.Column>
                </Grid>
            </div>
        )
    }
}