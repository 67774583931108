import React from 'react';
import { Container } from 'semantic-ui-react';
import MainNav from './components/main_nav/main_nav';
import { Redirect } from 'react-router-dom'

import MainRoute from './routes/main_route'
import { checkAuth } from '../helper/authentication'
import { getLocalStorage } from '../helper/local_storage'

export default class OwnerApp extends React.Component{
    render(){
        let isLoggedIn = checkAuth();
        if(isLoggedIn){
            let userInfo = getLocalStorage('user');
            if(userInfo.user.role === 0){
                return(
                    <div>
                        <MainNav/>
                        <Container style={{paddingTop: 100}}>
                            <MainRoute/>
                        </Container>
                    </div>
                )
            }else if(userInfo.user.role === 1){
                return <Redirect to='/admin/projects' />
            }else{
                return <Redirect to='/contributor' />
            }
        }else{
            return <Redirect to='/' />
        }
    }
}