import * as SecureLS from 'secure-ls'

export function setLocalStorage(name, data){
    let ls = new SecureLS();
    ls.set(name, data);
    return true;
}
export function getLocalStorage(name){
    let ls = new SecureLS();
    let data = ls.get(name);
    return data;
}
