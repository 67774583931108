import React from 'react'
import { getLocalStorage } from '../../../helper/local_storage'
import { Feed, Flag,
    Loader, Icon, Card, Input
} from 'semantic-ui-react'
import DataTable from 'react-data-table-component'
import { tableSearch } from '../../../helper/dataTable'
import NoDataImage from '../../components/nodata'
const superagent = require('superagent')

const ExpandedSection = ({ data }) => {
    return(
        <Feed>
            {data.histories.map((val, k)=>
            {
                let text = {};
                let action = val.before_value === '' ? 'added' : 'modified';
                if(val.audit_type === 'translation'){
                    if(val.before_value !== ''){
                        text = <div>
                            {val.username} {action} {val.audit_type} in <Flag name={val.language_flag} />{val.language_name} language.<br/>
                            {val.before_value} <Icon name='arrow right' /> {val.after_value}
                        </div>
                    }else{
                        text = <div>
                            {val.username} {action} {val.audit_type} in <Flag name={val.language_flag} />{val.language_name} language.<br/>
                            Value : {val.after_value}
                        </div>
                    }
                }else{
                    if(val.before_value !== ''){
                        text = <div>
                            {val.username} {action} {val.audit_type}.<br/>
                            {val.before_value} <Icon name='arrow right' /> {val.after_value}
                        </div>
                    }else{
                        text = <div>
                            {val.username} {action} {val.audit_type}.<br/>
                            Value : {val.after_value}
                        </div>
                    }
                }
                return(
                    <Feed.Event key={k}>
                        <Feed.Label>
                            <Icon name='pencil' />
                        </Feed.Label>
                        <Feed.Content>
                            <Feed.Date>{val.updated_at}</Feed.Date>
                            <Feed.Summary>
                                {text}
                            </Feed.Summary>
                        </Feed.Content>
                    </Feed.Event>
                )
            })}
        </Feed>
    )
};

export default class History extends React.Component{
    constructor(props){
        super(props)
        let userInfo = getLocalStorage('user');
        this.state = {
            userInfo: userInfo.user,
            historyList: [],
            oldHistoryList: [],
            isLoading: true
        }
    }

    componentWillMount(){
        superagent.get(process.env.REACT_APP_API_URL+'api/history/'+this.props.match.params.project_id)
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            this.setState({isLoading:false})
            if(res !== undefined){
                if(res.body.status !== undefined){
                    localStorage.clear();
                    alert('something wrong! You will be logout in 3 seconds')
                    setTimeout(()=>{
                        window.location.href='/'
                    }, 3000)
                }else{
                    if(res.body.length > 0){
                        this.setState({historyList: res.body, oldHistoryList: res.body});
                    }
                }
            }else{
                localStorage.clear();
                alert('something wrong! You will be logout in 3 seconds')
                setTimeout(()=>{
                    window.location.href='/'
                }, 3000)
            }
        });
    }


    render(){
        let columns = [
            {
                name: 'Key',
                selector: 'base_key',
                sortable: true,
                ignoreRowClick: true,
                allowOverflow: true,
                wrap: true
            },
            {
                name: 'Base Value',
                selector: 'base_value',
                sortable: true,
                ignoreRowClick: true,
                allowOverflow: false,
                wrap: true
            },
            {
                name: 'Last Updated',
                selector: 'updated_at',
                sortable: true,
                ignoreRowClick: true,
                allowOverflow: false,
                wrap: true
            }
        ]
        return(
            <Card fluid color='blue'>
                { this.state.isLoading ? <div>
                    <Loader active/>
                </div> : ''}
                <DataTable
                    title="History"
                    columns={columns}
                    data={this.state.historyList}
                    highlightOnHover
                    defaultSortField="base_id"
                    defaultSortAsc={false}
                    sortIcon={<Icon name='sort amount down'/>}
                    keyfield='base_id'
                    pagination
                    subHeader
                    expandableRows
                    expandableRowsComponent={<ExpandedSection />}
                    subHeaderComponent={
                        <Input onChange={this.tableSearch} size='small'
                        icon='search' placeholder='Search...' />
                    }
                    noDataComponent={<NoDataImage/>}
                    />
            </Card>
        )
    }

    tableSearch = (event, e) =>{
        if(e !== undefined){
            if(e.value === ''){
                this.setState({historyList:this.state.oldHistoryList});
            }else{
                this.setState({historyList:tableSearch(this.state.oldHistoryList, e.value)});
            }
        }
    }
}