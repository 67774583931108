import React from 'react'
import { Modal, Popup, Button, Icon, TransitionablePortal,
    Label, Input, Divider,  ModalHeader,
    ModalContent,
    ModalActions, } from 'semantic-ui-react'
import { getLocalStorage } from '../../../helper/local_storage'
const superagent = require('superagent')
const iconInputSuccess = { name: 'add', 
circular: true, 
link: true, disabled: false,
color: 'green', onClick: null }
const iconInputError = <Popup open on='focus' content='url format is incorrect' position='right center'
trigger={<i aria-hidden="true" className="remove red circular icon"></i>} />

export default class PublicApiManage extends React.Component{
    constructor(props){
        super(props)
        let userInfo = getLocalStorage('user');
        this.state = {
            open: false,
            openDialogPublish: false,
            userInfo: userInfo.user,
            listApp: [],
            currentKey: '',
            currentAppName: '',
            currentId: '',
            iconInput: iconInputError,
            isLoadingGenerate: false,
            isLoadingSave: false,
            showResultPublishingModal: {type: '', isShow: false, message:''},
        }

        this.handleSaveExternal = this.handleSaveExternal.bind(this)
        this.handleRefreshData = this.handleRefreshData.bind(this)
        this.formChange = this.formChange.bind(this)
        this.handleRemove = this.handleRemove.bind(this)
        this.confirmPublish = this.confirmPublish.bind(this)
        this.openPublishDialog = this.openPublishDialog.bind(this)
        this.closePublishDialog = this.closePublishDialog.bind(this)
        this.isOnProjectDetailPage = this.isOnProjectDetailPage.bind(this)
        this.onOpenResultPublish = this.onOpenResultPublish.bind(this)
        this.onCloseResultPublish = this.onCloseResultPublish.bind(this)
    }

    componentWillMount(){
        this.handleRefreshData()
    }

    render(){
        let project = getLocalStorage('project');
        return(
            <div>
            {!this.state.open ? <Popup
                    content={
                    <div>
                        <b>{this.state.currentKey !== '' ? this.state.currentKey : 'Please generate to get key.'}</b><br/>

                    {this.state.currentKey !== '' ? <Button primary floated='right' size='mini' onClick={this.openModal}>Manage</Button> : ''}

                    <Button 
                        floated='right' 
                        size='mini' 
                        loading={this.state.isLoadingGenerate} 
                        disabled={this.state.isLoadingGenerate} 
                        onClick={this.handleGenerate}>
                            Generate
                    </Button>
                    </div>}
                    on='click'
                    pinned={true} position='left center'
                    trigger={<Button primary icon style={{bottom:35, position: 'relative'}} 
                        floated='right'><Icon name='keycdn' /> Public Api Key</Button>}
                /> : ''}

                {
                    this.isOnProjectDetailPage() && project.project?.is_versioning && <Button 
                    style={{bottom:35, position: 'relative'}} 
                    floated='right' key='btn_publish_debug' 
                    onClick={this.openPublishDialog}
                    color='green'
                >Publish</Button>
                }
                
                <TransitionablePortal open={this.state.open}>
                    <Modal size='tiny' open={this.state.open} >
                        <Modal.Header>Public Apps</Modal.Header>
                        <Modal.Content scrolling>
                            <Input value={this.state.currentAppName} onChange={this.formChange} icon={this.state.iconInput} 
                            placeholder='Type your app url...' />
                            {this.state.isLoadingSave ? <Icon loading name='circle notch' /> : ''}
                            <Divider/>
                            <Label.Group>
                                {
                                this.state.listApp.map((v,k)=><Label key={k}>
                                    {v}
                                    <Icon pname={v} onClick={this.handleRemove} name='delete' color='red'/>
                                </Label>)
                                }   
                            </Label.Group>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={this.close}>Close</Button>
                        </Modal.Actions>
                    </Modal>


                </TransitionablePortal>

                <Modal
                    size={'mini'}
                    open={this.state.openDialogPublish}
                    onClose={this.closePublishDialog}
                >
                    <ModalHeader>{this.props.isArchiveAllHasBeenClicked ? 'Publish': 'You need to Archice All first'}</ModalHeader>
                    <ModalContent>
                    <p>{this.props.isArchiveAllHasBeenClicked ? 'Publish new localization file version?' : 'Seems you never click the Archive All button yet, please Archive All first'}</p>
                    </ModalContent>
                    <ModalActions>
                        {
                            this.props.isArchiveAllHasBeenClicked ? <>
                                <Button negative onClick={this.closePublishDialog}>
                                    Cancel
                                </Button>
                                <Button positive onClick={this.confirmPublish}>
                                    Yes
                                </Button>
                            </>
                            :
                            <Button negative onClick={this.closePublishDialog}>
                                Understand
                            </Button>
                        }
                    </ModalActions>
                </Modal>

                <Modal
                    size={'mini'}
                    open={this.state.showResultPublishingModal.isShow}
                    onClose={this.onCloseResultPublish}
                >
                    <ModalHeader>Publish Project</ModalHeader>
                    <ModalContent>
                    <p>{this.state.showResultPublishingModal.message}</p>
                    </ModalContent>
                    <ModalActions>
                        {
                            this.state.showResultPublishingModal.type === 'success' ? <>
                            <Button positive onClick={this.onCloseResultPublish}>
                                Ok
                            </Button>
                            </>
                            :
                            <Button negative onClick={this.onCloseResultPublish}>
                                Understand
                            </Button>
                        }
                    </ModalActions>
                </Modal>
            </div>


        )
    }

    handleGenerate = () =>{
        this.setState({isLoadingGenerate: true})
        let dataReq = this.state.listApp
        let param = '/'+this.state.currentId
        param = param !== '/' ? param : ''
        superagent.post(process.env.REACT_APP_API_URL+'api/external/save'+param)
        .send({
            app_name: dataReq.join(',')
        })
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            if(res.body.status === 200){
                this.handleRefreshData()
                this.setState({currentAppName:'', iconInput: iconInputError, isLoadingGenerate: false})
            }
        })
    }

    openModal = () => {
        this.setState({ open: true }) 
    } 

    close = () =>{
        this.setState({ open: false }) 
    }

    formChange(e, data){
        let expression = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w-]+)+[\w\-_~:/?#[\]@!&',;=.]+$/
        let regex = new RegExp(expression)
        let t = data.value
        let icon = iconInputError

        if (t.match(regex)) {
            iconInputSuccess.onClick = this.handleSaveExternal
            icon = iconInputSuccess
        }
        this.setState({currentAppName: t, iconInput: icon})
    }

    handleRefreshData(){
        superagent.get(process.env.REACT_APP_API_URL+'api/external/get')
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            if(res.body.status !== undefined){
                localStorage.clear();
                alert('something wrong! You will be logout in 3 seconds')
                setTimeout(()=>{
                    window.location.href='/'
                }, 3000)
            }else{
                if(res.body.app_name !== undefined){
                    let dataApp = res.body.app_name !== '' ? res.body.app_name.split(',') : []
                    this.setState({listApp: dataApp, 
                        currentKey: res.body.key, currentId: res.body.id})
                }
            }
        })
    }

    handleSaveExternal(e, data, is_remove = null){
        let dataReq = this.state.listApp
        if(this.state.currentAppName === '' && is_remove === null){
            alert('app url cannot is required!')
            return false
        }
        if(is_remove === null){
            let idx = dataReq.findIndex(x=>x === this.state.currentAppName)
            if(idx < 0){
                dataReq.push(this.state.currentAppName)
            }
        }

        let param = '/'+this.state.currentId
        param = param !== '/' ? param : ''
        this.setState({isLoadingSave: true})
        superagent.post(process.env.REACT_APP_API_URL+'api/external/save'+param)
        .send({
            app_name: dataReq.join(','),
            key: this.state.currentKey
        })
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            if(res.body.status === 200){
                this.handleRefreshData()
                this.setState({currentAppName:'', iconInput: iconInputError, isLoadingSave: false})
            }
        })
    }

    handleRemove(e, data){
        let dataReq = this.state.listApp
        let idx = dataReq.findIndex(x=>x === data.pname)
        dataReq.splice(idx, 1)
        this.setState({listApp: dataReq})
        this.handleSaveExternal(null, null, true)
    }

    confirmPublish() {
        this.closePublishDialog()
        this.publishProject()
        this.props.setArchiveAllButtonToBeClicked(false)
    }

    closePublishDialog(){
        this.props.setArchiveAllButtonToBeClicked(false)
        this.setState({
            openDialogPublish: false
        })
    }

    openPublishDialog(){
        this.setState({
            openDialogPublish: true
        })
    }

    onOpenResultPublish(isError, message){
        this.setState({
            showResultPublishingModal: {
                type: isError ? 'error':'success',
                isShow: true,
                message: message
            }
        })
    }

    onCloseResultPublish(){
         this.setState({
            showResultPublishingModal: {
                type: 'success',
                isShow: false,
                message: ''
            }
        })
    }

    publishProject(){
        const paths = window.location.pathname.split('/') || []
        if(paths.length !== 0 && paths.length >= 3){
            const projectId = paths[3]
            this.setState({isLoadingSave: true})
            superagent.get(process.env.REACT_APP_API_URL+'api/version/save-project/'+projectId)
            .set('X-API-KEY', this.state.userInfo.api_key)
            .end((err, res) => {
                this.setState({isLoadingSave: false})
                if(res.body.status === 200){
                    this.handleRefreshData()
                    this.onOpenResultPublish(false, "Success publish")
                }else{
                    this.onOpenResultPublish(true, err || "Failed when publishing project")
                }
            })
        }

    }

    isOnProjectDetailPage(){
        const paths = window.location.pathname.split('/') || []
        if(paths.length !== 0 && paths.length >= 3){
            const isProjectDetail = window.location.pathname?.includes(`admin/projects/${paths[3]}`)
            return isProjectDetail
        }
        return false
    }
}