import React from 'react'
import { Button, TransitionablePortal, Modal,
    Form, Dropdown, Header, Card, Image, Container, Dimmer, Loader, Icon, Divider, Popup } from 'semantic-ui-react'
import { getLocalStorage } from '../../helper/local_storage'
const superagent = require('superagent');

export default class FabButton extends React.Component{
    constructor(props){
        super(props)
        let userInfo = getLocalStorage('user');
        let project = getLocalStorage('project');
        this.state = {
            isExportShow: false,
            isArchiveShow: false,
            exportProcessLoading: false,
            userInfo: userInfo.user,
            currentProject:project,
            exportForm:{
                category: '',
                language: '',
                type: '',
                project_id: project.project_id
            },
            archiveForm:{
                category: '',
                language: '',
            },
            fileTypes: [
            {
                key: 'json',
                text: 'JSON',
                value: 'json',
                icon: 'file code outline'
            },
            {
                key: 'xml',
                text: 'XML',
                value: 'xml',
                icon: 'code'
            },
            {
                key: 'excel',
                text: 'Excel',
                value: 'excel',
                icon: 'file excel'
            }
            ],
              categories: [{
                key: 'translation',
                text: 'Translation',
                value: 'translation',
              },{
                key: 'base',
                text: 'Base',
                value: 'base'
              }],
            languages: [],
            archiveLoading: false,
            archiveAllLanguages: [],
            showBtnOk: false
        }
        this.handleOpenButton = this.handleOpenButton.bind(this);
        this.handleChangeExport = this.handleChangeExport.bind(this);
        this.handleChangeArchive = this.handleChangeArchive.bind(this);
        this.handleSaveArchive = this.handleSaveArchive.bind(this)
    }

    componentWillMount(){
        let listLanguages = [];
        superagent.get(process.env.REACT_APP_API_URL+'api/langproject/'+this.state.exportForm.project_id)
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            if(res !== undefined){
                if(res.body.status !== undefined){
                    localStorage.clear();
                    alert('something wrong! You will be logout in 3 seconds')
                    setTimeout(()=>{
                        window.location.href='/'
                    }, 3000)
                }else{
                    if(res.body.length > 0){
                        res.body.forEach(function(val){
                            listLanguages.push(
                                { 
                                    key: val.language_code, 
                                    value: val.language_code, 
                                    flag: val.language_flag, 
                                    text: val.language_name 
                                }
                            )
                        });
                        this.setState({languages: listLanguages});
                    }else{
                        alert('no lang found!');
                    }
                }
            }else{
                localStorage.clear();
                alert('something wrong! You will be logout in 3 seconds')
                setTimeout(()=>{
                    window.location.href='/'
                }, 3000)
            }
        });
    }

    archiveAll = () =>{
        if(window.confirm('Are you sure want to archive all?')){
            this.props.setArchiveAllButtonToBeClicked(true)
            let langs = this.state.languages
            if(langs.findIndex(x=>x.value === 'en') < 0){
                langs.push({
                    key: 'en', 
                    value: 'en', 
                    flag: 'en', 
                    text: 'English' 
                })
            }
    
            let lTemps = []
            langs.forEach(x=>{
                lTemps.push({
                    value: x.value,
                    done: false,
                    loading: false,
                    text: x.text,
                    canceled: false,
                    error: ''
                })
            })
            this.setState({archiveLoading: true, archiveAllLanguages: lTemps})
            setTimeout(()=>{
                let i = 1
                lTemps.forEach(x=>{
                    this.archiveAllProcess(x.value)
                    if(lTemps.length === i){
                        this.setState({showBtnOk: true})
                    }
                    i++
                })
            }, 500)
        }
    }

    archiveAllProcess = async(lang, is_force = null)=>{
        let arcLang = this.state.archiveAllLanguages
        let url = 'api/version/save/translation/'+this.state.currentProject.project_id+'/'+lang
        if(lang === 'en'){
            url = 'api/version/save/base/'+this.state.currentProject.project_id
        }

        if(is_force !== null){
            url = url+'?is_force=1'
        }
        
        let arcIdx = arcLang.findIndex(x=>x.value === lang)
        if(arcIdx !== -1){
            arcLang[arcIdx].loading = true
            this.setState({archiveAllLanguages: arcLang})
        }

        let response = await fetch(process.env.REACT_APP_API_URL+url, {
            headers: {
                'X-API-KEY': this.state.userInfo.api_key
            }
        })
        let data = await response.json()
        arcLang[arcIdx].loading = false
        if(data.status === 200){
            arcLang[arcIdx].done = true
        }else if(data.status === 500){
            arcLang[arcIdx].canceled = true
            arcLang[arcIdx].error = data.message
        }else if(data.status === 411){
            if(window.confirm('There are still translation not yet verified for '+arcLang[arcIdx].text+' language.\n Are you sure want to continue?')){
                this.archiveAllProcess(lang, true)
            }else{
                arcLang[arcIdx].canceled = true
            }
        }
        this.setState({archiveAllLanguages: arcLang})
    }

    render(){
        let project = getLocalStorage('project');
        return(
            <div style={{right: -50, position: 'relative'}}>
                {project.project.is_versioning ? 
                <>
                <Button key='btn_archive_all'
                floated='right' color='teal' onClick={this.archiveAll}
                content='Archive All' icon='archive' act='archive' 
                labelPosition='right' 
                disabled={this.state.languages.length === 0}
                />

                <Button key='btn_archive' disabled={this.state.languages.length === 0}
                floated='right' color='orange' onClick={this.handleOpenButton}
                content='Archive' icon='archive' act='archive'
                labelPosition='right' />
                </>
                : null}
    
                <Button key='btn_export_base' onClick={this.handleOpenButton}
                floated='right' color='blue' act='export'
                content='Export' icon='download' 
                labelPosition='right' />

                <TransitionablePortal open={this.state.isExportShow}>
                    <Modal size='tiny' open closeOnEscape={true} closeOnDimmerClick={false}>
                        <Modal.Header>Export</Modal.Header>
                        <Modal.Content>
                            <Form>
                                <Form.Group widths="equal">
                                    <Form.Field name='category' control={Dropdown} label='Category' 
                                    options={this.state.categories} fluid clearable
                                    search
                                    selection
                                    onChange={this.handleChangeExport}
                                    placeholder='Select Category' />

                                    {this.state.exportForm.category === 'translation' ? <Form.Field name='language' control={Dropdown} label='Language' 
                                    options={this.state.languages} fluid clearable
                                    search
                                    selection
                                    onChange={this.handleChangeExport}
                                    placeholder='Select Language' /> : ''}  

                                    <Form.Field name='filetype' control={Dropdown} label='File Type' 
                                    options={this.state.fileTypes} fluid clearable
                                    search
                                    selection
                                    onChange={this.handleChangeExport}
                                    placeholder='Select File Type' />      
                                </Form.Group>
                            </Form>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button content='Cancel' onClick={this.close} />
                            <Button primary loading={this.state.exportProcessLoading} disabled={this.state.exportProcessLoading}
                                content='Export'
                                onClick={this.handleExportFile}
                            />
                        </Modal.Actions>
                    </Modal>
                </TransitionablePortal>

                <TransitionablePortal open={this.state.isArchiveShow}>
                    <Modal size='tiny' open closeOnEscape={true} closeOnDimmerClick={false}>
                        <Modal.Header>Archive</Modal.Header>
                        <Modal.Content>
                            <Form>
                                <Form.Group widths="equal">
                                    <Form.Field name='category' control={Dropdown} label='Category' 
                                    options={this.state.categories} fluid clearable
                                    search
                                    selection
                                    onChange={this.handleChangeArchive}
                                    placeholder='Select Category' />

                                    {this.state.archiveForm.category === 'translation' ? <Form.Field name='language' control={Dropdown} label='Language' 
                                    options={this.state.languages} fluid clearable
                                    search
                                    selection
                                    onChange={this.handleChangeArchive}
                                    placeholder='Select Language' /> : ''}  
     
                                </Form.Group>
                            </Form>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button content='Cancel' onClick={this.close} />
                            <Button primary loading={this.state.exportProcessLoading} disabled={this.state.exportProcessLoading}
                                content='Archive'
                                onClick={this.handleSaveArchive}
                            />
                        </Modal.Actions>
                    </Modal>
                </TransitionablePortal>

                <Modal size='small' open={this.state.archiveLoading}>
                    <Modal.Header>Archive Processing</Modal.Header>
                    <Modal.Content>
                        <Card.Group itemsPerRow={3}>
                            {this.state.archiveAllLanguages.map((v, k)=>
                            <Card color={v.canceled ? 'red' : v.done ? 'green' : null} key={k}>
                                <Dimmer active={v.loading}>
                                    <Loader active={v.loading}/>
                                </Dimmer>
                                <Card.Content>
                                    <Container textAlign='center'>
                                        <Image src={require('../../img/flag/'+v.value+'.png')} size='tiny' centered circular />
                                    </Container>
                                    <Header as='h3' textAlign='center'>
                                        {v.text} {v.done ? 
                                        <Popup content='The data is archived' trigger={<Icon name='check circle' size='small' color='green'/>} />
                                        : v.canceled ? <Popup content={<>Archive data is canceled!<br/><b>{v.error}</b></>} trigger={<Icon name='remove' size='small' color='red'/>} /> : null}
                                    </Header>
                                </Card.Content>
                            </Card>
                            )}
                        </Card.Group>
                        {this.state.showBtnOk ? 
                        <>
                        <Divider hidden/>
                        <Container textAlign='center'>
                            <Button primary onClick={()=>this.setState({archiveLoading: false, showBtnOk: false})}>Close Progress</Button>
                        </Container>
                        </>
                        : null}
                        
                    </Modal.Content>
                </Modal>
            </div>
        )    
    }

    handleOpenButton(e, data){
        if(data.act === 'export'){
            this.setState({isExportShow:true, isArchiveShow:false});
        }else{
            this.setState({isExportShow:false, isArchiveShow:true});
        }
    }

    handleChangeExport(e, data){
        let cName = data.name
        let eForm = this.state.exportForm;
        let value = data.value;
        switch (cName) {
            case 'category':
                eForm.category = value;
                break;
            case 'language':
                eForm.language = value;
                break;
            default:
                eForm.type = value;
                break;
        }
        this.setState({exportForm: eForm});
    }

    handleChangeArchive(e, data){
        let aForm = this.state.archiveForm;
        let value = data.value;
        switch (data.name) {
            case 'category':
                    aForm.category = value;
                break;
            default:
                    aForm.language = value;
            break;
        }
        this.setState({archiveForm: aForm});
    }

    close = () => {
        if(this.state.isExportShow){
            this.setState({isExportShow:false})
        }
        if(this.state.isArchiveShow){
            this.setState({isArchiveShow:false})
        }
    }

    handleExportFile = () => {
        let eForm = this.state.exportForm;
        eForm.language = eForm.category === 'base' ? 'en' : eForm.language;
        if(eForm.category === '' || eForm.language === '' || eForm.type === ''){
            alert('please complete the form!');
            return false;
        }
        this.setState({exportProcessLoading:true});
        superagent
        .post(process.env.REACT_APP_API_URL+'api/common/export')
        .send(eForm)
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            if(res !== undefined){
                this.setState({exportProcessLoading:false});
                if(res.body.status === 200){
                    let ext = eForm.type === 'excel' ? 'xlsx' : eForm.type; 
                    window.location = process.env.REACT_APP_API_URL+'api/download/exported/'+res.body.data.filename+'/'+ext;
                    this.setState({isExportShow:false});
                    window.setTimeout(function(){
                        window.location.href=''
                    }, 1000)
                }else if(res.body.status >= 400 && res.body.status !== 500){
                    localStorage.clear();
                    alert('something wrong! You will be logout in 3 seconds')
                    setTimeout(()=>{
                        window.location.href='/'
                    }, 3000)
                }else{
                    alert('export failed!');
                }
            }else{
                localStorage.clear();
                alert('something wrong! You will be logout in 3 seconds')
                setTimeout(()=>{
                    window.location.href='/'
                }, 3000)
            }
        });
    }

    handleSaveArchive(e, event, is_force = null){
        let acForm = this.state.archiveForm
        let url = 'api/version/save/translation/'+this.state.currentProject.project_id+'/'+acForm.language
        if(acForm.category === 'base'){
            url = 'api/version/save/base/'+this.state.currentProject.project_id
        }
        if(is_force !== null){
            url = url+'?is_force=1'
        }
        this.setState({exportProcessLoading:true})
        superagent
        .get(process.env.REACT_APP_API_URL+url)
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            this.setState({exportProcessLoading:false})
            if(res.body.status === 200){
                alert('success archive')
                this.setState({isArchiveShow: false, archiveForm: {category:'', language:''}})
                window.location.href=''
            }else if(res.body.status === 500){
                alert('failed archive!\n'+res.body.message)
            }else if(res.body.status === 411){
                if(window.confirm('There are still translation not yet verified.\n Are you sure want to continue?')){
                    this.handleSaveArchive(e, event, true)
                }
            }
        })
    }
}