import React from 'react';
import { Container } from 'semantic-ui-react';
import MainNav from './components/main_nav/main_nav';
import { Redirect } from 'react-router-dom'

import AdminRoute from './routes/main_route'
import { checkAuth } from '../helper/authentication'
import { getLocalStorage } from '../helper/local_storage'
import OneTimeSetting from '../contributor/components/onetime_setting'

class AdminApp extends React.Component{
    render(){
        let isLoggedIn = checkAuth();
        if(isLoggedIn){
            let userInfo = getLocalStorage('user');
            if(userInfo.user.role === 1){
                return(
                    <div>
                {userInfo.user.is_new ? <OneTimeSetting no_lang={true}/> : ''}
                        <MainNav/>
                        <Container style={{paddingTop: 100}}>
                            <AdminRoute/>
                        </Container>
                    </div>
                )
            }else{
                return <Redirect to='/contributor' />
            }
            
        }else{
            return <Redirect to='/' />
        }
    }
}

export default AdminApp
