import { Route } from 'react-router-dom'
import React from 'react'

import LProjects from '../projects/index'
import LUserManage from '../user_manage/index'
import LFAQ from '../faq/index'

export default function AdminRoute(){
    return(
        <div>
            <Route path='/admin/projects' component={LProjects} />
            <Route path='/admin/user' component={LUserManage} />
            <Route path='/admin/faq' exact component={LFAQ} />
        </div>
    )
}