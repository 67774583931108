import React from 'react'
import {  Header, Feed } from 'semantic-ui-react'
import NoDataImage from '../../../components/nodata'

export default class ActivitySection extends React.Component{
    render(){
        let currData = this.props.data
        return(
            <div>
                <Header as='h3' dividing>
                    Recent Activity
                </Header>
                {currData.last_activities.length > 0 ? <Feed>
                    {currData.last_activities.map((v,k)=>{
                        let msg = ''
                        let type = 'added'
                        if(v.before_value !== '' && v.before_value !== null){
                            type = 'modified'
                        }

                        msg = (v.username === this.props.user.username ? 'You' : v.username) +' '+ type +' '+ v.audit_type
                        +'\nbefore:'+v.before_value+' after:'+v.after_value
                        
                        return(
                            <Feed.Event key={k}
                            icon='pencil'
                            date={v.updated_at}
                            summary={msg}
                            />
                        )
                    })}
                </Feed> : <NoDataImage/>}
                
            </div>
        )
    }
}