import React from 'react'
import { Statistic, Flag } from 'semantic-ui-react'

export default class TranslationSection extends React.Component{
    render(){
        let currData = this.props.data.translations
        let data = this.props.data
        let total = currData.base + currData.total
        let byLangs = []
        Object.keys(currData.languages).forEach(function(k){
            byLangs.push({total: currData.languages[k], lang: k})
        })
        return(
            <div>
                <Statistic className='total-statistic'>
                    <Statistic.Value>{total}</Statistic.Value>
                    <Statistic.Label>Total Translation</Statistic.Label>
                </Statistic>
                <Statistic.Group horizontal size='small'>
                    <Statistic key='base'>
                        <Statistic.Value>
                            {currData.base}
                        </Statistic.Value>
                        <Statistic.Label><Flag name='gb'/>English</Statistic.Label>
                    </Statistic>
                {
                    byLangs.map((v, k)=>
                    <Statistic key={k}>
                        <Statistic.Value>
                            {v.total}
                        </Statistic.Value>
                        <Statistic.Label><Flag name={data.languages.list[v.lang].language_flag}/>{data.languages.list[v.lang].language_name}</Statistic.Label>
                    </Statistic>
                    )
                }
                    
                </Statistic.Group>
                
            </div>
        )
    }
}