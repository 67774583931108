import React from 'react'
import { Route } from 'react-router-dom'
import Menus from './menus'
import Translation from './translate'
import Validation from './validate'
import Language from './language'
import OneTimeSetting from './onetime_setting'
import { getLocalStorage } from '../../helper/local_storage'

export default class ContentCon extends React.Component{
    constructor(props){
        super(props)
        this.state = {
        }
        this.handleChangeTab = this.handleChangeTab.bind(this)
    }

    render(){
        let userInfo = getLocalStorage('user')
        return(
            <div>
                {userInfo.user.is_new ? <OneTimeSetting/> : <Language />}
                <Route path='/contributor/:lang' exact component={Menus} />
                <Route path='/contributor/:lang/translate' exact component={Translation} />
                <Route path='/contributor/:lang/validate' exact component={Validation} />
            </div>
        )
    }

    handleChangeTab(e, ev){
        if(ev.activeIndex !== this.state.currentTabIndex){
            this.setState({isTabChanged:true, currentTabIndex: ev.activeIndex})
        }
    }
}
