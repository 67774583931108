import React from 'react'
import { Card, Image, Header } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { getLocalStorage } from '../../helper/local_storage'

export default class Menus extends React.Component{
    render(){
        let userRole = getLocalStorage('user')
        userRole = userRole.user
        return(
            <Card.Group centered>
                {userRole.is_translator ? <Link className="ui card menu-cont" to={this.props.location.pathname+'/translate'}>
                    <div className="content">
                        <div className="description">
                            <Image src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQirxvZ25hKxlTzWZ1Zjxr6tvVeIgQHNrOL40ZUn11oSirqb0UZYQ' size='small' />
                            <Header as='h3'>TRANSLATE</Header>
                        </div>
                    </div>
                </Link> : '' }
                
                {userRole.is_validator ? <Link className="ui card menu-cont" to={this.props.location.pathname+'/validate'}>
                    <div className="content">
                        <div className="description">
                            <Image src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxZtIUZ5DpnLoZ3diFvBtwgF4luiOKxxCtEBtcpt1xbXdbTizf' size='small' />
                            <Header as='h3'>PROOFREAD</Header>
                        </div>
                    </div>
                </Link> : ''}
            </Card.Group>
        )
    }
}