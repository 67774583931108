import React from 'react'
import { Grid, Segment } from 'semantic-ui-react'
import { getLocalStorage } from '../../../helper/local_storage'
import ProjectSection from './sections/project'
import UserSection from './sections/user'
import ExportSection from './sections/export'
import ArcvhiveSection from './sections/archive'
import ActivitySection from './sections/activity'
import PlaceholderComponent from '../../components/placeholder'
const superagent = require('superagent')


export default class Dashboard extends React.Component{
    constructor(props){
        super(props)
        let userInfo = getLocalStorage('user')
        this.state = {
            userInfo: userInfo.user,
            isLoading: true,
            dashboardData: {}
        }
    }

    componentWillMount(){
        superagent.get(process.env.REACT_APP_API_URL+'api/dashboard/get/'+this.props.match.params.project_id)
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            if(res.body.status === 200){
                this.setState({dashboardData: res.body.data, isLoading:false})
            }
        })
    }

    render(){
        let currData = this.state.dashboardData
        return(
            <Grid columns='equal'>
                <Grid.Column>
                    <Segment>
                        {this.state.isLoading ? <PlaceholderComponent type='line'/> : <ProjectSection data={currData}/>}
                    </Segment>
                </Grid.Column>
                <Grid.Column>
                    <Segment style={{maxHeight: 300,
                    overflow: 'auto',
                    height: 300}}>
                        {this.state.isLoading ? <PlaceholderComponent type='line'/> : <UserSection data={currData}/>}
                    </Segment>
                    <Segment style={{maxHeight: 348,
                    overflow: 'auto',
                    height: 348}}>
                        {this.state.isLoading ? <PlaceholderComponent type='line'/> : <ExportSection data={currData}/>}
                    </Segment>
                    <Segment style={{maxHeight: 370,
                    overflow: 'auto',
                    height: 370}}>
                        {this.state.isLoading ? <PlaceholderComponent type='line'/> : <ArcvhiveSection data={currData}/>}
                    </Segment>
                </Grid.Column>
                <Grid.Column width={5}>
                    <Segment style={{maxHeight: 1045,
                    overflow: 'auto',
                    height: 1045}}>
                        {this.state.isLoading ? <PlaceholderComponent type='paragraph'/> : <ActivitySection data={currData} user={this.state.userInfo}/>}
                    </Segment>
                </Grid.Column>
            </Grid>
        )
    }
}