import React from 'react'
import { BreadcrumbRoot } from '../../admin/components/breadcrumb/breadcrumb'
import DataTable from 'react-data-table-component'
import 'react-quill/dist/quill.snow.css'
import { tableSearch } from '../../helper/dataTable'
import { 
    Loader, Button, Icon, Divider,
    Modal, Form, Card, Input, TransitionablePortal
} from 'semantic-ui-react'
import { getLocalStorage } from '../../helper/local_storage'
import { Link } from 'react-router-dom'
import NoDataImage from '../../admin/components/nodata'
const superagent = require('superagent')

export default class UserOwner extends React.Component{
    constructor(props){
        super(props)
        let userInfo = getLocalStorage('user');
        this.state = {
            userInfo: userInfo.user,
            userList: [],
            oldUserList: [],
            isLoading: true,
            open: false,
            saveLoading: false,
            userEmail: '',
            search: ''
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleRefreshData = this.handleRefreshData.bind(this)
        this.handleTableAction = this.handleTableAction.bind(this)
        this.handleSave = this.handleSave.bind(this)
    }

    componentWillMount(){
        this.handleRefreshData()
    }

    render(){
        let columns = [
            {
                name: 'Username',
                selector: 'username',
                sortable: true,
                ignoreRowClick: true,
                allowOverflow: false,
                wrap: true
            },
            {
                name: 'Email',
                selector: 'email',
                sortable: true,
                ignoreRowClick: true,
                allowOverflow: false,
                wrap: true
            },
            {
                name: 'Full Name',
                selector: 'full_name',
                sortable: true,
                ignoreRowClick: true,
                allowOverflow: false,
                wrap: true
            },
            {
                name: 'Created',
                selector: 'created_at',
                sortable: true,
                ignoreRowClick: true,
                allowOverflow: false,
                wrap: true
            },
            {
                name: 'Actions',
                cell: row => <Link className='ui blue small button' to={this.props.location.pathname+'/'+row.username}>Detail</Link>,
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
                wrap: true
            },
        ]
        let actions = [
            <Button key='btn_user_create' onClick={this.openModal} 
            floated='right' color='green' 
            content='Add New User' icon='plus' 
            labelPosition='right' />
        ]
        return(
            <div>
                <BreadcrumbRoot data={'User Manage'}/><Divider /><Divider hidden />
                <Card fluid color='blue'>
                    { this.state.isLoading ? <div>
                        <Loader active/>
                    </div> : ''}
                    <DataTable
                        columns={columns}
                        data={this.state.userList}
                        highlightOnHover
                        defaultSortField="created_at"
                        defaultSortAsc={false}
                        actions={actions}
                        sortIcon={<Icon name='sort amount down'/>}
                        keyfield='username'
                        pagination
                        subHeader
                        subHeaderComponent={
                            <Input onChange={this.tableSearch} size='small'
                            icon='search' placeholder='Search...' />
                        }
                        noDataComponent={<NoDataImage/>}
                    />
                </Card>
                <TransitionablePortal open={this.state.open}>
                    <Modal size='mini' open closeOnEscape={true} closeOnDimmerClick={false}>
                        <Modal.Header>Manage User</Modal.Header>
                        <Modal.Content>
                            <Form>
                                <Form.Input onChange={this.handleChange}
                                name='email' type='email' label='Email' value={this.state.userEmail}
                                placeholder='Type email...' />
                            </Form>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button content='Cancel' onClick={this.close} />
                            <Button primary loading={this.state.saveLoading} disabled={this.state.saveLoading}
                                content='Save'
                                onClick={this.handleSave}
                            />
                        </Modal.Actions>
                    </Modal>
                </TransitionablePortal>
            </div>
        )
    }

    handleChange(e, data){
        this.setState({userEmail: data.value})
    }

    handleRefreshData(){
        this.setState({isLoading:true});
        superagent.get(process.env.REACT_APP_API_URL+'api/user/role?role=1')
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            this.setState({isLoading:false});
            if(res !== undefined){
                if(res.body.status !== undefined){
                    localStorage.clear();
                    alert('something wrong! You will be logout in 3 seconds')
                    setTimeout(()=>{
                        window.location.href='/'
                    }, 3000)
                }else{
                    this.setState({userList: res.body, oldUserList: res.body})
                    if(this.state.search !== ''){
                        this.tableSearch()
                    }
                }
            }else{
                localStorage.clear();
                alert('something wrong! You will be logout in 3 seconds')
                setTimeout(()=>{
                    window.location.href='/'
                }, 3000)
            }
        })
    }

    handleTableAction(){

    }

    handleSave(){
        if(this.state.userEmail === ''){
            alert('please complete the form');
            return false;
        }
        this.setState({saveLoading:true});
        superagent
        .post(process.env.REACT_APP_API_URL+'api/user/save')
        .send({email: this.state.userEmail})
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            this.setState({saveLoading:false});
            if(res !== undefined){
                if(res.body.status === 200){
                    alert('user created!');
                    this.setState({open: false});
                    this.handleRefreshData();
                }else if(res.body.status >= 400 && res.body.status !== 500){
                    localStorage.clear();
                    alert('something wrong! You will be logout in 3 seconds')
                    setTimeout(()=>{
                        window.location.href='/'
                    }, 3000)
                }else{
                    alert('save failed!\n'+res.body.message);
                }
            }else{
                localStorage.clear();
                alert('something wrong! You will be logout in 3 seconds')
                setTimeout(()=>{
                    window.location.href='/'
                }, 3000)
            }
        });
    }

    tableSearch = (event, e) =>{
        let txtSearch = ''
        let stateSearch = this.state.search
        if(e !== undefined){
            if(stateSearch !== e.value){
                txtSearch = e.value
            }else{
                txtSearch = stateSearch
            }
        }else{
            txtSearch = stateSearch
        }
        if(txtSearch === ''){
            this.setState({userList:this.state.oldUserList, search: ''});
        }else{
            this.setState({userList:tableSearch(this.state.oldUserList, txtSearch), search: txtSearch});
        }
    }

    openModal = () => {
        this.setState({open: true, userEmail:''})
    } 
    
    close = () => {
        this.setState({ open: false })
    }
}