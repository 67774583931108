import React from 'react'
import { Button, Dropdown, Modal,
    TransitionablePortal, Form } from 'semantic-ui-react'
import { getLocalStorage, setLocalStorage } from '../../../helper/local_storage'
const superagent = require('superagent')

export default class PasswordSetting extends React.Component{
    constructor(props){
        super(props)
        let userInfo = getLocalStorage('user')
        this.state = {
            userInfo: userInfo.user,
            currentPassword: '',
            isSaveDetail: false
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSaveProfile = this.handleSaveProfile.bind(this)
    }

    render(){
        return(
            <TransitionablePortal open={this.state.isSettingOpen} trigger={
                <Dropdown.Item icon='key' text='Change Password' onClick={this.openSetting}/>}>
                <Modal size='mini' open={this.state.isSettingOpen} closeOnDimmerClick={false}>
                    <Modal.Header>New Password</Modal.Header>
                    <Modal.Content>
                        <Form>
                            <Form.Input onChange={this.handleChange} type='password' name='password' placeholder='Type new password...' />
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.closeSetting}>
                            Cancel
                        </Button>
                        <Button loading={this.state.isSaveDetail}
                            disabled={this.state.isSaveDetail} primary onClick={this.handleSaveProfile}>
                            Save
                        </Button>
                    </Modal.Actions>
                </Modal>
            </TransitionablePortal>
        )
    }

    handleChange(e, data){
        this.setState({currentPassword: data.value})
    }

    openSetting = () =>{
        this.setState({isSettingOpen:true})
    }

    closeSetting = () =>{this.setState({isSettingOpen:false})}

    handleSaveProfile(){
        this.setState({isSaveDetail: true})
        superagent
        .post(process.env.REACT_APP_API_URL+'api/update/user/detail')
        .send({password: this.state.currentPassword})
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            this.setState({isSaveDetail: false})
            if(res.body.status === 200){
                alert('profile updated')
                let user = res.body.data
                setLocalStorage('user', {
                    isLoggedIn: true,
                    user: user
                })
                let lg = user.preferred_language.split(',')
                lg.sort()
                window.location.href="/contributor/"+lg[0]
            }else{
                alert('save failed!')
            }
        })
    }
}
