import React from 'react'
import { Grid } from 'semantic-ui-react'
import SideNavCon from './components/sidenav'
import ContentCon from './components/content'
import { checkAuth } from '../helper/authentication'
import { getLocalStorage } from '../helper/local_storage'
import { Redirect } from 'react-router-dom'

export default class ContributorApp extends React.Component{
    render(){
        let isLoggedIn = checkAuth();
        if(isLoggedIn){
            let userInfo = getLocalStorage('user');
            if(userInfo.user.role === 2){
                return(
                    <Grid columns='equal'>
                        <Grid.Row stretched>
                            <SideNavCon/>
                            <Grid.Column id="contentColumn">
                                <ContentCon/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )
            }else if(userInfo.user.role === 1){
                return <Redirect to='/admin/projects' />
            }else{
                return <Redirect to='/owner/dashboard' />
            }
        }else{
            return <Redirect to='/' />
        }

    }
}
