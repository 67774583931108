import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

export default class ValidationSection extends React.Component{
    render(){
        let userDetail = this.props.user
        let langs = []
        let totAcc = []
        let totRej = []
        let totAssign = []
        userDetail.stats.forEach(function(val){
            langs.push(val.lang.language_name)
            totAcc.push(val.total_acception)
            totRej.push(val.total_rejection)
            totAssign.push(val.total_validation_assignment)
        })
        const options = {
            title: {
              text: 'Validation Activity Chart'
            },
            xAxis: {
                categories: langs,
            },
            credits: {
                enabled: false
            },
            series: [
                { name:'Acception', data: totAcc, type: 'column' },
                { name:'Rejection', data: totRej, type: 'column' },
                { name:'Assignment', data: totAssign, type: 'column' }
            ]
        }
        return(
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        )
    }
}