import React from 'react'
import { getLocalStorage } from '../../../helper/local_storage'
import { 
    Loader, Button, Icon, Dropdown,
    Modal, Form, Card, Input, TransitionablePortal
} from 'semantic-ui-react'
import DataTable from 'react-data-table-component'
import { tableSearch } from '../../../helper/dataTable'
import './manage_base.scss';
import NoDataImage from '../../components/nodata'

const superagent = require('superagent')

class ManageBase extends React.Component{
    constructor(props){
        super(props)
        let userInfo = getLocalStorage('user');
        this.state = {
            userInfo: userInfo.user,
            isLoading: true,
            saveBaseLoading: false,
            baseList: [],
            oldBaseList: [],
            open: false,
            baseForm:{
                project_id:props.match.params.project_id,
                base_id: 0,
                base_key:'',
                base_value:'',
                base_description:'',
                is_variable: false
            },
            textVarible: {},
            baseDetail:'',
            currentBaseData: null,
            isUpdate: false,
            search: ''
        }
        this.handleTableAction = this.handleTableAction.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSaveBase = this.handleSaveBase.bind(this);
        this.handleRefreshBase = this.handleRefreshBase.bind(this);
    }

    componentWillMount(){
        this.handleRefreshBase();
    }

    render(){
        let columns = [
            {
                name: 'Key',
                selector: 'base_key',
                sortable: true,
                ignoreRowClick: true,
                allowOverflow: true,
                wrap: true
            },
            {
                name: 'Value',
                selector: 'base_value',
                sortable: true,
                ignoreRowClick: true,
                allowOverflow: false,
                wrap: true
            },
            {
                name: 'Context',
                selector: 'base_description',
                sortable: true,
                ignoreRowClick: true,
                allowOverflow: false,
                wrap: true
            },
            {
                name: 'Is Variable',
                selector: 'is_variable',
                sortable: true,
                ignoreRowClick: true,
                allowOverflow: false,
                wrap: true,
                cell: row=> {
                    let comp = row.is_variable ? <Icon name='check' color='green' /> : 
                    <Icon name='remove' color='red' />
                    return comp
                }
            },
            {
                name: 'Actions',
                cell: row => {
                    let options = [
                        { key: 0, color: 'blue', text: 'Edit', value: 'edit' },
                        { key: 1, color: 'red', text: 'Remove', value: 'delete' },
                    ];
                    return (
                        <div>
                            {
                                options.map((val, k) =><Button key={k} size='small' color={val.color} b_id={row.base_id} action={val.key} onClick={this.handleTableAction}>{val.text}</Button>)
                            }
                        </div>
                    )
                },
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
                wrap: true
            },
        ]

        let actions = [
            <Dropdown key='btn_template_base' text='Base Template' icon='file' floating labeled button className='icon'>
                <Dropdown.Menu>
                <Dropdown.Item onMouseDown={this.downloadTemplate.bind(this, 'xml')} icon='file code outline' text='XML' />
                <Dropdown.Item onMouseDown={this.downloadTemplate.bind(this, 'xmlnocontext')} icon='file code outline' text='No Context XML' />
                <Dropdown.Item onMouseDown={this.downloadTemplate.bind(this, 'excel')} icon='file excel outline' text='EXCEL' />
                </Dropdown.Menu>
            </Dropdown>
            ,

            <div key='btn_import_base' className="ui action input">
            <Form>
            <Form.Input action='Import' type='file' onChange={this.handleChange}
            name='file' /></Form>
            </div>
            ,
            <Button key='btn_base_create' onClick={this.openModal} 
            floated='right' color='green' 
            content='Add New Base' icon='plus' 
            labelPosition='right' />
        ]

        return(
            <div>
                <Card fluid color='blue'>
                    { this.state.isLoading ? <div>
                        <Loader active/>
                    </div> : ''}
                    <DataTable
                        title="Manage Base"
                        columns={columns}
                        data={this.state.baseList}
                        highlightOnHover
                        defaultSortField="base_id"
                        defaultSortAsc={false}
                        actions={actions}
                        sortIcon={<Icon name='sort amount down'/>}
                        keyfield='base_id'
                        pagination
                        subHeader
                        subHeaderComponent={
                            <Input onChange={this.tableSearch} size='small'
                            icon='search' placeholder='Search...' />
                        }
                        noDataComponent={<NoDataImage/>}
                    />
                </Card>
                <TransitionablePortal open={this.state.open}>
                    <Modal size='tiny' open closeOnEscape={true} closeOnDimmerClick={false}>
                        <Modal.Header>Manage Base</Modal.Header>
                        <Modal.Content scrolling>
                            <Form>
                                <Form.Input onChange={this.handleChange} readOnly={this.state.isUpdate}
                                name='base_key' label='Base Key' value={this.state.baseForm.base_key} 
                                placeholder='Type base key...' />
                                <Form.TextArea onChange={this.handleChange} value={this.state.baseForm.base_value}
                                name='base_value' label='Base Value' 
                                placeholder='Type @ to start your variable' />
                                <Form.TextArea onChange={this.handleChange} value={this.state.baseForm.base_description}
                                name='base_description' label='Base Context' 
                                placeholder='Type base context...' />
                            </Form>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button content='Cancel' onClick={this.close} />
                            <Button primary loading={this.state.saveBaseLoading} disabled={this.state.saveBaseLoading}
                                content='Save'
                                onClick={this.handleSaveBase}
                            />
                        </Modal.Actions>
                    </Modal>
                </TransitionablePortal>
        </div>
        )
    }

    openModal = () => {
        let bForm = this.state.baseForm;
        bForm.base_key = '';
        bForm.base_value = '';
        this.setState({ open: true, baseForm: bForm, currentBaseData:null, isUpdate: false }) 
    } 
    
    close = () => {
        this.setState({ open: false })
    } 

    tableSearch = (event, e) =>{
        let txtSearch = ''
        let stateSearch = this.state.search
        if(e !== undefined){
            if(stateSearch !== e.value){
                txtSearch = e.value
            }else{
                txtSearch = stateSearch
            }
        }else{
            txtSearch = stateSearch
        }
        if(txtSearch === ''){
            this.setState({baseList:this.state.oldBaseList, search: ''});
        }else{
            this.setState({baseList:tableSearch(this.state.oldBaseList, txtSearch), search: txtSearch});
        }
    }

    handleTableAction(event, e){
        let data = this.state.baseList;
        let result = data.filter(base => base.base_id === e.b_id);
        result = result[0];
        this.setState({currentBaseData: result});
        switch (e.action) {
            case 0:
                let bForm = this.state.baseForm;
                bForm.base_id = e.b_id;
                bForm.base_key = result.base_key;
                bForm.base_value = result.base_value;
                bForm.base_description = result.base_description;
                this.setState({baseForm: bForm, open: true, isUpdate: true});
                break;
            case 1:
                if(window.confirm('are you sure want to delete?')){
                    this.setState({isLoading: true});
                    superagent
                    .delete(process.env.REACT_APP_API_URL+'api/base/'+e.b_id)
                    .set('X-API-KEY', this.state.userInfo.api_key)
                    .end((err, res) => {
                        if(res.body.status === 200){
                            this.setState({isLoading:false});
                            alert('base deleted!');
                            this.handleRefreshBase();
                        }else if(res.body.status >= 400 && res.body.status !== 500){
                            localStorage.clear();
                            alert('something wrong! You will be logout in 3 seconds')
                            setTimeout(()=>{
                                window.location.href='/'
                            }, 3000)
                        }else{
                            alert('delete failed!');
                        }
                    });
                }
                break;
            default:
                break;
        }
    }

    handleChange(event, data){
        let cName = data.name
        let bForm = this.state.baseForm;
        let value = data.value;
        switch (cName) {
            case 'base_key':
                bForm.base_key = value;
                break;
            case 'base_value':
                bForm.base_value = value;
                break;
            case 'base_description':
                bForm.base_description = value;
                break;
            default:
                let file = event.target.files[0];
                if(file === null){
                    alert('file not found, please select other file.');
                    return false;
                }
                let fileType = file.name.split('.');
                fileType = fileType[fileType.length - 1];
                if(fileType !== 'xlsx' && fileType !== 'xml'){
                    alert('file format is not supported.');
                    return false;
                }
                let autoTrans = 0;
                if(window.confirm('do you want to translate imported file automatically?')){
                    autoTrans = 1;
                }
                this.setState({isLoading:true});
                let dataRequest = new FormData();
                let projectInfo = getLocalStorage('project');
                dataRequest.append('file', event.target.files[0]);
                dataRequest.append('project_id', projectInfo.project_id);
                dataRequest.append('is_translate', autoTrans);
                superagent
                .post(process.env.REACT_APP_API_URL+'api/import/base')
                .send(dataRequest)
                .set('X-API-KEY', this.state.userInfo.api_key)
                .end((err, res) => {
                    this.setState({isLoading:false});
                    if(res !== undefined){
                        if(res.body.status === 200){
                            alert('base imported!');
                            this.handleRefreshBase();
                        }else if(res.body.status >= 400 && res.body.status !== 500){
                            localStorage.clear();
                            alert('something wrong! You will be logout in 3 seconds')
                            setTimeout(()=>{
                                window.location.href='/'
                            }, 3000)
                        }else{
                            alert(res.body.message);
                        }
                    }else{
                        localStorage.clear();
                        alert('something wrong! You will be logout in 3 seconds')
                        setTimeout(()=>{
                            window.location.href='/'
                        }, 3000)
                    }
                });
                break;
        }
        this.setState({
            baseForm: bForm
        });
    }

    handleSaveBase(){
        let bForm = this.state.baseForm;
        if(bForm.base_key === '' || bForm.base_value === ''){
            alert('please complete form.');
            return false;
        }

        let url = bForm.base_id === 0 ? 'api/base' : 'api/base/'+bForm.base_id;
        if(bForm.base_value.includes('@')){
            bForm.is_variable = true;
        }
        this.setState({saveBaseLoading:true});
        superagent
            .post(process.env.REACT_APP_API_URL+url)
            .send(bForm)
            .set('X-API-KEY', this.state.userInfo.api_key)
            .end((err, res) => {
                if(res.body !== null && res.body.status === 200){
                    this.setState({saveBaseLoading:false});
                    alert('base saved!');
                    this.setState({open: false});
                    this.handleRefreshBase();
                    let url = 'api/translate/base/'+res.body.base_id;
                    if(window.confirm('do you want to translate this key with google autotranslate?\n this will affect all current project languages.')){
                        url = 'api/translate/base/'+res.body.base_id+'/true';
                    }
                    
                    this.setState({isLoading:true});
                    superagent.get(process.env.REACT_APP_API_URL+url)
                    .set('X-API-KEY', this.state.userInfo.api_key)
                    .end((err, res) => {
                        this.setState({isLoading:false});
                    });
                }else if(res.body.status >= 400 && res.body.status !== 500){
                    localStorage.clear();
                    alert('something wrong! You will be logout in 3 seconds')
                    setTimeout(()=>{
                        window.location.href='/'
                    }, 3000)
                }else{
                    alert('save failed!');
                }
            });
    }

    handleRefreshBase(){
        this.setState({isLoading:true});
        superagent.get(process.env.REACT_APP_API_URL+'api/base/byproject/'+this.props.match.params.project_id)
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            this.setState({isLoading:false});
            if(res !== undefined){
                if(res.body.status !== undefined){
                    localStorage.clear();
                    alert('something wrong! You will be logout in 3 seconds')
                    setTimeout(()=>{
                        window.location.href='/'
                    }, 3000)
                }else{
                    if(res.body.length > 0){
                        let rows = [];
                        res.body.forEach(function(val, k){
                            rows.push(
                                { 
                                    base_id: val.base_id, 
                                    base_key: val.base_key, 
                                    base_value: val.base_value,
                                    is_variable: val.is_variable,
                                    base_description: val.base_description
                                }
                            )
                        });
                        
                        this.setState({baseList:rows, oldBaseList:rows})
                        if(this.state.search !== ''){
                            this.tableSearch()
                        }
                    }else{
                        this.setState({baseList:[], oldBaseList:[]})
                    }
                }
            }else{
                localStorage.clear();
                alert('something wrong! You will be logout in 3 seconds')
                setTimeout(()=>{
                    window.location.href='/'
                }, 3000)
            }
        });
    }

    downloadTemplate = (type, e) =>{
        window.location = process.env.REACT_APP_API_URL+'api/download/template/base/'+type;
    }
}

export default ManageBase