import React from 'react'
import { Grid, Segment, Header } from 'semantic-ui-react'
import { getLocalStorage } from '../../helper/local_storage'
import PlaceholderComponent from '../components/placeholder'
import NoDataImage from '../components/nodata'
import ProfileSection from './sections/profile'
import TranslationSection from './sections/translation'
import ValidationSection from './sections/validation'
import ActivitySection from './sections/activity';
import './user_manage.scss'
const superagent = require('superagent');

export default class UserDetail extends React.Component{
    constructor(props){
        super(props)
        let userInfo = getLocalStorage('user');
        this.state = {
            userInfo: userInfo.user,
            userDetail: {},
            isLoadProfile: true,

        }
    }

    componentWillMount(){
        superagent.get(process.env.REACT_APP_API_URL+'api/user/detail/'+ this.props.match.params.username)
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            if(res !== undefined){
                if(res.body.status === 200){
                    this.setState({userDetail: res.body.data, isLoadProfile:false});
                }else if(res.body.status >= 400 && res.body.status !== 500){
                    localStorage.clear();
                    alert('something wrong! You will be logout in 3 seconds')
                    setTimeout(()=>{
                        window.location.href='/'
                    }, 3000)
                }else{
                    alert('no data found!');
                }
            }else{
                localStorage.clear();
                alert('something wrong! You will be logout in 3 seconds')
                setTimeout(()=>{
                    window.location.href='/'
                }, 3000)
            }
        });
    }

    render(){
        return(
            <Grid columns='equal'>
                <Grid.Column>
                    <Segment>
                    {this.state.isLoadProfile ? <PlaceholderComponent type='profile'/> :
                    <ProfileSection user={this.state.userDetail}/>}
                    </Segment>
                </Grid.Column>
                <Grid.Column>
                    <Segment style={{height:589.438}}>
                        <Header as='h2'>Translation</Header>
                        {
                            this.state.isLoadProfile ? <PlaceholderComponent type='line'/> :
                            this.state.userDetail.stats.length > 0 ? <TranslationSection user={this.state.userDetail}/> : <NoDataImage/>
                        }
                    </Segment>
                </Grid.Column>
                <Grid.Column>
                    <Segment style={{height:291.688, overflow:'auto'}}>
                        <Header as='h2'>Validation</Header>
                        {
                            this.state.isLoadProfile ? <PlaceholderComponent type='line'/> :
                            this.state.userDetail.stats.length > 0 ? <ValidationSection user={this.state.userDetail}/> : <NoDataImage size='small'/>
                        }
                    </Segment>
                    <Segment style={{height:282.6875, maxHeight:282.6875, overflow:'auto'}}>
                        <Header as='h2'>Last Activity</Header>
                        {
                            this.state.isLoadProfile ? <PlaceholderComponent type='paragraph'/> :
                            <ActivitySection user={this.state.userDetail}/>
                        }
                    </Segment>
                </Grid.Column>
            </Grid>
        )
    }


}
