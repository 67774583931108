import React from 'react'
import { Button, Form, Grid, Segment, Divider } from 'semantic-ui-react'
import { setLocalStorage, getLocalStorage } from '../helper/local_storage'
import { checkAuth } from '../helper/authentication'
import { Redirect } from 'react-router-dom'
const superagent = require('superagent')

class LLoginForm extends React.Component{
    constructor(props) {
        super(props);
        this.state = {username:'',password:'', is_force: false, isLoading:false};
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    render() {
        let isLoggedIn = checkAuth();
        if(isLoggedIn){
            let userInfo = getLocalStorage('user');
            if(userInfo.user.role === 1){
                return <Redirect to='/admin/projects' />
            }else if(userInfo.user.role === 2){
                return <Redirect to='/contributor' />
            }else{
                return <Redirect to='/owner/dashboard' />
            }
        }else{
            return (
                <div className='login-form'>
                    <style>{`
                    body > div,
                    body > div > div,
                    body > div > div > div.login-form {
                        height: 100%;
                    }
                    `}
                    </style>
                    <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
                    <Grid.Column style={{ maxWidth: 425 }}>
                        <Form size='large'>
                        <Segment>
                            <img alt='logo' width="250px" src={require('../img/logo.png')} />
                            <Divider className="hidden" />
                            <Form.Input fluid icon='user' onChange={this.handleChange} autoComplete='username'
                            name='username' iconPosition='left' placeholder='Username or Email' required/>
                            <Form.Input name='password' onChange={this.handleChange}
                            fluid icon='lock' iconPosition='left' placeholder='Password' type='password' required/>
                            <Divider className="hidden" />
                            <Button primary fluid onClick={this.handleSubmit}
                            disabled={this.state.isLoading} loading={this.state.isLoading}>
                                <i className="sign in icon"></i>
                                Login
                            </Button>
                            <br/>
                            <a href='/forgot'>forgot password?</a>
                        </Segment>
                        </Form>
                    </Grid.Column>
                    </Grid>
                </div>
            );
        }
    }

    handleChange(event) {
        let target = event.target;
        if(target.name === 'username'){
            this.setState({username: target.value});
        }else{
            this.setState({password: target.value});
        }
    }

    handleSubmit() {
        let form = this.state;
        if(form.username === '' || form.password === ''){
            alert('complete the form!');
            return false;
        }else{
            this.setState({isLoading:true})
            superagent
            .post(process.env.REACT_APP_API_URL+'api/user/login')
            .send(form)
            .end((err, res) => {
                this.setState({isLoading:false})
                if(res !== undefined){
                    let response = res.body;
                    if(response !== null && response.status === 200){
                        if(res.body.data.role === 2){
                            if(res.body.data.preferred_language !== null){
                                let lg = res.body.data.preferred_language.split(',')
                                lg.sort()
                                window.location.href="/contributor/"+lg[0]
                            }else{
                                window.location.href="/contributor"
                            }
                        }else if(res.body.data.role === 0){
                            window.location.href="/owner/dashboard"
                        }else{
                            window.location.href="/admin/projects";
                        }
                        setLocalStorage('user', {
                            isLoggedIn: true,
                            user: res.body.data
                        });
                    }else if(response.status === 226){
                        if(window.confirm('your account is being used in other device, click ok to login using this device and other device will be logged out.')){
                            this.setState({ is_force:true });
                            this.handleSubmit();
                        }
                    }else{
                        alert('cannot login!');
                    }
                }else{
                    alert('cannot login!');
                }
            });
        }
    }
}

export default LLoginForm
