import React from 'react'
import {
    Button, Dropdown, Dimmer, Icon,
    Modal, TransitionablePortal,
    Form, Card, Divider, Transition, Image,
    Grid, Header, Label, Progress
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import './welcome_admin.scss'
import PlaceholderComponent from '../../components/placeholder'

import { getLocalStorage, setLocalStorage } from '../../../helper/local_storage'

const superagent = require('superagent');
const Thumbnails = [
    { value: 0, active: false },
    { value: 1, active: false },
    { value: 2, active: false },
    { value: 3, active: false },
    { value: 4, active: false },
]

export default class WelcomeAdmin extends React.Component{
    constructor(props) {
        super(props);
        let userInfo = getLocalStorage('user');
        this.state = {
            listProjects: [],
            userInfo: userInfo.user,
            isLoading: true,
            open: false,
            languages: [],
            thumbnails: Thumbnails,
            saveProjectLoading: false,
            projectForm:{
                project_name: '',
                project_description: '',
                project_note: '',
                project_language: '',
                is_versioning: false,
                project_thumbnail: '',
            },
            projectId: '',
            currentSelectedLangText:'',
            currentSelectedLang:''
        }
        this.handleRefreshData = this.handleRefreshData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSaveProject = this.handleSaveProject.bind(this);
        this.handleOpenSaveProjectForm = this.handleOpenSaveProjectForm.bind(this);
        this.thumbnailSelect = this.thumbnailSelect.bind(this);
        this.handleProjectActions = this.handleProjectActions.bind(this);
        this.handleSetProjectStorage = this.handleSetProjectStorage.bind(this);
    }

    componentWillMount(){
        this.loadLanguage()
    }

    componentDidMount(){
        this.handleRefreshData();
    }

    render(){
        return (
        <div>
            <Grid id={'projectFolder'} columns={4} stackable>
                { this.state.isLoading ? <Grid.Column className='center aligned'>
                    <PlaceholderComponent type='profile'/>
                </Grid.Column> : 
                this.state.listProjects.map((val, i) =>{
                    return (
                        <Transition key={i} transitionOnMount={true} animation='scale' duration={1000}>
                            <Grid.Column className="center aligned">
                              <Link onClick={()=>this.handleSetProjectStorage(val)} to={this.props.param.match.path + '/' + val.project_id+'/dashboard'}>
                                <Image src={require('../../../img/projects/'+val.project.project_thumbnail+'.png')} fluid />
                                <Progress indicating percent={val.progress} size='small' progress='percent'/>
                                <Header as='h3'>{val.project.project_name}</Header>
                              </Link>
                              <button
                                  className="ui small primary button"
                                  onClick={()=>this.handleProjectActions(this, val.project.project_id, 'edit')}>
                                  <Icon name='pencil' />
                                  Edit
                              </button>
                              <button
                                  className="ui small negative icon button"
                                  onClick={()=>this.handleProjectActions(this, val.project.project_id, 'delete')}>
                                  <Icon name='trash' />
                              </button>
                              <Label size={'mini'}>
                                {val.project_id}
                              </Label>
                            </Grid.Column>
                        </Transition>
                    )
                })
                }
            <Grid.Column className='center aligned'>
              <Image id='addProject' src={require('../../../img/add-project.png')} centered onClick={this.handleOpenSaveProjectForm} />
              <Header as='h3'>Add New Project</Header>
            </Grid.Column>
            </Grid>
            <Divider hidden />
            <TransitionablePortal open={this.state.open}>
                <Modal open closeOnEscape={true}closeOnDimmerClick={false}>
                    <Modal.Header>Project</Modal.Header>
                    <Modal.Content scrolling>
                        <Form>
                            <Form.Group widths="equal">
                                <Form.Input onChange={this.handleChange} name='project_name'
                                value={this.state.projectForm.project_name}
                                label='Project Name' placeholder='Type project name...' />
                                <Form.Field name='project_language' control={Dropdown} label={
                                    <label>Languages {this.state.currentSelectedLangText}</label>
                                }
                                options={this.state.languages} fluid clearable
                                search
                                multiple
                                selection
                                value={this.state.projectForm.project_language}
                                onChange={this.handleChange}
                                placeholder='Select Language' />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <Form.TextArea onChange={this.handleChange}
                                name='project_description'
                                label='Project Description'
                                value={this.state.projectForm.project_description}
                                placeholder='Type project description...' />
                                <Form.TextArea onChange={this.handleChange}
                                value={this.state.projectForm.project_note}
                                name='project_note'
                                label='Project Note'
                                placeholder='Type project note...' />
                            </Form.Group>
                            <Form.Checkbox name='is_versioning' label='Is Versioning?'
                            checked={this.state.projectForm.is_versioning ? true : false}
                            onChange={this.handleChange} />
                            <p><b>Project Thumbnail</b></p>
                            <Card.Group itemsPerRow={5}>
                                {
                                    this.state.thumbnails.map((val, i) =>{
                                        return (
                                            <Card key={i} href='' onClick={this.thumbnailSelect.bind(this, i)} raised>
                                                <Dimmer.Dimmable as={Card}>
                                                    <div className='image'><img alt={i} src={require('../../../img/projects/'+val.value+'.png')} /></div>
                                                    <Dimmer active={val.active}>
                                                        <Icon name='check' color='green' />
                                                    </Dimmer>
                                                </Dimmer.Dimmable>
                                            </Card>
                                        )
                                    })
                                }
                            </Card.Group>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            content='Cancel'
                            onClick={this.close}
                        />
                        <Button primary loading={this.state.saveProjectLoading} disabled={this.state.saveProjectLoading}
                        content='Save'
                        onClick={this.handleSaveProject}
                        />
                    </Modal.Actions>
                </Modal>
            </TransitionablePortal>
        </div>
        )
    }

    loadLanguage = () =>{
        this.setState({currentSelectedLangText:''})
        let listLanguages = [];
        superagent.get(process.env.REACT_APP_API_URL+'api/languages')
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            if(res !== undefined){
                if(res.body.status !== undefined){
                    localStorage.clear();
                    alert('something wrong! You will be logout in 3 seconds')
                    setTimeout(()=>{
                        window.location.href='/'
                    }, 3000)
                }else{
                    if(res.body.length > 0){
                        Array.from(res.body).forEach(val=>{
                            listLanguages.push(
                                {
                                    key: val.language_code,
                                    value: val.language_code,
                                    flag: val.language_flag,
                                    text: val.language_name
                                }
                            )
                        })

                        this.setState({languages: listLanguages});
                    }else{
                        alert('no lang found!');
                    }
                }
            }else{
                localStorage.clear();
                alert('something wrong! You will be logout in 3 seconds')
                setTimeout(()=>{
                    window.location.href='/'
                }, 3000)
            }
        })
    }

    handleRefreshData(){
        (async () => {
            try {
                const res = await superagent.get(process.env.REACT_APP_API_URL+'api/project/username')
                .set('X-API-KEY', this.state.userInfo.api_key);
                this.setState({isLoading: false});
                if(res !== undefined){
                    if(res.body.status !== undefined){
                        localStorage.clear();
                        alert('something wrong! You will be logout in 3 seconds')
                        setTimeout(()=>{
                            window.location.href='/'
                        }, 3000)
                    }else{
                        let projects = res.body;
                        let isOpen = this.state.userInfo.is_new ? false : res.body.length > 0 ? false:true
                        this.setState({ listProjects: projects, open: isOpen });
                    }
                }else{
                    localStorage.clear();
                    alert('something wrong! You will be logout in 3 seconds')
                    setTimeout(()=>{
                        window.location.href='/'
                    }, 3000)
                }
            } catch (err) {
            }
        })();
    }

    close = () => this.setState({ open: false })

    thumbnailSelect(key, e){
        e.preventDefault();
        let thumbs = this.state.thumbnails;
        let pForm = this.state.projectForm;
        thumbs[key].active = true;
        pForm.project_thumbnail = key;
        thumbs.forEach(function(v, k){
            if(k !== key){
                thumbs[k].active = false;
            }
        });
        this.setState({
            thumbnails:thumbs,
            projectForm:pForm
        });
    }

    handleChange(event, data){
        let cName = data.name
        let pForm = this.state.projectForm;
        switch (cName) {
            case 'project_name':
                pForm.project_name = data.value;
                this.setState({
                    projectForm: pForm
                });
                break;
            case 'project_language':
                pForm.project_language = data.value;
                this.setState({
                    projectForm: pForm
                });
                break;
            case 'is_versioning':
                pForm.is_versioning = data.checked;
                this.setState({
                    projectForm: pForm
                });
                break;
            case 'project_description':
                pForm.project_description = data.value;
                this.setState({
                    projectForm: pForm
                });
                break;
            default:
                pForm.project_note = data.value;
                this.setState({
                    projectForm: pForm
                });
                break;
        }
    }

    handleSaveProject(){
        let pForm = this.state.projectForm;
        
        if(this.state.projectId !== ''){
            if(pForm.project_language !== ''){
                pForm.project_language = pForm.project_language+','+this.state.currentSelectedLang
            }else{
                pForm.project_language = this.state.currentSelectedLang
            }
        }else{
            pForm.project_language = pForm.project_language.join()
        }

        if(pForm.project_name === '' || pForm.project_language === ''){
            alert('please complete form.');
            return false;
        }

        this.setState({saveProjectLoading:true});
        let url = this.state.projectId !== '' ? 'api/project/save/'+this.state.projectId : 'api/project/save';
        superagent
            .post(process.env.REACT_APP_API_URL+url)
            .send(pForm)
            .set('X-API-KEY', this.state.userInfo.api_key)
            .end((err, res) => {
                if(res.body.status === 200){
                    this.setState({saveProjectLoading:false});
                    alert('project saved!');
                    this.setState({open: false});
                    let userData = this.state.userInfo;
                    setLocalStorage('user', {
                        isLoggedIn: true,
                        user: userData
                    });
                    this.handleRefreshData();
                }else if(res.body.status >= 400 && res.body.status !== 500){
                    localStorage.clear();
                    alert('something wrong! You will be logout in 3 seconds')
                    setTimeout(()=>{
                        window.location.href='/'
                    }, 3000)
                }else{
                    alert('save failed!');
                }
            });
    }

    handleOpenSaveProjectForm(){
        this.loadLanguage()
        let pForm = {
            project_name: '',
            project_description: '',
            project_note: '',
            project_language: [],
            is_versioning: false,
            project_thumbnail: '',
        }
        let th = this.state.thumbnails;
        for (let index = 0; index < th.length; index++) {
            if(th[index].active){
                th[index].active = false;
            }
        }
        this.setState({open: true, projectForm: pForm, thumbnails: th, projectId:''});
    }

    handleProjectActions(e, pid, type){
        if(type === 'edit'){
            this.loadLanguage()
            this.setState({isLoading:true});
            superagent
            .get(process.env.REACT_APP_API_URL+'api/project/'+pid)
            .set('X-API-KEY', this.state.userInfo.api_key)
            .end((err, res) => {
                this.setState({isLoading:false});
                if(res.body.status !== undefined){
                    localStorage.clear();
                    alert('something wrong! You will be logout in 3 seconds')
                    setTimeout(()=>{
                        window.location.href='/'
                    }, 3000)
                }else{
                    let pForm = {
                        project_name: res.body.project_name,
                        project_description: res.body.project_description,
                        project_note: res.body.project_note,
                        project_language: '',
                        is_versioning: res.body.is_versioning,
                        project_thumbnail: res.body.project_thumbnail,
                    }
                    
                    let th = this.state.thumbnails;
                    for (let index = 0; index < th.length; index++) {
                        if(th[index].value === res.body.project_thumbnail){
                            th[index].active = true;
                        }else{
                            th[index].active = false;
                        }
                    }
                    let pLang = res.body.project_language.split(',')
                    let langList = this.state.languages
                    let sLangs = []
                    pLang.forEach(function(val){
                        let lIdx = langList.findIndex(x=>x.value === val)
                        if(lIdx >= 0){
                            sLangs.push(langList[lIdx].text) 
                            langList.splice(lIdx, 1)
                        }
                    })
                    this.setState({projectForm: pForm, open: true, thumbnails: th, projectId: res.body.project_id, 
                        languages: langList, currentSelectedLangText: <div>({sLangs.join(',')})</div>, 
                        currentSelectedLang: res.body.project_language})
                }
            });
        }else{
            if(window.confirm('are you sure want to delete project?')){
                this.setState({isLoading:true});
                superagent
                .delete(process.env.REACT_APP_API_URL+'api/project/remove/'+pid)
                .set('X-API-KEY', this.state.userInfo.api_key)
                .end((err, res) => {
                    this.setState({isLoading:false});
                    if(res.body.status === 200){
                        alert('project deleted');
                        this.handleRefreshData();
                    }else if(res.body.status >= 400 && res.body.status !== 500){
                        localStorage.clear();
                        alert('something wrong! You will be logout in 3 seconds')
                        setTimeout(()=>{
                            window.location.href='/'
                        }, 3000)
                    }else{
                        alert('delete failed!');
                    }
                });
            }
        }
    }

    handleSetProjectStorage(param){
        setLocalStorage('project', param);
    }
}
