import React from 'react'
import { Header, List, Flag} from 'semantic-ui-react'
import NoDataImage from '../../../components/nodata'

export default class ExportSection extends React.Component{
    render(){
        let currData = this.props.data
        return(
            <div>
                <Header as='h3' dividing>
                    Recent Export
                </Header>
                {currData.last_export.length > 0 ? <List>
                    {currData.last_export.map((v, k)=><List.Item key={k}>
                    <List.Icon name='file' />
                    <List.Content>
                        <List.Header>
                            {v.language === 'en' || v.language === '' ? <div>{v.username} export <Flag name='gb'/> translation</div> : <div>
                                {v.username} export <Flag name={currData.languages_info[v.language].language_flag}/> translation
                            </div>}
                        </List.Header>
                        <List.Description>on {v.updated_at}</List.Description>
                    </List.Content>
                    </List.Item>)}
                </List> : <NoDataImage size='small'/>}
            </div>
        )
    }
}