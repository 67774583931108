import React from 'react'
import MainUserManage from './main'
import UserDetail from './user_detail'
import { Divider } from 'semantic-ui-react'
import { Route } from 'react-router-dom'
import BreadcrumbComponent, { BreadcrumbRoot } from '../components/breadcrumb/breadcrumb'
export default function LUserManage(props){
    let pathName = props.location.pathname;
    let sections = [];
    pathName = pathName.split('/');
    if(pathName.length === 4){
        sections = [{ key: 'Users', content: 'Users', link: true, target: '/admin/user' },
        { key: pathName[3], content: pathName[3], active: true },]
    }

    let breadcrumbRoot = <div><BreadcrumbRoot data={'Users'}/><Divider /><Divider hidden /></div>;
    let breadcrumbComponent = <div>
        <BreadcrumbComponent data={sections}/>
        <Divider />
        </div>;

    return (
        <div>
            {
                sections.length !== 0 ? breadcrumbComponent : breadcrumbRoot
            }
            <Route path='/admin/user' exact component={MainUserManage} />
            <Route path='/admin/user/:username' exact component={UserDetail} />
        </div>
    )
}
