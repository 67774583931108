import React from 'react'
import { Progress, Segment, Button, Header,
    Form, TextArea, Icon, Table, Radio, Flag,
    Modal, Grid, Loader, Container, Label, Message } from 'semantic-ui-react'
import { getLocalStorage } from '../../helper/local_storage'
import { Link } from 'react-router-dom'
import NoDataImage from '../../admin/components/nodata'
const superagent = require('superagent')

export default class Validation extends React.Component{
    constructor(props){
        super(props)
        let userInfo = getLocalStorage('user');
        this.state = {
            listTranslation: [],
            listOldTranslation: [],
            currentTranslation: null,
            currentProgress: 1,
            totalProgress: 10,
            newTranslate: '',
            userInfo: userInfo.user,
            translationIds: [],
            isLoading: true,
            isModalFinishOpen: false,
            isTableDisplay: false,
            radioValue: null,
            allProgress: null,
            showTip: true
        }

        this.handleSubmitTranslation = this.handleSubmitTranslation.bind(this)
        this.handleFormChange = this.handleFormChange.bind(this)
        this.handleDisplayChange = this.handleDisplayChange.bind(this)
    }

    componentWillMount(){
        this.handleRefreshData()
    }

    handleDisplayChange(e, event){
        let isAct = false
        if(event.act === 'table'){
            isAct = true
        }
        if(this.state.isTableDisplay !== isAct){
            this.setState({isTableDisplay: isAct})
            this.handleRefreshData()
        }
    }

    handleFormChange(e, data){
        if(data.name.includes('textarea')){
            let splitName = data.name.split('_')
            if(splitName.length > 1){
                let tId = parseInt(splitName[1])
                let tData = this.state.listTranslation
                let filteredData = tData.filter(x=>x.translation_id === tId);
                tData[tData.findIndex(i => i.translation_id === filteredData[0].translation_id)].translation_value = data.value
                this.setState({radioValue: data.value, listTranslation:tData})
            }else{
                this.setState({newTranslate: data.value})
            }
        }else if(data.name.includes('radioGroup')){
            let splitName = data.name.split('_')
            if(splitName.length > 1){
                let tId = parseInt(splitName[1])
                let tData = this.state.listTranslation
                let oldData = this.state.listOldTranslation
                let filteredData = tData.filter(x=>x.translation_id === tId)
                let oldFiltered = oldData.filter(x=>x.translation_id === tId)
                let klp = []
                if(oldFiltered.length === 0){
                    klp.push({
                        translation_id: tId,
                        translation_value: filteredData[0].translation_value
                    })
                }else{
                    klp = oldFiltered
                    if(data.value){
                        tData[tData.findIndex(i => i.translation_id === filteredData[0].translation_id)].translation_value = oldFiltered[0].translation_value
                    }
                }

                tData[tData.findIndex(i => i.translation_id === filteredData[0].translation_id)].is_verified = data.value
                tData[tData.findIndex(i => i.translation_id === filteredData[0].translation_id)].project_id = null

                this.setState({radioValue: data.value, listTranslation:tData, listOldTranslation: klp})
            }else{
                this.setState({radioValue: data.value ? true : false, newTranslate: this.state.currentTranslation.translation_value})
            }
        }
    }

    handleSubmitTranslation(e, event){
        if(event.act === 'submit_table'){
            let tData = this.state.listTranslation
            let oldData = this.state.listOldTranslation
            let filteredData = tData.filter(x=>x.translation_id === event.id);
            let oldFilteredData = oldData.filter(x=>x.translation_id === event.id);
            if(filteredData.length > 0){
                filteredData = filteredData[0]

                let trans = oldFilteredData !== undefined && oldFilteredData.length > 0 ? 
                    oldFilteredData[0].translation_value : ''
                let is_verified = false
                
                if(!filteredData.is_verified){
                    if(filteredData.translation_value !== trans){
                        trans = filteredData.translation_value
                        is_verified = true
                    }
                }else{
                    is_verified = true
                }

                if(trans === ''){
                    alert('Translation cannot be empty.')
                    return false
                }
                let dataRequest = {
                    translation: trans,
                    is_verified: is_verified
                }

                superagent
                .post(process.env.REACT_APP_API_URL+'api/translation/manual/'+filteredData.translation_id)
                .send(dataRequest)
                .set('X-API-KEY', this.state.userInfo.api_key)
                .end((err, res) => {
                    tData[tData.findIndex(i => i.translation_id === filteredData.translation_id)].project_id = true
                    let idx = 1;
                    let isShow = false;
                    tData.forEach(function(val){
                        if(typeof(val.project_id) === 'string'){
                            return false;
                        }else if(val.project_id){
                            if(idx === tData.length){
                                isShow = true
                            }
                        } 
                        idx++
                    })
                    this.setState({listTranslation:isShow ? [] : tData, isModalFinishOpen: isShow})
                })
            }
        }else{
            let count = this.state.currentProgress;
        
            if(count <= this.state.totalProgress){
                let listTranslation = this.state.listTranslation
                count = count + 1;
                let ids = this.state.translationIds
                let datacur = listTranslation.filter(function(item){
                    return ids.indexOf(item.translation_id) === -1;
                });
                if(datacur.length > 0){
                    ids.push(datacur[0].translation_id)
                }else{
                    ids = []
                }
                if(event.act === 'skip'){
                    superagent
                    .get(process.env.REACT_APP_API_URL+'api/reset/translation/'+this.state.currentTranslation.translation_id)
                    .set('X-API-KEY', this.state.userInfo.api_key)
                    .end((err, res) => {
                        this.setState({currentProgress: count, currentTranslation: datacur.length > 0 ? datacur[0] : null, 
                        newTranslate:'', translationIds: ids, isModalFinishOpen: count-1 === this.state.totalProgress ? true : false,
                        listTranslation: count-1 === this.state.totalProgress ? [] : this.state.listTranslation})
                    })
                }else{
                    if(this.state.newTranslate === ''){
                        alert('Translation cannot be empty.')
                        return false
                    }
                    let dataRequest = {
                        translation: this.state.newTranslate,
                        is_verified: this.state.currentTranslation.translation_value !== this.state.newTranslate 
                        ? true : this.state.radioValue
                    }
                    superagent
                    .post(process.env.REACT_APP_API_URL+'api/translation/manual/'+this.state.currentTranslation.translation_id)
                    .send(dataRequest)
                    .set('X-API-KEY', this.state.userInfo.api_key)
                    .end((err, res) => {
                        this.setState({currentProgress: count, currentTranslation: datacur.length > 0 ? datacur[0] : null, 
                        newTranslate:'', translationIds: ids, isModalFinishOpen: count-1 === this.state.totalProgress ? true : false,
                        listTranslation: count-1 === this.state.totalProgress ? [] : this.state.listTranslation,
                        radioValue: null})
                    })
                }
            }
        }
    }

    handleRefreshData = () =>{
        this.setState({isLoading: true})
        superagent
        .get(process.env.REACT_APP_API_URL+'api/translation-list/pick?lang='+this.props.match.params.lang+'&type=validate')
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            this.setState({isLoading:false, isModalFinishOpen: false})
            if(res.body !== undefined && res.body.data !== undefined){
                if(Array.isArray(res.body.data) && res.body.data.length > 0){
                    let ids = this.state.translationIds
                    ids.push(res.body.data[0].translation_id)
                    this.setState({listTranslation: res.body.data, currentTranslation: res.body.data[0], currentProgress: 1,
                    totalProgress: res.body.data.length, translationIds:ids, radioValue: null, 
                    allProgress: res.body.progress !== undefined ? res.body.progress : null })
                }
            }
        })
    }

    render(){
        return(
            <Container style={{padding:20}}>
                {this.state.showTip ? 
                <Message size='mini'
                info
                onDismiss={()=>this.setState({showTip: false})}
                header='Additional Information!'
                content={
                    <p>
                        Some of translations might contain variables, please take a look on <b>"How to Handle Variable?"</b>
                    </p>
                }
                />
                : null}
                {
                    this.state.isLoading ? <Loader active>Fetching Translation...</Loader> :
                        this.state.listTranslation.length > 0 && this.state.currentTranslation !== null ?
                        <div>
                            {this.state.allProgress !== null ? 
                            <div>
                                <Header as='h4'>Proofread Progress ({this.state.allProgress.finished}/{this.state.allProgress.total})</Header>
                                <Progress percent={this.state.allProgress.percentage} 
                                color='green' progress/>
                            </div>
                            : null}
                            <Button.Group>
                                <Button act='single' onClick={this.handleDisplayChange} icon active={!this.state.isTableDisplay}>
                                    <Icon name='th' />
                                </Button>
                                <Button act='table' onClick={this.handleDisplayChange} icon active={this.state.isTableDisplay}>
                                    <Icon name='th list' />
                                </Button>
                            </Button.Group>
                            <Header as='h4' attached='top'>
                                Translation
                                
                                <Modal trigger={<Icon style={{float: 'right'}} link name='question circle outline' />}>
                                    <Modal.Header>Context Information</Modal.Header>
                                    <Modal.Content>
                                    <Grid columns={2} divided>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <Modal.Description>
                                                    <Header>General Context</Header>
                                                    <p>{this.state.currentTranslation.project_info.project_description}</p>
                                                </Modal.Description>
                                            </Grid.Column>
                                            {!this.state.isTableDisplay ? <Grid.Column>
                                                <Modal.Description>
                                                    <Header>Translation Context</Header>
                                                    <p>{this.state.currentTranslation.base_info.base_description}</p>
                                                </Modal.Description>
                                            </Grid.Column> : ''}
                                        </Grid.Row>
                                    </Grid>
                                    </Modal.Content>
                                </Modal>
                                <Modal closeIcon trigger={
                                    <Label style={{float: 'right'}} as='a' color='teal' tag>
                                        How to Handle Variable?
                                    </Label>
                                }>
                                    <Modal.Header>Variable Translation Guide</Modal.Header>
                                    <Modal.Content>
                                    <p>
                                    Please find below the information you need to know before you start translating in localizy.<br/><br/>
                                    Every translation that contain <b style={{color: '#00b5ad'}}>@</b> is a variable and need to write as it is, for example:
                                    </p>
                                    <Header as='h2' color='teal'>
                                        @&&USER_NAME&&
                                    </Header>
                                    <p>You must copy it and write exactly the same for the whole word 
                                    and continue with the rest of the words based on specific language 
                                    that you are translating or proofreading.</p>
                                    </Modal.Content>
                                </Modal>
                            </Header>
                            {
                                this.state.isTableDisplay ? 
                                <Segment attached clearing>
                                    <Table className='fixed'>
                                        <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell><Flag name='gb' /> ENGLISH</Table.HeaderCell>
                                            <Table.HeaderCell style={{textTransform:'uppercase'}}>
                                                {this.state.currentTranslation.language_info.language_name}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell width={1}>
                                                <Icon name='check' color='green'/>
                                            </Table.HeaderCell>
                                            <Table.HeaderCell width={1}>
                                                <Icon name='remove' color='red'/>
                                            </Table.HeaderCell>
                                            <Table.HeaderCell width={2}>Action</Table.HeaderCell>
                                        </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {this.state.listTranslation.map((val, k)=>
                                                <Table.Row key={k}>
                                                <Table.Cell>
                                                    {val.base_info.is_variable ? 
                                                    <Label style={{marginRight: 5}} size='mini' color='teal'>@</Label>
                                                    : null}
                                                    {val.base_info.base_value}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {val.project_id === null && !val.is_verified ? <Form>
                                                    <TextArea name={'textarea_'+val.translation_id} value={val.translation_value} onChange={this.handleFormChange} 
                                                    placeholder='Type here if you want to change the translation...' />
                                                    </Form> : val.translation_value}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {val.project_id !== true ? <Radio
                                                        name={'radioGroup_'+val.translation_id}
                                                        value={1}
                                                        checked={val.project_id === null ? val.is_verified ? true : false : false}
                                                        onChange={this.handleFormChange}
                                                    /> : ''}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {val.project_id !== true ? <Radio
                                                        name={'radioGroup_'+val.translation_id}
                                                        value={0}
                                                        checked={val.project_id === null ? !val.is_verified ? true : false : false}
                                                        onChange={this.handleFormChange}
                                                    /> : ''}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {
                                                        val.project_id === null ? 
                                                        <Button size='mini' act='submit_table' id={val.translation_id} 
                                                        disabled={val.project_id === null ? false : true}
                                                        onClick={this.handleSubmitTranslation} primary>Verify</Button>
                                                        : val.project_id === true ? 'Submitted' : ''
                                                    }
                                                    {
                                                        this.state.isTableDisplay ? <Modal trigger={<Icon style={{float: 'right'}} link name='question circle outline' />}>
                                                        <Modal.Header>Context Information</Modal.Header>
                                                        <Modal.Content>
                                                        <Grid columns={2} divided>
                                                            <Grid.Row>
                                                                <Grid.Column>
                                                                    <Modal.Description>
                                                                        <Header>Translation Context</Header>
                                                                        <p>{val.base_info.base_description}</p>
                                                                    </Modal.Description>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                        </Modal.Content>
                                                        </Modal> : ''
                                                    }
                                                </Table.Cell>
                                            </Table.Row>)}
                                        </Table.Body>
                                    </Table>
                                </Segment>
                                :
                                <Segment attached clearing>
                                    <Progress color='blue' value={this.state.currentProgress} 
                                    total={this.state.totalProgress} progress='ratio' />
                                    <h5><Flag name='gb' /> ENGLISH</h5>
                                    <h4>{this.state.currentTranslation.base_info.base_value}</h4>
                                    <Table basic='very' style={{width:'100%'}}>
                                        <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>
                                            <h5 style={{textTransform:'uppercase'}}>{this.state.currentTranslation.language_info.language_name}</h5>
                                            </Table.HeaderCell>
                                            <Table.HeaderCell width={1}>
                                                <Icon color='green' name='check'/>
                                            </Table.HeaderCell>
                                            <Table.HeaderCell width={1}>
                                                <Icon color='red' name='remove'/>
                                            </Table.HeaderCell>
                                        </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                        <Table.Row>
                                            <Table.Cell>
                                                <h4>
                                                    {this.state.currentTranslation.base_info.is_variable ? 
                                                    <Label style={{marginRight: 5}} size='mini' color='teal'>@</Label>
                                                    : null}
                                                    {this.state.currentTranslation.translation_value}
                                                </h4>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Radio
                                                    name='radioGroup'
                                                    value={1}
                                                    checked={this.state.radioValue !== null ? this.state.radioValue : false}
                                                    onChange={this.handleFormChange}
                                                />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Radio
                                                    name='radioGroup'
                                                    value={0}
                                                    checked={this.state.radioValue !== null ? !this.state.radioValue : false}
                                                    onChange={this.handleFormChange}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                        </Table.Body>
                                    </Table>
                                    {!this.state.radioValue && this.state.radioValue!== null ? <Form>
                                        <TextArea name='textarea' onChange={this.handleFormChange} value={this.state.newTranslate} 
                                        placeholder='Type here if you want to change the translation...' />
                                    </Form> : ''}
                                    <br/>
                                    <Link to={'/contributor/'+this.props.match.params.lang} className='ui button'>BACK</Link>
                                    <Button floated='right' act='submit' disabled={this.state.radioValue === null ? true : false} 
                                    primary onClick={this.handleSubmitTranslation}>SUBMIT</Button>
                                    <Button floated='right' act='skip' onClick={this.handleSubmitTranslation}>SKIP</Button>
                                </Segment>
                            }
                        </div> : <NoDataImage/>
                }
                <Modal dimmer='blurring' open={this.state.isModalFinishOpen} size='small'>
                    <Header icon='browser' content='Finish Submission' />
                    <Modal.Content>
                        <Modal.Description>
                        <h3>You've complete the steps, our system will be assiged you another available task.</h3>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                    <Button color='green' onClick={this.handleRefreshData} inverted>
                        <Icon name='checkmark' /> Got it
                    </Button>
                    </Modal.Actions>
                </Modal>
            </Container>
        )
    }
}