import React from 'react'
import { Grid, Segment, Loader,
    Tab, Statistic, Dropdown, MenuItem, Header, Divider } from 'semantic-ui-react'
import { getLocalStorage } from '../../helper/local_storage'
import LanguageSetting from './settings/language'
import PasswordSetting from './settings/password'
import AvatarSetting from './settings/avatar'
import './contributor.scss'
import { nFormatter } from '../../helper/number'
const superagent = require('superagent');

export default class SideNavCon extends React.Component{
    constructor(props){
        super(props)
        let userInfo = getLocalStorage('user')
        this.state = {
            isLoading: true,
            userInfo: userInfo.user,
            userStatistic: [],
            userPreferredLang: [],
        }
    }

    componentWillMount(){
        superagent
        .get(process.env.REACT_APP_API_URL+'api/user/detail')
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            if(res.body.status === 200){
                this.setState({userStatistic:res.body.data.stats, isLoading: false,
                    userPreferredLang: res.body.data.preferred_languages})
            }
        });
    }

    render(){
        let stats = this.state.userStatistic
        let translatorTab = []
        let uPL = this.state.userPreferredLang
        let userInfo = this.state.userInfo
        uPL.forEach(function(vlang){
            let sData = stats.filter(x=>x.lang.language_code === vlang.language_code)
            if(sData.length > 0){
                sData = sData[0]
            }else{
                sData = {
                    total_word:0,
                    total_character:0,
                    total_line:0,
                    total_acception:0,
                    total_rejection:0,
                    total_assigment:0,
                    total_translation_accepted:0,
                    total_translation_rejected:0,
                    total_translation_assignment:0,
                    total_validation_assignment:0
                }
            }

            sData.total_word = nFormatter(sData.total_word, 1)
            sData.total_character = nFormatter(sData.total_character, 1)
            sData.total_line = nFormatter(sData.total_line, 1)
            sData.total_acception = nFormatter(sData.total_acception, 1)
            sData.total_rejection = nFormatter(sData.total_rejection, 1)
            sData.total_assigment = nFormatter(sData.total_assigment, 1)
            sData.total_translation_accepted = nFormatter(sData.total_translation_accepted, 1)
            sData.total_translation_rejected = nFormatter(sData.total_translation_rejected, 1)
            sData.total_translation_assignment = nFormatter(sData.total_translation_assignment, 1)
            sData.total_validation_assignment = nFormatter(sData.total_validation_assignment, 1)

            translatorTab.push({
                menuItem:  <MenuItem key={vlang.language_code}>{vlang.language_name}</MenuItem>,
                render: () => <Tab.Pane style={{textAlign:'center'}}>
                    {userInfo.is_translator ? <div>
                        <Header as='h3'>Translation</Header>
                        <Statistic.Group horizontal size={'small'}>
                        <Statistic>
                            <Statistic.Value>{sData.total_word}</Statistic.Value>
                            <Statistic.Label>Words</Statistic.Label>
                        </Statistic>
                        <Statistic>
                            <Statistic.Value>{sData.total_character}</Statistic.Value>
                            <Statistic.Label>Characters</Statistic.Label>
                        </Statistic>
                        <br/>
                        <Statistic>
                            <Statistic.Value>{sData.total_line}</Statistic.Value>
                            <Statistic.Label>Lines</Statistic.Label>
                        </Statistic>
                        <Statistic>
                            <Statistic.Value>{sData.total_translation_accepted}</Statistic.Value>
                            <Statistic.Label>Accepted</Statistic.Label>
                        </Statistic>
                        <br/>
                        <Statistic>
                            <Statistic.Value>{sData.total_translation_rejected}</Statistic.Value>
                            <Statistic.Label>Rejected</Statistic.Label>
                        </Statistic>
                        <Statistic>
                            <Statistic.Value>{sData.total_translation_assignment}</Statistic.Value>
                            <Statistic.Label>Assigment</Statistic.Label>
                        </Statistic>
                        </Statistic.Group>
                        <Divider/></div> : ''}
                    {userInfo.is_validator ? <div>
                        <Header as='h3'>Proofread</Header>
                        <Statistic.Group horizontal size={'small'}>
                        <Statistic>
                            <Statistic.Value>{sData.total_rejection}</Statistic.Value>
                            <Statistic.Label>Rejections</Statistic.Label>
                        </Statistic>
                        <Statistic>
                            <Statistic.Value>{sData.total_acception}</Statistic.Value>
                            <Statistic.Label>Acception</Statistic.Label>
                        </Statistic>
                        <Statistic>
                            <Statistic.Value>{sData.total_validation_assignment}</Statistic.Value>
                            <Statistic.Label>Assigment</Statistic.Label>
                        </Statistic>
                        </Statistic.Group>
                    </div> : ''}
                </Tab.Pane>
            })
        })

        return(
            <Grid.Column width={4} id="sideNavCon">

                <Segment inverted>
                    <div id="avatarBox">
                      <img alt='ava' className="ui small circular centered image" src={require('../../img/avatar/'+this.state.userInfo.avatar+'.png')} />
                      <AvatarSetting/>
                    </div>

                    <h3>{this.state.userInfo.full_name}</h3>
                    <div className="ui blue label"><i className="user icon"></i>{this.state.userInfo.username}</div>
                    <div className="ui blue label"><i className="mail icon"></i>{this.state.userInfo.email}</div>
                    <Dropdown icon='setting' className='tertiary' id='settingButton'>
                        <Dropdown.Menu>
                            <LanguageSetting/>
                            <PasswordSetting/>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Divider hidden/>
                    {this.state.isLoading ? <Loader inline inverted active>Loading Statistic...</Loader> :
                    <Segment id="profileStats"><Tab menu={{ secondary: true }} panes={translatorTab} /></Segment>}
                </Segment>
            </Grid.Column>
        )
    }

    logout = () =>{
        superagent.get(process.env.REACT_APP_API_URL+'api/user/logout')
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            localStorage.clear();
            window.location.href='/'
        });
    }
}
