import React from 'react'
import { getLocalStorage } from '../../../helper/local_storage'
import { Menu, Dropdown } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import './main_nav.scss'
const superagent = require('superagent');

export default function MainNav(props){
    let userLS = getLocalStorage('user');
    const [userInfo] = React.useState(userLS.user);
    function logout(){
        superagent.get(process.env.REACT_APP_API_URL+'api/user/logout')
        .set('X-API-KEY', userInfo.api_key)
        .end((err, res) => {
            localStorage.clear();
            window.location.href='/'
        });
    }
    let activePath = window.location.pathname.split('/');
    activePath = activePath[2];
    return (
        <Menu stackable fixed={'top'} className={'inverted'}>
            <Link to="/" className='header item'>
            <img alt="test" src={require('../../../img/logo-header.png')} />
            </Link>
            <Link to="/owner/dashboard" className={'item'+ (activePath === 'dashboard' ? ' active' : '')}>Dashboard</Link>
            <Link to='/owner/user' className={'item'+ (activePath === 'user' ? ' active' : '')}>User Management</Link>
            <Link to='/owner/static/language' className={'item'+ (activePath === 'static' ? ' active' : '')}>Static Manage</Link>
            <Menu.Menu position='right'>
                <Dropdown item text={userInfo.username}>
                  <Dropdown.Menu>
                    <Dropdown.Item name='help' onClick={logout}>
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
            </Menu.Menu>
        </Menu>
    )
}
