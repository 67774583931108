import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import './sass/style.scss';

import { BrowserRouter as Router, Route } from 'react-router-dom'

import LLoginForm from './auth/login';
import ForgotPassword from './auth/forgot_password';
import ResetPassword from './auth/reset_password';
import AdminApp from './admin/app';
import ContributorApp from './contributor/app';
import OwnerApp from './owner/app';

class App extends Component {
  render () {
    return(
      <Router>
        <Route exact path="/" component={LLoginForm} />
        <Route exact path="/forgot" component={ForgotPassword} />
        <Route exact path="/reset/:username/:token" component={ResetPassword} />
        <Route path="/admin" component={AdminApp} />
        <Route path="/contributor" component={ContributorApp} />
        <Route path="/owner" component={OwnerApp} />
      </Router>
    )

  }
}

export default App;
