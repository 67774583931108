import React from 'react'
import { Divider } from 'semantic-ui-react'
import SecondNav from '../components/second_nav'
import WelcomeAdmin from './welcome/welcome_admin'
import ProjectRoute from '../routes/project_route'
import BreadcrumbComponent, { BreadcrumbRoot } from '../components/breadcrumb/breadcrumb'
import FabButton from '../components/fab_button'
import { getLocalStorage, setLocalStorage } from '../../helper/local_storage'
import PublicApiManage from './public_api/manage'

class LProjects extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            isArchiveAllHasBeenClicked: false
        }

        this.setArchiveAllButtonToBeClicked = this.setArchiveAllButtonToBeClicked.bind(this)
    }
    render(){
        let sections = [];
        let k = this.props.location.pathname.split('/');
        let menuList = []
        let contentHTML;
        let pinned = <PublicApiManage
            isArchiveAllHasBeenClicked={this.state.isArchiveAllHasBeenClicked} 
            setArchiveAllButtonToBeClicked={this.setArchiveAllButtonToBeClicked}/>
        
        if(k.length === 3){
            contentHTML = <WelcomeAdmin param={this.props}/>
        }else if(k.length >= 4){
            let projectInfo = getLocalStorage('project')
            let p_id = k[k.length-2]
            if(projectInfo ===  null || projectInfo === ""){
                let userInfo = getLocalStorage('user');
                userInfo = userInfo.user
                fetch(process.env.REACT_APP_API_URL+'api/project/username', {
                    headers:{
                        'X-API-KEY': userInfo.api_key
                    }
                })
                .then((res)=>{
                    return res.json()
                })
                .then((res)=>{
                    res.forEach(x=>{
                        if(x.project.project_id === p_id){
                            setLocalStorage("project", x)
                            projectInfo = x
                        }
                    })
                })
            }else{
                if(projectInfo.project.project_id !== p_id){
                    projectInfo = ""
                    let userInfo = getLocalStorage('user');
                    userInfo = userInfo.user
                    fetch(process.env.REACT_APP_API_URL+'api/project/username', {
                        headers:{
                            'X-API-KEY': userInfo.api_key
                        }
                    })
                    .then((res)=>{
                        return res.json()
                    })
                    .then((res)=>{
                        res.forEach(x=>{
                            if(x.project.project_id === p_id){
                                setLocalStorage("project", x)
                                projectInfo = x
                            }
                        })
                    })
                }
            }

            
            if(projectInfo !== "" && projectInfo !== null){
                sections = [
                    { key: 'Projects', content: 'Projects', link: true, target: '/admin/projects' },
                    { key: k[3], content: projectInfo.project.project_name, active: true },
                ]
            }
            menuList = [
                {
                    target: this.props.match.url+'/'+k[3]+'/dashboard',
                    path: 'dashboard',
                    text:'Dashboard'
                },
                {
                    target: this.props.match.url+'/'+k[3]+'/translation',
                    path: 'translation',
                    text:'Translation'
                },
                {
                    target: this.props.match.url+'/'+k[3]+'/base',
                    path: 'base',
                    text:'Manage Base'
                },
                {
                    target: this.props.match.url+'/'+k[3]+'/versioning',
                    path: 'versioning',
                    text:'Versioning'
                },
                {
                    target: this.props.match.url+'/'+k[3]+'/history',
                    path: 'history',
                    text:'History'
                },
                {
                    target: this.props.match.url+'/'+k[3]+'/settings',
                    path: 'settings',
                    text:'Settings'
                }
            ]
            
            contentHTML = <ProjectRoute/>
        }

        let breadcrumbRoot = <div><BreadcrumbRoot data={'Projects'}/>{pinned}<Divider /><Divider hidden /></div>;
        let breadcrumbComponent = <div>
            <BreadcrumbComponent data={sections}/>
            {pinned}
            <div class="ui hidden section divider"></div>
            <Divider />
            <FabButton setArchiveAllButtonToBeClicked={this.setArchiveAllButtonToBeClicked}/>
            <SecondNav data={menuList} match={this.props.location}/>
            <Divider className={'hidden'} />
            </div>;
        return (
            <div>
                {menuList.length === 0 ? breadcrumbRoot : breadcrumbComponent}
                
                {contentHTML}
            </div>
        )
    }

    setArchiveAllButtonToBeClicked(b){
        this.setState({
            isArchiveAllHasBeenClicked: b
        })
    }
}

export default LProjects
