import React from 'react'
import { getLocalStorage } from '../../../helper/local_storage'
import { Button, Checkbox, Modal, Popup, List,
    TransitionablePortal, TextArea, Form, Label,
    Loader, Icon, Dropdown, Card, Input, Segment
} from 'semantic-ui-react'
import DataTable from 'react-data-table-component'
import { tableSearch } from '../../../helper/dataTable'
import NoDataImage from '../../components/nodata'

const superagent = require('superagent')

class Translation extends React.Component{
    constructor(props){
        super(props)
        let userInfo = getLocalStorage('user');
        this.state = {
            userInfo: userInfo.user,
            isLoading: true,
            translationList: [],
            oldTranslationList: [],
            open: false,
            textVarible: {},
            isOpenModal: false,
            languages: [],
            currentLang: '',
            isShowEmptyTranslate: false,
            isShowVariable: false,
            checkedDataTable: [],
            toggledClearRows: false,
            currentTranslation: '',
            currentBaseTranslation: '',
            currentTranslationId: 0,
            saveTranslationLoading: false,
            search: ''
        }
        this.handleTableAction = this.handleTableAction.bind(this);
        this.handleRefreshTranslation = this.handleRefreshTranslation.bind(this);
        this.handleChangeFilterTable = this.handleChangeFilterTable.bind(this);
        this.handleTableChange = this.handleTableChange.bind(this);
        this.handleEditTranslation = this.handleEditTranslation.bind(this);
    }

    componentWillMount(){
        let listLanguages = [];
        superagent.get(process.env.REACT_APP_API_URL+'api/langproject/'+this.props.match.params.project_id)
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            if(res !== undefined){
                if(res.body.status !== undefined){
                    localStorage.clear();
                    alert('something wrong! You will be logout in 3 seconds')
                    setTimeout(()=>{
                        window.location.href='/'
                    }, 3000)
                }else{
                    if(res.body.length > 0){
                        this.setState({currentLang: res.body[0].language_code});
                        res.body.forEach(function(val){
                            listLanguages.push(
                                { 
                                    key: val.language_code, 
                                    value: val.language_code, 
                                    flag: val.language_flag, 
                                    text: val.language_name 
                                }
                            )
                        });
                        this.setState({languages: listLanguages});
                        this.handleRefreshTranslation();
                    }else{
                        alert('no lang found!');
                    }
                }
            }else{
                localStorage.clear();
                alert('something wrong! You will be logout in 3 seconds')
                setTimeout(()=>{
                    window.location.href='/'
                }, 3000)
            }
        });
    }

    render(){
        let columns = [
            {
                name: 'Key',
                selector: 'base_key',
                sortable: true,
                ignoreRowClick: true,
                allowOverflow: true,
                wrap: true
            },
            {
                name: 'Base Value',
                selector: 'base_value',
                sortable: true,
                ignoreRowClick: true,
                allowOverflow: true,
                wrap: true
            },
            {
                name: 'Translation',
                selector: 'translation_value',
                sortable: true,
                ignoreRowClick: true,
                allowOverflow: true,
                wrap: true
            },
            {
                name: 'Assigned',
                selector: 'username',
                sortable: true,
                ignoreRowClick: true,
                allowOverflow: false,
                wrap: true
            },
            {
                name: 'Is Verified',
                selector: 'is_verified',
                sortable: true,
                ignoreRowClick: true,
                allowOverflow: false,
                wrap: true,
                cell: row=>{
                    let comp = row.is_verified ? <Icon name='check' color='green' /> : 
                    <Icon name='remove' color='red' />
                    return comp
                }
            },
            {
                name: 'Last Updated',
                selector: 'updated_at',
                sortable: true,
                ignoreRowClick: true,
                allowOverflow: false,
                wrap: true
            },
            {
                name: 'Actions',
                cell: row => {
                    let options = [
                        { key: 0, color: 'blue', text: 'Edit', value: 'edit' },
                    ];
                    if(!row.is_verified && row.translation_value !== null && row.translation_value !== '' && row.error_list.length === 0){
                        options = [
                            { key: 0, color: 'blue', text: 'Edit', value: 'edit' },
                            { key: 1, color: 'green', text: 'Verify', value: 'verify' },
                        ]
                    }
                    return (
                        <div>
                            {
                                options.map((val, k) =>
                                <Button key={k} size='small' color={val.color} act={val.value} 
                                b_id={row.translation_id} action={val.key} 
                                onClick={this.handleEditTranslation}>
                                    {val.text}
                                </Button>
                                )
                            }
                            {row.error_list.length > 0 ? 
                            <Popup basic style={{background:'#db2828', color:'#fff'}} content={
                                <List bulleted>
                                {row.error_list.map((v,k)=><List.Item key={k}>{v}</List.Item>)}
                                </List>
                            } 
                            trigger={<Icon circular  name='exclamation' color='red'/>} /> : 
                            ''}
                        </div>
                    )
                },
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
                wrap: true
            }
        ]
        let actions = [
            <Button key='btn_translate_all' act='all' onClick={this.handleTableAction} 
            floated='right'
            content='Translate All' icon='tasks' 
            labelPosition='right' />,
            <Button key='btn_translate_some' act='some' onClick={this.handleTableAction} 
            floated='right'
            content='Translate Selected' icon='file alternate' 
            labelPosition='right' />
        ]
        return(
            <div>
                <Dropdown name='lang' placeholder='Language' onChange={this.handleChangeFilterTable}
                search selection options={this.state.languages} value={this.state.currentLang} />
                
                <Checkbox checked={this.state.isShowEmptyTranslate} name='unstranslated' 
                style={{marginLeft:10}} label='show untranslated words only?' onChange={this.handleChangeFilterTable} />

                <Checkbox checked={this.state.isShowVariable} name='variable' 
                style={{marginLeft:10}} label='show variable only?' onChange={this.handleChangeFilterTable} />

                <Card fluid color='blue'>
                    { this.state.isLoading ? <div>
                        <Loader active/>
                    </div> : ''}
                    <DataTable
                        title="Translation"
                        columns={columns}
                        data={this.state.translationList}
                        highlightOnHover
                        defaultSortField="base_id"
                        defaultSortAsc={false}
                        actions={actions}
                        sortIcon={<Icon name='sort amount down'/>}
                        keyfield='base_id'
                        pagination
                        subHeader
                        subHeaderComponent={
                            <Input onChange={this.tableSearch} size='small'
                            icon='search' placeholder='Search...' />
                        }
                        selectableRows
                        selectableRowsComponent={Checkbox}
                        onTableUpdate={this.handleTableChange}
                        clearSelectedRows={this.state.toggledClearRows}
                        noDataComponent={<NoDataImage/>}
                    />
                </Card>

                <TransitionablePortal open={this.state.isOpenModal}>
                    <Modal open onClose={this.close} size='tiny' closeOnDimmerClick={true} closeOnDocumentClick={true}>
                        <Modal.Header>Edit Translation</Modal.Header>
                        <Modal.Content>
                            <Segment inverted color='black'>
                                <Label color='orange' ribbon>
                                    Base Value
                                </Label>
                                {this.state.currentBaseTranslation}
                            </Segment>
                            <Form>
                                <TextArea onChange={this.onChangeTranslate} value={this.state.currentTranslation} placeholder='Type your manual translation...' />
                            </Form>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button content='Cancel' onClick={this.close} />
                            <Button primary loading={this.state.saveTranslationLoading} disabled={this.state.saveTranslationLoading}
                                content='Save'
                                onClick={this.handleUpdateTranslate}
                            />
                        </Modal.Actions>
                    </Modal>
                </TransitionablePortal>
            </div>
        )
    }

    handleChangeFilterTable(e, data){
        let cName = data.name
        if(cName === 'lang'){
            if(this.state.currentLang !== data.value){
                this.setState({currentLang: data.value, isLoading:true}, this.handleRefreshTranslation);
            }
        }else if(cName === 'unstranslated'){
            let k = this.state.oldTranslationList;
            if(data.checked){
                k = this.state.oldTranslationList.filter(word => word.translation_value === '' || word.translation_value === null);
            }
            this.setState({translationList: k, isShowEmptyTranslate: data.checked});
        }else{
            let k = this.state.oldTranslationList;
            if(data.checked){
                k = this.state.oldTranslationList.filter(word => word.is_variable);
            }
            this.setState({translationList: k, isShowVariable: data.checked});
        }
        
    }

    tableSearch = (event, e) =>{
        let txtSearch = ''
        let stateSearch = this.state.search
        if(e !== undefined){
            if(stateSearch !== e.value){
                txtSearch = e.value
            }else{
                txtSearch = stateSearch
            }
        }else{
            txtSearch = stateSearch
        }

        let dataFilter = this.state.isShowEmptyTranslate ? 
            this.state.oldTranslationList.filter(word => word.translation_value === '' || word.translation_value === null)
            : this.state.oldTranslationList;

        if(this.state.isShowVariable){
            dataFilter = dataFilter.filter(x=>x.is_variable)
        }

        if(txtSearch === ''){
            this.setState({translationList:dataFilter, search: ''});
        }else{
            this.setState({translationList:tableSearch(dataFilter, txtSearch), search: txtSearch});
        }
    }

    handleTableAction(event, e){
        if(e.act === 'all'){
            if(window.confirm('are you sure want to translate all untranslated words with autotranslate?')){
                let pStorage = getLocalStorage('project');
                this.setState({isLoading: true});
                let fData = {
                    is_auto: false,
                    lang: this.state.currentLang,
                    project_id: pStorage.project_id
                }
                superagent
                .post(process.env.REACT_APP_API_URL+'api/translation/save')
                .send(fData)
                .set('X-API-KEY', this.state.userInfo.api_key)
                .end((err, res) => {
                    this.setState({isLoading: false});
                        if(res !== undefined){
                            if(res.body.status === 200){
                                alert('translate submited, the process is running in background!');
                                this.handleRefreshTranslation();
                            }else if(res.body.status >= 400 && res.body.status !== 500){
                                localStorage.clear();
                                alert('something wrong! You will be logout in 3 seconds')
                                setTimeout(()=>{
                                    window.location.href='/'
                                }, 3000)
                            }else{
                                alert('translate failed!');
                            }
                        }else{
                            localStorage.clear();
                            alert('something wrong! You will be logout in 3 seconds')
                            setTimeout(()=>{
                                window.location.href='/'
                            }, 3000)
                        }
                });
            }
        }else{
            if(this.state.checkedDataTable.length > 0){
                let reqData = this.state.checkedDataTable.filter(word=>word.translation_value === '' || word.translation_value === null);
                if(reqData.length === 0){
                    alert('your selected data already translated, please select untranslated one!');
                    return false;
                }
                this.setState({isLoading: true});
                let fData = {
                    is_auto: true,
                    data: reqData,
                    lang: this.state.currentLang
                }
                superagent
                .post(process.env.REACT_APP_API_URL+'api/translation/save')
                .send(fData)
                .set('X-API-KEY', this.state.userInfo.api_key)
                .end((err, res) => {
                    this.setState({isLoading: false, toggledClearRows: !this.state.toggledClearRows});
                    if(res !== undefined && res.body !== null){
                        let status = res.body.status
                        if(status === 200){
                            alert('translate success!');
                            this.handleRefreshTranslation();
                        }else if(status >= 400 && status !== 500){
                            // localStorage.clear();
                            // alert('something wrong!');
                            alert('translate failed!');
                        }else{
                            alert('translate failed!');
                        }
                    }else{
                        // localStorage.clear();
                        // alert('something wrong!');
                        alert('translate failed!');
                    }
                });
            }else{
                alert('please select the table first!');
            }
        }
    }

    handleRefreshTranslation(){
        this.setState({isLoading:true});
        superagent.get(process.env.REACT_APP_API_URL+'api/translation/'+this.props.match.params.project_id+'/'+this.state.currentLang)
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            this.setState({isLoading:false});
            if(res !== undefined){
                if(res.body.status !== undefined){
                    localStorage.clear();
                    alert('something wrong! You will be logout in 3 seconds')
                    setTimeout(()=>{
                        window.location.href='/'
                    }, 3000)
                }else{
                    if(res.body.length > 0){
                        let dataFilter = this.state.isShowEmptyTranslate ? 
                        res.body.filter(word => word.translation_value === '' || word.translation_value === null) : res.body
                        if(this.state.isShowVariable){
                            dataFilter = dataFilter.filter(x=>x.is_variable)
                        }

                        this.setState({translationList: dataFilter, oldTranslationList:res.body, checkedDataTable: []})
                        if(this.state.search !== ''){
                            this.tableSearch()
                        }
                    }else{
                        this.setState({open:true, translationList:[], oldTranslationList:[], checkedDataTable: []})
                    }
                }
            }else{
                localStorage.clear();
                alert('something wrong! You will be logout in 3 seconds')
                setTimeout(()=>{
                    window.location.href='/'
                }, 3000)
            }
        });
    }

    handleTableChange(state){
        this.setState({checkedDataTable: state.selectedRows});
    }

    handleEditTranslation(e, data){
        if(data.act === 'edit'){
            let trData = this.state.translationList.filter(word=>word.translation_id === data.b_id);
            this.setState({isOpenModal:true, currentBaseTranslation: trData[0].base_value,
                currentTranslation: trData[0].translation_value, currentTranslationId: trData[0].translation_id});
        }else{
            if(window.confirm('are you sure want to verify this? the words will be lock from translator or validator.')){
                this.setState({isLoading:true});
                superagent
                .get(process.env.REACT_APP_API_URL+'api/verify/translation/'+data.b_id)
                .set('X-API-KEY', this.state.userInfo.api_key)
                .end((err, res) => {
                    this.setState({isLoading:false});
                    if(res !== undefined){
                        if(res.body.status === 200){
                            alert('translate verified!');
                            this.handleRefreshTranslation();
                        }else if(res.body.status >= 400 && res.body.status !== 500){
                            localStorage.clear();
                            alert('something wrong! You will be logout in 3 seconds')
                            setTimeout(()=>{
                                window.location.href='/'
                            }, 3000)
                        }else{
                            alert('verify failed!');
                        }
                    }else{
                        localStorage.clear();
                        alert('something wrong! You will be logout in 3 seconds')
                        setTimeout(()=>{
                            window.location.href='/'
                        }, 3000)
                    }
                });
            }
        }
    }

    close = () => {
        this.setState({ isOpenModal: false })
    } 

    onChangeTranslate = (e, data) =>{
        this.setState({ currentTranslation: data.value })
    }

    handleUpdateTranslate = () =>{
        this.setState({saveTranslationLoading:true});
        let fData = {
            translation: this.state.currentTranslation
        }
        superagent
        .post(process.env.REACT_APP_API_URL+'api/translation/manual/'+this.state.currentTranslationId)
        .send(fData)
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            this.setState({saveTranslationLoading:false});
            if(res !== undefined){
                if(res.body.status === 200){
                    alert('translate saved!');
                    this.setState({isOpenModal:false});
                    this.handleRefreshTranslation();
                }else if(res.body.status >= 400 && res.body.status !== 500){
                    localStorage.clear();
                    alert('something wrong! You will be logout in 3 seconds')
                    setTimeout(()=>{
                        window.location.href='/'
                    }, 3000)
                }else{
                    alert('translate failed!');
                }
            }else{
                localStorage.clear();
                alert('something wrong! You will be logout in 3 seconds')
                setTimeout(()=>{
                    window.location.href='/'
                }, 3000)
            }
        });
    }
}

export default Translation