import { getLocalStorage } from './local_storage'
const superagent = require('superagent')

export function checkAuth(){
    let userInfo = getLocalStorage('user');
    if(userInfo.isLoggedIn){
        superagent
            .get(process.env.REACT_APP_API_URL+'api/user/detail')
            .set('X-API-KEY', userInfo.user.api_key)
            .end((err, res) => {
                if(res !== undefined){
                    if(res.body.status >= 400){
                        localStorage.clear();
                        return false;
                    }
                }else{
                    localStorage.clear();
                    // window.location.href='/'
                }
            });
        return true;
    }

    localStorage.clear();
    return false;
}