import React from 'react'
import { Header, Grid, Modal, Button } from 'semantic-ui-react'
import NoDataImage from '../../../admin/components/nodata'

export default class UserSection extends React.Component{
    render(){
        let usertDetail = this.props.user
        let limit = usertDetail.user_child.length > 9 ? usertDetail.user_child.slice(0, 9) : usertDetail.user_child
        return(
            <div>
                <Grid columns={3}>
                {limit.length > 0 ? limit.map((v,k)=><Grid.Column key={k} className="center aligned">
                    <img alt='profile_image' className='ui small circular centered image' src={require('../../../img/avatar/'+v.avatar+'.png')} />
                    <Header as='h4' style={{marginTop:0}}>{v.full_name === null ? v.username : v.full_name}</Header>
                    </Grid.Column>) : <NoDataImage/>}
                </Grid>
                {usertDetail.user_child.length > 9 ? <Modal closeOnDimmerClick trigger={<Button size='mini'>View More</Button>}>
                    <Modal.Header>List of Users Child</Modal.Header>
                    <Modal.Content scrolling>
                        <Grid columns={10}>
                            {limit.map((v,k)=><Grid.Column key={k} className="center aligned">
                            <img alt='profile_image' className='ui small circular centered image' src={require('../../../img/avatar/'+v.avatar+'.png')} />
                            <Header as='h4' style={{marginTop:0}}>{v.full_name === null ? v.username : v.full_name}</Header>
                            </Grid.Column>)}
                        </Grid>
                    </Modal.Content>
                </Modal> : ''}
            </div>
        )
    }
}