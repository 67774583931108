import React from 'react'
import { Modal, Form, TransitionablePortal,
Button, Dropdown, Card, Dimmer,
Icon } from 'semantic-ui-react'
import { getLocalStorage, setLocalStorage } from '../../helper/local_storage'
const superagent = require('superagent')

export default class OneTimeSetting extends React.Component{
    constructor(props){
        super(props)
        let userInfo = getLocalStorage('user')

        this.state = {
            isSaveDetail: false,
            languages: [],
            userInfo: userInfo.user,
            userForm: {
                password: '',
                full_name: '',
                avatar: userInfo.user.avatar,
                preferred_language: '',
            },
            avatars : [
                { value: 0, active: false },
                { value: 1, active: false },
                { value: 2, active: false },
                { value: 3, active: false },
                { value: 4, active: false },
                { value: 5, active: false }
            ]
        }

        this.saveDetail = this.saveDetail.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    componentWillMount(){
        superagent
        .get(process.env.REACT_APP_API_URL+'api/languages')
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            let langs = res.body
            let tempLangs = []
            Array.from(langs).forEach(v=>{
                tempLangs.push({
                    key: v.language_code,
                    value: v.language_code,
                    text: v.language_name
                })
            })

            this.setState({languages: tempLangs})
        });
    }

    render(){
        let is_no_lang = this.props.no_lang !== undefined ? this.props.no_lang : false
        if(this.state.userInfo.is_new){
            return(
                <TransitionablePortal open>
                    <Modal open closeOnDimmerClick={false} dimmer='blurring'>
                        <Modal.Header>Onetime Detail Change</Modal.Header>
                        <Modal.Content>
                            <Form>
                                <Form.Group widths="equal">
                                    <Form.Input onChange={this.handleChange} type='password' name='password'
                                    label='New Password' placeholder='Type new password...' />
                                    <Form.Input name='full_name' onChange={this.handleChange}
                                    label='Full Name' placeholder='Type full name...' />
                                    {is_no_lang ? '' : <Form.Field name='preferred_language' control={Dropdown} label='Preferred Language'
                                    options={this.state.languages} fluid clearable
                                    search onChange={this.handleChange}
                                    multiple
                                    selection
                                    placeholder='Select Language' />}
                                    
                                </Form.Group>
                                <p><b>Avatar</b></p>
                                <Card.Group itemsPerRow={6}>
                                    {
                                        this.state.avatars.map((val, i) =>{
                                            return (
                                                <Card key={i} href='' onClick={this.thumbnailSelect.bind(this, i)} raised>
                                                    <Dimmer.Dimmable as={Card}>
                                                        <div className='image'><img alt={i} src={require('../../img/avatar/'+val.value+'.png')} /></div>
                                                        <Dimmer active={val.active}>
                                                            <Icon name='check' color='green' />
                                                        </Dimmer>
                                                    </Dimmer.Dimmable>
                                                </Card>
                                            )
                                        })
                                    }
                                </Card.Group>
                            </Form>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button primary loading={this.state.isSaveDetail} 
                            disabled={this.state.isSaveDetail}
                            content='Save'
                            onClick={this.saveDetail}
                            />
                        </Modal.Actions>
                    </Modal>
                </TransitionablePortal>
            )
        }
    }

    handleChange(e, data){
        let cName = data.name
        let uForm = this.state.userForm
        switch (cName) {
            case 'password':
                uForm.password = data.value
                break;
            case 'full_name':
                uForm.full_name = data.value
                break;
            default:
                uForm.preferred_language = data.value.join(',')
                break;
        }
        this.setState({userForm: uForm})
    }

    saveDetail(){
        this.setState({isSaveDetail: true})
        superagent
        .post(process.env.REACT_APP_API_URL+'api/update/user/detail/first')
        .send(this.state.userForm)
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            this.setState({isSaveDetail: false})
            if(res.body.status === 200){
                alert('profile updated')
                let user = res.body.data
                setLocalStorage('user', {
                    isLoggedIn: true,
                    user: user
                })
                if(this.props.no_lang !== undefined){
                    window.location.href=''
                }else{
                    let lg = user.preferred_language.split(',')
                    lg.sort()
                    window.location.href="/contributor/"+lg[0]
                }
            }else{
                alert('save failed!')
            }
        })
    }

    thumbnailSelect(key, e){
        e.preventDefault();
        let ava = this.state.avatars;
        let uForm = this.state.userForm;
        ava[key].active = true;
        uForm.avatar = key;
        ava.forEach(function(v, k){
            if(k !== key){
                ava[k].active = false;
            }
        });
        this.setState({
            avatars: ava,
            userForm: uForm
        });
    }
}