import React from 'react'
import { Header, Progress, Tab, Statistic, MenuItem, Flag } from 'semantic-ui-react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
    
export default class ProjectSection extends React.Component{
    
    render(){
        let currData = this.props.data
        let statisticProject = [];
        let progressStaticLang = [];
        statisticProject = [
            { key: 'trans', label: 'Translations', value: currData.translations.total },
            { key: 'transl', label: 'Translated', value: currData.translations.translated },
            { key: 'verify', label: 'Verified', value: currData.translations.validated }
        ]

        let staticLang = currData.translations.languages
        Object.keys(staticLang).forEach(function(k){
            progressStaticLang.push(
                { 
                    menuItem: <MenuItem key={currData.languages_info[k].language_code}><Flag name={currData.languages_info[k].language_flag} /> {currData.languages_info[k].language_name}</MenuItem>, 
                    render: () => <Tab.Pane attached={false}>
                        <Progress indicating percent={staticLang[k].progress} progress/>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                title: {
                                    text: ''
                                  },
                                xAxis: {
                                    categories: ['Activity'],
                                },
                                credits: {
                                    enabled: false
                                },
                                series: [
                                    { name:'Total', data: [staticLang[k].total], type: 'column' },
                                    { name:'Translated', data: [staticLang[k].translated], type: 'column' },
                                    { name:'Verified', data: [staticLang[k].validated], type: 'column' }
                                ]
                            }}
                        />
                    </Tab.Pane> 
                }
            )
        })
        return(
            <div>
                <Header as='h3' dividing>
                    Project Overall
                </Header>
                <Statistic.Group horizontal items={statisticProject} size='mini'/>
                <Progress className='project-progress' percent={currData.translations.progress} progress indicating/>
                <div style={{marginTop:-45}}>
                    <Header as='h3' dividing>
                        Statistics detail
                    </Header>
                    <Tab className='wrapped' menu={{ secondary: true }} panes={progressStaticLang}/>
                </div>
            </div>
        )
    }
}