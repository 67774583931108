import React from 'react'
import { Button, Form, Segment,
Grid, Divider, Message } from 'semantic-ui-react'
import ReCAPTCHA from "react-google-recaptcha";
const superagent = require('superagent')
const recaptchaRef = React.createRef();

export default class ResetPassword extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            isSuccess: false,
            resetForm: {
                captcha: '',
                password: '',
                username: '',
                api_key: '', 
            }
        }

        this.handleFormChange = this.handleFormChange.bind(this)
        this.handleSubmitReset = this.handleSubmitReset.bind(this)
    }

    componentDidMount(){
        let params = this.props.match.params;
        let fForm = this.state.resetForm
        fForm.username = params.username
        fForm.api_key = params.token
        this.setState({resetForm: fForm})
    }

    render(){
        return(
            <div className='login-form'>
            <style>{`
            body > div,
            body > div > div,
            body > div > div > div.login-form {
                height: 100%;
            }
            `}
            
            </style>
            <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
            <Grid.Column style={{ maxWidth: 425 }}>
                <Form size='large'>
                <Segment>
                    <img alt='logo' width="250px" src={require('../img/logo.png')} />
                    <Divider className="hidden" />
                    {this.state.isSuccess ? <Message positive>
                        <Message.Header>Success!</Message.Header>
                        <p>
                            Your reset password sent.
                        </p>
                    </Message> : ''}
                    <Form.Input type='password' fluid icon='key' onChange={this.handleFormChange} autoComplete='password'
                    name='password' iconPosition='left' placeholder='Type your new password...' required/>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey='6LfveIMUAAAAAPzd1W07Jt8o8mhKAantkiC3R3e_'
                        onChange={this.handleFormChange}
                    />
                    <Divider className="hidden" />
                    <Button onClick={this.handleSubmitReset} 
                    disabled={this.state.isLoading} loading={this.state.isLoading} 
                    primary fluid >
                        Submit
                    </Button>
                    <br/>
                    <a href='/'>back to login page</a>
                </Segment>
                </Form>
            </Grid.Column>
            </Grid>
        </div>
        )
    }

    handleFormChange(e, data){
        let fForm = this.state.resetForm
        if(data !== undefined){
            fForm.password = data.value
        }else{
            fForm.captcha = e;
        }
    }

    handleSubmitReset(){
        let fForm = this.state.resetForm
        if(fForm.captcha === '' || fForm.password === ''){
            alert('please complete the form!')
            return false
        }
        this.setState({isLoading:true})
        superagent
        .post(process.env.REACT_APP_API_URL+'api/user/reset')
        .send(fForm)
        .end((err, res) => {
            this.setState({isLoading:false})
            if(res.body.status === 200){
                fForm.captcha = ''
                fForm.password = ''
                this.setState({resetForm:fForm, isSuccess:true, isError:false})
                setTimeout(function(){
                    window.location.href = '/'
                }, 2000)
            }else{
                this.setState({forgotForm:fForm, isError:true, isSuccess:false, errorMessage: res.body.message})
            }
            recaptchaRef.current.reset()
        })
    }
}