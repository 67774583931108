import React from 'react'
import { 
    Loader, Button, Icon,
    Card, Input, Dropdown, Select
} from 'semantic-ui-react'
import DataTable from 'react-data-table-component'
import { tableSearch } from '../../../helper/dataTable'
import { getLocalStorage } from '../../../helper/local_storage'
import NoDataImage from '../../components/nodata'
const superagent = require('superagent')

export default class Versioning extends React.Component{
    constructor(props){
        super(props)
        let userInfo = getLocalStorage('user');
        this.state = {
            isLoading: true,
            userInfo: userInfo.user,
            versioningList: [],
            versionRequest: {
                type: '',
                file_type: '',
                language: ''
            },
            languages: []
        }

        this.handleRefreshData = this.handleRefreshData.bind(this)
        this.handleChangeFilterTable = this.handleChangeFilterTable.bind(this)
    }

    componentWillMount(){
        let listLanguages = [];
        superagent.get(process.env.REACT_APP_API_URL+'api/langproject/'+this.props.match.params.project_id)
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            if(res !== undefined){
                if(res.body.status !== undefined){
                    localStorage.clear();
                    alert('something wrong! You will be logout in 3 seconds')
                    setTimeout(()=>{
                        window.location.href='/'
                    }, 3000)
                }else{
                    if(res.body.length > 0){
                        listLanguages.push(
                            { 
                                key: 'all', 
                                value: 'all', 
                                text: 'All' 
                            }
                        )
                        res.body.forEach(function(val){
                            listLanguages.push(
                                { 
                                    key: val.language_code, 
                                    value: val.language_code, 
                                    flag: val.language_flag, 
                                    text: val.language_name 
                                }
                            )
                        });
                        this.setState({languages: listLanguages});
                        this.handleRefreshData()
                    }
                }
            }else{
                localStorage.clear();
                alert('something wrong! You will be logout in 3 seconds')
                setTimeout(()=>{
                    window.location.href='/'
                }, 3000)
            }
        });
    }

    render(){
        let columns = [
            {
                name: 'Version',
                selector: 'project_version',
                sortable: true,
                ignoreRowClick: true,
                allowOverflow: true,
                wrap: true
            },
            {
                name: 'File Type',
                selector: 'file_type',
                sortable: true,
                ignoreRowClick: true,
                allowOverflow: true,
                wrap: true,
            },
            {
                name: 'Versioning Type',
                selector: 'type',
                sortable: true,
                ignoreRowClick: true,
                allowOverflow: true,
                wrap: true
            },
            {
                name: 'Language',
                selector: 'language',
                sortable: true,
                ignoreRowClick: true,
                allowOverflow: true,
                cell: row => {
                    if(row.language === null){
                        return 'en'
                    }else{
                        return row.language
                    }
                },
                wrap: true
            },
            {
                name: 'Last Updated',
                selector: 'updated_at',
                sortable: true,
                ignoreRowClick: true,
                allowOverflow: false,
                wrap: true
            },
            {
                name: 'Link',
                selector: 'url',
                sortable: true,
                ignoreRowClick: true,
                allowOverflow: true,
                wrap: true,
                cell: row=> <Button size='small' primary onClick={this.handleDownload.bind(this, row.url)}>Download</Button>
            }
        ]

        let fileType = [
            { key: 'al', value: 'all', text: 'All' },
            { key: 'json', value: 'json', text: 'JSON', icon: {
                name:'file code outline',
            } },
            { key: 'xml', value: 'xml', text: 'XML', icon: {
                name:'file code',
                color: 'blue',
            } },
            { key: 'exc', value: 'excel', text: 'Excel', icon: {
                name:'file excel',
                color: 'green',
            } }
        ]

        let categories = [
            { key: 'al', value: 'all', text: 'All' },
            { key: 'bs', value: 'base', text: 'Base' },
            { key: 'ts', value: 'translation', text: 'Translation' }
        ]
        return(
            <div>
                <Select style={{marginRight:5}} name='cat' placeholder='Select Category' options={categories} 
                onChange={this.handleChangeFilterTable} />

                <Dropdown style={{marginRight:5}} name='lang' placeholder='Language' onChange={this.handleChangeFilterTable}
                search selection options={this.state.languages}/>

                <Select name='type' placeholder='Select File Type' options={fileType} 
                onChange={this.handleChangeFilterTable} />

                <Card fluid color='blue'>
                    { this.state.isLoading ? <div>
                        <Loader active/>
                    </div> : ''}
                    <DataTable
                        title="Versioning List"
                        columns={columns}
                        data={this.state.versioningList}
                        highlightOnHover
                        defaultSortField="project_versioning_id"
                        defaultSortAsc={false}
                        sortIcon={<Icon name='sort amount down'/>}
                        keyfield='project_versioning_id'
                        pagination
                        subHeader
                        subHeaderComponent={
                            <Input onChange={this.tableSearch} size='small'
                            icon='search' placeholder='Search...' />
                        }
                        noDataComponent={<NoDataImage/>}
                    />
                </Card>
            </div>
        )
    }

    tableSearch = (event, e) =>{
        if(e !== undefined){
            if(e.value === ''){
                this.setState({historyList:this.state.oldHistoryList});
            }else{
                this.setState({historyList:tableSearch(this.state.oldHistoryList, e.value)});
            }
        }
    }

    handleRefreshData(){
        this.setState({isLoading:true})
        superagent.post(process.env.REACT_APP_API_URL+'api/version/list/'+this.props.match.params.project_id)
        .set('X-API-KEY', this.state.userInfo.api_key)
        .send(this.state.versionRequest)
        .end((err, res) => {
            this.setState({isLoading:false})
            if(res.body.status === 200){
                this.setState({versioningList: res.body.data})
            }
        })
    }

    handleChangeFilterTable(e, data){
        let cName = data.name
        let vRequest = this.state.versionRequest
        switch (cName) {
            case 'lang':
                vRequest.language = data.value !== 'all' ? data.value : ''
                break;
            case 'cat':
                vRequest.type = data.value !== 'all' ? data.value : ''
                break;
            default:
                vRequest.file_type = data.value !== 'all' ? data.value : ''
                break;
        }

        this.setState({versionRequest: vRequest})
        this.handleRefreshData()
    }

    handleDownload = (e, ev) =>{
        window.open(e, '_blank')
    }
}