import React from 'react'
import { Header, List, Flag} from 'semantic-ui-react'
import NoDataImage from '../../../components/nodata'

export default class ArchiveSection extends React.Component{
    render(){
        let currData = this.props.data
        return(
            <div>
                <Header as='h3' dividing>
                    Recent Archive
                </Header>
                {currData.last_archive.length > 0 ? <List>
                    {currData.last_archive.map((v, k)=><List.Item key={k}>
                    <List.Icon name='archive' />
                    <List.Content>
                        <List.Header>
                            {v.language === 'en' || v.language === '' ? <div>{v.username} archive <Flag name='gb'/> translation. version {v.version.project_version}</div> : <div>
                                {v.username} archive  <Flag name={currData.languages_info[v.language].language_flag }/>translation. version {v.version.project_version}
                            </div>}
                        </List.Header>
                        <List.Description>on {v.updated_at}</List.Description>
                    </List.Content>
                    </List.Item>)}
                </List> : <NoDataImage size='small'/>}
            </div>
        )
    }
}