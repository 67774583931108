import React from 'react'
import { Flag, Tab, MenuItem, Loader } from 'semantic-ui-react'
import { getLocalStorage } from '../../../helper/local_storage'
import SettingContent from './content'
const superagent = require('superagent');

export default class Settings extends React.Component{
    constructor(props){
        super(props)
        let userInfo = getLocalStorage('user')
        this.state = {
            isLoading: true,
            userInfo: userInfo.user,
            languages: []
        }
    }

    componentWillMount(){
        superagent.get(process.env.REACT_APP_API_URL+'api/langproject/'+this.props.match.params.project_id)
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            if(res !== undefined){
                if(res.body.status !== undefined){
                    localStorage.clear();
                    alert('something wrong! You will be logout in 3 seconds')
                    setTimeout(()=>{
                        window.location.href='/'
                    }, 3000)
                }else{
                    if(res.body.length > 0){
                        let panes = []
                        let pid = this.props.match.params.project_id
                        let key = this.state.userInfo.api_key
                        res.body.forEach(function(val){
                            let dataReq = {
                                lang: val.language_code,
                                pid: pid,
                                key: key
                            }
                            panes.push({ 
                                menuItem: <MenuItem key={val.language_code}><Flag name={val.language_flag} /> {val.language_name}</MenuItem>, 
                                render: () => <Tab.Pane><SettingContent data={dataReq}/></Tab.Pane> 
                            })
                        })
                        this.setState({languages: panes, isLoading: false})
                    }else{
                        alert('no lang found!');
                    }
                }
            }else{
                localStorage.clear();
                alert('something wrong! You will be logout in 3 seconds')
                setTimeout(()=>{
                    window.location.href='/'
                }, 3000)
            }
        });
    }
    render(){
        return(
            <div>
            {this.state.isLoading ? <Loader active>Loading...</Loader> : <Tab menu={{ fluid: true, vertical: true }} panes={this.state.languages} />}
            </div>
        )
    }
}