import React from 'react'
import { Route } from 'react-router-dom'
import { BreadcrumbRoot } from '../../admin/components/breadcrumb/breadcrumb'
import { Divider } from 'semantic-ui-react'
import SecondNav from '../../admin/components/second_nav'
import LanguageOwner from './language'
import FAQ from './faq'

export default class MainStatic extends React.Component{
    render(){
        let menuList = [
            {
                target: '/owner/static/language',
                path: 'language',
                text:'Language'
            },
            {
                target: '/owner/static/faq',
                path: 'faq',
                text:'FAQ'
            }
        ]
        return(
            <div>
                <BreadcrumbRoot data={'Static Manage'}/><Divider /><Divider hidden />
                <SecondNav data={menuList} match={this.props.location}/>
                <Route path='/owner/static/language' exact component={LanguageOwner} />
                <Route path='/owner/static/faq' exact component={FAQ} />
            </div>
        )
    }
}