import React from 'react'
import { Image, Header } from 'semantic-ui-react'

export default class ProfileSection extends React.Component{
    render(){
        let userDetail = this.props.user
        return(
            <div>
                <Image src={require('../../../img/avatar/'+userDetail.avatar+'.png')} />
                <Header as='h2'>{userDetail.full_name}</Header>
                <Header as='h2'>{userDetail.username}</Header>
                <p>{userDetail.email}</p>
                <i>created on {userDetail.created_at}</i>
            </div>
        )
    }
}