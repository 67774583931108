import React from 'react'
import { Button, Icon, Modal,
    TransitionablePortal,
    Card, Dimmer } from 'semantic-ui-react'
import { getLocalStorage, setLocalStorage } from '../../../helper/local_storage'
const superagent = require('superagent')

export default class AvatarSetting extends React.Component{
    constructor(props){
        super(props)
        let userInfo = getLocalStorage('user')
        this.state = {
            avatars : [
                { value: 0, active: false },
                { value: 1, active: false },
                { value: 2, active: false },
                { value: 3, active: false },
                { value: 4, active: false },
                { value: 5, active: false },
            ],
            userInfo: userInfo.user,
            selectedAva: 0,
            isSaveDetail: false
        }

        this.handleSaveProfile = this.handleSaveProfile.bind(this)
    }

    render(){
        return(
            <TransitionablePortal open={this.state.isSettingOpen} trigger={
            <a href='#open' onClick={this.openSetting} id="updateAvatarTrigger" className="ui small black icon button">
                <i aria-hidden="true" className="pencil icon"></i></a>
            }>
                <Modal open={this.state.isSettingOpen}>
                    <Modal.Header>Avatar</Modal.Header>
                    <Modal.Content>
                        <Card.Group itemsPerRow={6}>
                            {
                                this.state.avatars.map((val, i) =>{
                                    return (
                                        <Card key={i} href='' onClick={this.thumbnailSelect.bind(this, i)} raised>
                                            <Dimmer.Dimmable as={Card}>
                                                <div className='image'><img alt={i} src={require('../../../img/avatar/'+val.value+'.png')} /></div>
                                                <Dimmer active={val.active}>
                                                    <Icon name='check' color='green' />
                                                </Dimmer>
                                            </Dimmer.Dimmable>
                                        </Card>
                                    )
                                })
                            }
                        </Card.Group>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.closeSetting}>
                            Cancel
                        </Button>
                        <Button loading={this.state.isSaveDetail}
                            disabled={this.state.isSaveDetail} primary onClick={this.handleSaveProfile}>
                            Save
                        </Button>
                    </Modal.Actions>
                </Modal>
            </TransitionablePortal>
        )
    }

    openSetting = (e) =>{
        e.preventDefault()
        this.setState({isSettingOpen:true})
    }

    closeSetting = () =>{this.setState({isSettingOpen:false})}

    thumbnailSelect(key, e){
        e.preventDefault();
        let ava = this.state.avatars;
        ava[key].active = true;
        ava.forEach(function(v, k){
            if(k !== key){
                ava[k].active = false;
            }
        });
        this.setState({
            avatars: ava,
            selectedAva: key
        });
    }

    handleSaveProfile(){
        this.setState({isSaveDetail: true})
        superagent
        .post(process.env.REACT_APP_API_URL+'api/update/user/detail')
        .send({avatar: this.state.selectedAva})
        .set('X-API-KEY', this.state.userInfo.api_key)
        .end((err, res) => {
            this.setState({isSaveDetail: false})
            if(res.body.status === 200){
                alert('profile updated')
                let user = res.body.data
                setLocalStorage('user', {
                    isLoggedIn: true,
                    user: user
                })
                let lg = user.preferred_language.split(',')
                lg.sort()
                window.location.href="/contributor/"+lg[0]
            }else{
                alert('save failed!')
            }
        })
    }
}
