import React from 'react'
import { Link } from 'react-router-dom'
import './breadcrumb.scss'
export default function BreadcrumbComponent(props){
    return (
        <div className="ui breadcrumb">
            {
                props.data.map((val, i)=>
                {
                    let ctn = {};
                    if(val.link && val.active === undefined){
                        ctn = <Link key={i} to={val.target} className="section">
                            <i className={BreadcrumbIcon(val.content)}></i>
                            {val.content}<i aria-hidden="true" className="right angle icon divider"></i>
                        </Link>
                    }else{
                        ctn = <div key={i} className="active section">{val.content}</div>;
                    }
                    return ctn
                }
                )
            }
        </div>
    )
}

export function BreadcrumbRoot(data){
    return (
        <div className="ui breadcrumb">
          <div className="active section"><i className={BreadcrumbIcon(data.data)}></i> {data.data}</div>
        </div>
    )
}

function BreadcrumbIcon(data){
  if(data === 'Users'){
    return 'user icon';
  }else if (data === 'Projects') {
    return 'folder icon';
  }else if(data === 'FAQ'){
      return 'question circle outline icon'
  }
}
